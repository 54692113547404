import { NameAndId, ServerError, useAxios } from "suregifts-component-lib";

import { useMutation, useQuery } from "react-query";

export const useGetSummaryData = () => {
  const axios = useAxios();
  return useQuery<SummaryData, ServerError>({
    queryKey: ["dashboard-summary"],
    keepPreviousData: true,
    queryFn: () => axios.post("/general/api/summary", {}),
  });
};

export const useGetIndustries = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["industries"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/general/api/industries", {}),
  });
};
export const useGetInitialData = () => {
  const axios = useAxios(false);
  return useMutation<initialData, ServerError, unknown>((d) => {
    return axios.get("/general/api/initial-settings");
  });
};
export interface initialData {
  businessUrl: string;
  websiteUrl: string;
  countryCode: string;
  contactPhone: string;
  contactEmail: string;
  currency: string;
  countries: CountryData[];
}
export interface CountryData {
  iso: string;
  name: string;
  businessUrl: string;
  url: string;
}
export interface SummaryData {
  walletBalance: string;
  amountSpent: string;
  vouchersIssued: string;
  totalRecipients: string;
  topRecipients: TopRecipientDto[];
  latestRedmeptions: LatestRedmeptionDto[];
  voucherStats: VoucherStatsDto;
}

export interface VoucherStatsDto {
  labels: string[];
  values: number[];
  quantities: number[];
}

export interface TopRecipientDto {
  amount: string;
  name: string;
}

export interface LatestRedmeptionDto {
  name: string;
  amount: string;
}
