import { Box, Button, Checkbox, IconButton, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import {
  DeleteIcon,
  EditPencilIcon,
  MainTable,
  PageHeaderTitle,
  Pagination,
  SearchContainer,
  TableBody,
  useNotificationContext,
  usePagination,
} from "suregifts-component-lib";
import { useDeleteStaff, useGetRecipients } from "../../../../services/discount";

import { useState } from "react";
import { toast } from "react-toastify";
import { EditStaffModal } from "./EditStaffModal";
import { useNavigate } from "react-router-dom";
import { useDiscountContext } from "../../DiscountContext";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useQueryClient } from "react-query";

export const DiscountEmployeeListScreen = () => {
  const { confirm } = useNotificationContext();
  const { data, isFetching, refetch } = useGetRecipients();
  const { clientPlan } = useDiscountContext();
  const [view, setView] = useState<"List" | "Edit">("List");
  const { mutate: deleteRecipients, isLoading } = useDeleteStaff();
  const [filter, setFilter] = useState({ page: 1 });
  const { total, data: items } = usePagination(filter.page, data);
  const navigate = useNavigate();
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const queryClient = useQueryClient();

  const [selections, setSelections] = useState<any[]>([]);
  const checkChanged = (id) => {
    setSelections((prev) => {
      if (prev.indexOf(id) === -1) {
        return [...prev, id];
      } else {
        return prev.filter((x) => x !== id);
      }
    });
  };
  const handleClose = () => {
    setView("List");
    setSelections([]);
  };

  return (
    <div>
      <SearchContainer>
        <PageHeaderTitle title={"Employees List"} />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <Tooltip title="Upload List">
            <Button
              startIcon={<FileUploadIcon />}
              onClick={() => navigate("/discounts/upload")}
              sx={{ fontSize: "14px", fontWeight: 500, borderRadius: "50vh" }}
            >
              Upload employee list
            </Button>
          </Tooltip>

          {selections.length > 0 && (
            <Button
              sx={{ borderRadius: "50vh" }}
              color="error"
              onClick={() =>
                confirm({
                  onConfirm: () => {
                    deleteRecipients(selections, {
                      onSuccess: () => {
                        toast.success("Staff deleted successfully.");
                        refetch();
                        queryClient.invalidateQueries("discount-client-plan");
                        setSelections([]);
                      },
                    });
                  },
                  message: "Are you sure you want to delete these employee(s) ",
                  title: "Confirm Delete",
                })
              }
            >
              Delete
            </Button>
          )}
        </Box>
      </SearchContainer>
      <MainTable sx={{ marginTop: "40px" }} loading={isFetching || isLoading}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "3%" }}></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell sx={{ width: "12%" }}>Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((item) => (
            <TableRow key={item.id}>
              <TableCell sx={{ width: "1%", padding: 0 }}>
                <Checkbox checked={selections.indexOf(item.id) !== -1} color="success" sx={{ padding: 0 }} onChange={() => checkChanged(item.id)} />
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.phone}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell sx={{ width: "10%", whiteSpace: "nowrap" }}>
                <IconButton
                  onClick={() => {
                    sessionStorage.setItem("RECIPIENT_DATA", JSON.stringify(item));
                    setView("Edit");
                  }}
                >
                  <EditPencilIcon />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={() =>
                    confirm({
                      onConfirm: () => {
                        deleteRecipients([item.id], {
                          onSuccess: () => {
                            toast.success("Staff deleted successfully.");
                            refetch();
                            queryClient.invalidateQueries("discount-client-plan");
                            setSelections([]);
                          },
                        });
                      },
                      message: "Are you sure you want to delete these employee",
                      title: "Confirm Delete",
                    })
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          page={filter.page}
          total={total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>

      {view === "Edit" && <EditStaffModal handleClose={handleClose} />}
    </div>
  );
};
