import { bool } from "yup";

export function range(start, end) {
  var foo: number[] = [];
  for (var i = start; i <= end; i++) {
    foo.push(i);
  }
  return foo;
}
export function isRealValue(obj) {
  return obj && obj !== "null" && obj !== "undefined";
}
