import { Typography } from "@mui/material";
import { DatePicker, FormInput, FormSelect, TitleDatailInlineV2 } from "suregifts-component-lib";
import { useGetBankAccounts } from "../../services/transaction";
import { Stack } from "@mui/system";
import { Controller } from "react-hook-form";

export const BankTransferInfoForm = ({ errors, register, control }) => {
  const { data: accounts } = useGetBankAccounts();
  return (
    <>
      <Typography sx={{ color: "#AAAABE" }}>
        To make a payment, you can directly transfer funds to one of our bank accounts. Please choose one of the following options.
      </Typography>
      <Stack spacing={2} sx={{ marginTop: "1.875rem" }}>
        {accounts?.map((item) => (
          <TitleDatailInlineV2 key={item.accountNumber} dt={item.bankName} dd={item.accountNumber} />
        ))}
      </Stack>
      <Stack spacing={2} sx={{ mt: "2.5rem" }}>
        <FormInput label="Depositor's Name" {...register("depositorName")} helperText={errors.depositorName?.message} />
        <Stack direction={"row"} spacing={2}>
          <Controller
            control={control}
            name="date"
            render={({ field: { value, onChange } }) => <DatePicker label={"Date"} value={value} onChange={onChange} helperText={errors.date?.message} />}
          />
          <FormInput label="Amount" {...register("amount")} helperText={errors.amount?.message} />
        </Stack>
        <FormInput label="Reference Number" {...register("referenceNumber")} helperText={errors.referenceNumber?.message} />
        <Controller
          control={control}
          name="accountUsed"
          render={({ field }) => (
            <FormSelect
              options={accounts?.map((x) => ({ name: x.bankName, id: x.accountNumber }))}
              label="Account Used"
              {...field}
              helperText={errors.accountUsed?.message}
            />
          )}
        />
      </Stack>
    </>
  );
};
