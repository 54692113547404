import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const images = ["nestle.png", "dhl.png", "ihs.png", "mtn.png", "fidelity.png", "fbn.png", "airtel.png", "dangote.png"];
export const SectionFive = () => {
  const theme = useTheme();
  return (
    <Box id="clients" sx={{ marginTop: "5rem", backgroundColor: "#FFFFFF", color: "rgb(16,15,21)", textAlign: "center", padding: "4.375rem" }}>
      <Box sx={{ marginInline: "auto" }}>
        <Typography sx={{ fontSize: "2.75rem", fontWeight: 700, lineHeight: "130%" }}>Some of our clients</Typography>
        <Typography sx={{ marginTop: "1.125rem", fontSize: "1.125rem", lineHeight: "1.875rem", color: "#100F15", opacity: 0.7 }}>
          Our personalized gift cards are designed to help organztions increase overall employee preformance and client loyalty
        </Typography>
      </Box>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            display: "grid",
            // gridTemplateColumns: "repeat(2, 1fr)",
            [theme.breakpoints.up("sm")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
            [theme.breakpoints.up("lg")]: {
              gridTemplateColumns: "repeat(4, 1fr)",
            },
            gap: "2.5rem",
            marginTop: "3.75rem",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          {images.map((x, i) => (
            <img src={`/assets/images/business/${x}`} alt="" key={`${x}-${i}`} />
          ))}
        </Box>
      </div>
    </Box>
  );
};
