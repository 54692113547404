import { Divider } from "@mui/material";
import { MiddleSection } from "./MiddleSection";
import { TopSection } from "./TopSection";
import { ProceedSection } from "./ProceedSection";

function DiscountSubscibeScreen() {
  return (
    <div>
      <TopSection />
      <Divider />
      <MiddleSection />
      <ProceedSection />
    </div>
  );
}

export default DiscountSubscibeScreen;
