import { Stack, OutlinedInput, Box, Divider, Typography } from "@mui/material";
import numeral from "numeral";
import { useMemo, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuthContext, LoadingBox, FormButton, PageHeaderTitle, AmountInlineView } from "suregifts-component-lib";
import { ComputeAmountModel, useComputeOrderAmount } from "../../services/order";
import { useGetWalletInfo } from "../../services/transaction";
type OrderAmountSummaryViewProps = {
  model: ComputeAmountModel;
  handleChange: (params) => void;
};
export const OrderAmountSummaryView = ({ model, handleChange }: OrderAmountSummaryViewProps) => {
  const { mutate: computeOrderAmount, isLoading: isComputing, data: computedAmount } = useComputeOrderAmount();

  const reCalculate = () => {
    computeOrderAmount(model, {
      onSuccess: (result) => {
        if (result.codeId === 0) return;
        if (result.codeId !== 0) {
          toast.error(result.message);
          handleChange({ couponCode: "" });
        }
        if (result.codeId !== 9) {
          handleChange({ tabValue: 1 });
        }
      },
    });
  };

  return (
    <LoadingBox loading={isComputing}>
      <CouponView model={model} handleChange={handleChange} reCalculate={reCalculate} />
      <PageHeaderTitle title="Order Amount" sx={{ mt: "1.875rem" }} />
      <AmountSummaryView model={model} calculateAmount={computedAmount} reCalculate={reCalculate} />
    </LoadingBox>
  );
};
const AmountSummaryView = ({ model, calculateAmount: computedAmount, reCalculate }) => {
  const { data: wallet } = useGetWalletInfo();
  const walletDeduction = useMemo(() => {
    if (!computedAmount || !wallet) return 0;
    return Math.min(computedAmount.amountDue, wallet.balance);
  }, [wallet, computedAmount]);

  useEffect(() => {
    reCalculate();
  }, [model.tabValue]);

  const { user } = useAuthContext();
  return (
    <Box
      sx={{
        marginTop: "1.25rem",
        backgroundColor: "primary.main",
        padding: "1.25rem",
        borderRadius: "1.25rem",
      }}
    >
      <Stack spacing={3}>
        <AmountInlineView dt={"Subtotal"} dd={computedAmount?.subTotal} />
        <AmountInlineView dt={"Shipping"} dd={computedAmount?.shipping} />
        <AmountInlineView dt={"Coupon"} dd={-(computedAmount?.couponDiscount || 0)} ddColor="success.main" dtColor="success.main" />
        <AmountInlineView dt={"Wallet"} dd={-walletDeduction} ddColor="success.main" dtColor="success.main" />
        <Divider />
      </Stack>
      <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "1.875rem", paddingBottom: "0.625rem" }}>
        <Typography style={totalStyle}>Total</Typography>
        <Typography style={totalStyle}>
          {user?.currency}
          {numeral(Math.abs((computedAmount?.amountDue || 0) - walletDeduction)).format("0,0.00")}
        </Typography>
      </div>
    </Box>
  );
};
const CouponView = ({ model, handleChange, reCalculate }) => {
  return (
    <Stack spacing={"0.625rem"} direction="row">
      <OutlinedInput
        sx={{ borderRadius: "0.75rem" }}
        placeholder="Enter Coupon"
        value={model.couponCode}
        onChange={(e) => handleChange({ couponCode: e.target.value })}
      />
      <FormButton
        disabled={model.couponCode.length === 0}
        label="Apply Cupon"
        color="primary"
        sx={{ px: "1.375rem", width: "auto", whiteSpace: "nowrap" }}
        onClick={() => reCalculate()}
      />
    </Stack>
  );
};
const totalStyle = { fontSize: "1.125rem", fontWeight: 700, lineHeight: "1.375rem" };
