import { Box, Stack, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";

export const ProceedSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "grid",
        gap: "1.5rem",
        [theme.breakpoints.up("md")]: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1.5rem",
          border: "1px solid rgba(255,255,255,0.2) ",
          borderRadius: "1rem",
          marginTop: "2rem",
        },
        marginY: "3rem",
      }}
    >
      <div>Sound Good?</div>
      <div>
        <Stack spacing={1} direction={"row"}>
          <FormButton label="Cancel" color="primary" onClick={() => navigate("/dashboard")} />
          <FormButton label="Proceed" onClick={() => navigate("/discounts/choose-plan")} color="success" sx={{ paddingX: "4.5rem" }} />
        </Stack>
      </div>
    </Box>
  );
};
