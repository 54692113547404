import { Box, Card, Divider, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import moment from "moment";
import { FormButton, OrderStatusLabel, OrderStatusType, PageHeaderTitle, TitleDetailView, TransactionStatusLabel, dateTime } from "suregifts-component-lib";
import { OrderInfoData, useResendOrder } from "../../../../services/order";
import { toast } from "react-toastify";
import { useMemo, useState } from "react";
import CancelOrderModal from "./CancelOrderModal";

export function OrderInfoSection({ data, refetch }: { data?: OrderInfoData; refetch: () => void }) {
  const { orderId } = useParams();
  const { mutate: resendOrder, isLoading: isResending } = useResendOrder();
  const [openCancel, setOpenCancel] = useState(false);
  const handleResend = () => {
    resendOrder(orderId, {
      onSuccess: () => {
        toast.success("Vouchers resent successfully");
        refetch();
      },
    });
  };

  return (
    <div>
      <PageHeaderTitle title="Order Details" />
      <Card variant="outlined" sx={{ p: "1.85rem", mt: "1.25rem", bgcolor: "primary.main", boxShadow: 2, borderRadius: "1.25rem" }}>
        <Stack spacing={"1.85rem"}>
          <TitleDetailView title="Date Created" detail={dateTime(data?.dateCreated)} />
          <TitleDetailView title="Delivery Date" detail={dateTime(data?.scheduledTime)} />

          <Stack spacing={2} direction={"row"}>
            <TitleDetailView title="Total Value" detail={data?.subTotal} />
          </Stack>
          <TitleDetailView title="Gift Type" detail={data?.merchantName} />
        </Stack>
        <Divider sx={{ my: "2.5rem" }} />
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Order Status" detail={<OrderStatusLabel label={data?.status} status={data?.statusId} />} />
            <TitleDetailView align="right" title="Delivery Status" detail={data?.deliveryStatus} />
          </Stack>
          {data?.statusId !== OrderStatusType.Canceled && (
            <Stack spacing={2} direction={"row"}>
              {(data?.statusId === OrderStatusType.Delivered || data?.statusId === OrderStatusType.PartiallyDelivered) && <FormButton sx={{ px: "0.125rem" }} onClick={handleResend} fullWidth label={"Resend Order"} color="success" />}
              {data?.canCancel && <FormButton onClick={() => setOpenCancel(true)} sx={{ px: "0.125rem", color: "error.main" }} fullWidth label="Cancel Order" color="primary" />}
            </Stack>
          )}
        </Stack>
      </Card>
      {openCancel && (
        <CancelOrderModal
          orderId={orderId}
          handleClose={() => {
            setOpenCancel(false);
            refetch();
          }}
        />
      )}
    </div>
  );
}
