import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";

export const DiscountBanner = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      component="section"
      sx={{
        borderRadius: "18px",
        marginTop: "3.125rem",
        background: "linear-gradient(258deg, #FA1C44 -36.43%, rgba(250, 28, 68, 0.00) 97.22%)",
        backdropFilter: "blur(27px)",
        display: "grid",
        gap: "2rem",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "1fr max-content",
        },
        padding: "18px 24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          gap: "2rem",
        }}
      >
        <img src="/assets/images/discounts/logo-sm.png" alt="" />
        <div>
          <h3 style={{ margin: "0" }}>Staff Discount</h3>
          <div style={{ margin: "0" }}>
            Your staff deserves to buy more with less. Give your employees the
            <br /> opportunity to buy more with less.
          </div>
        </div>
      </Box>
      <FormButton
        label="Learn More"
        onClick={() => {
          navigate("/discounts-landing");
        }}
      />
    </Box>
  );
};
