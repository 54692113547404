import { Box, useTheme } from "@mui/material";
import { AuthSection } from "./AuthSection";

export const SectionFour = () => {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={{
        marginTop: "3.5rem",
        h2: {
          fontSize: "46px",
          fontWeight: 700,
          lineHeight: "110%",
        },
        p: {
          fontSize: "18px",
          lineHeight: "30px",
        },
        img: {
          borderRadius: "32px",
        },
        display: "grid",
        gap: "2rem",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "repeat(2,1fr)",
        },
      }}
    >
      <div>
        <h2>
          Save and shop at the <br />
          merchants you love
        </h2>
        <p style={{ opacity: 0.7 }}>
          Make your employees happy by giving the
          <br /> opportunity to shop at a big discount and <br />
          save more on shopping.
        </p>
        <AuthSection justifyContent="start" />
      </div>
      <img src="/assets/images/discounts/promo1.png" alt="" />
    </Box>
  );
};
