import { Typography } from "@mui/material";
import { TypographyProps } from "@mui/system";
import React from "react";
type StaticHeaderProps = {
  children?: React.ReactNode;
};
function StaticHeader({ children }: StaticHeaderProps) {
  return (
    <Typography
      sx={{
        fontSize: {
          xs: "1.5625rem",
          md: "2.8125rem",
        },
        lineHeight: "110%",
        fontWeight: 700,
      }}
    >
      {children}
    </Typography>
  );
}

export default StaticHeader;
