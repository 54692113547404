import { GetStartedSection } from "./GetStartedSection";
import { Container } from "@mui/material";

import { SectionFour } from "./SectionFour";
import { DashboardControlSection } from "./DashboardControlSection";
import { CorporateSolutionSection } from "./CorporateSolutionSection";
import { SectionFive } from "./SectionFive";
import HeaderSection from "../header/HeaderSection";
import FooterSection from "../footer/FooterSection";
import { useAuthContext, useDocumentTitle } from "suregifts-component-lib";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { DiscountBanner } from "./DiscountBanner";

function HomeScreen() {
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  useDocumentTitle("Home");
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/my-accounts");
    }
  }, []);

  return (
    <>
      <Container>
        <DiscountBanner />
        <GetStartedSection />
        <DashboardControlSection />
        <CorporateSolutionSection />
        <SectionFour />
      </Container>
      <SectionFive />
    </>
  );
}
export default HomeScreen;
