import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { UtilityInfoSection } from "./UtilityInfoSection";
import RecipientSection from "./RecipientSection";
import { LoadingBox, useDocumentTitle } from "suregifts-component-lib";
import { useGetUtilityById } from "../../../../services/utilities";
import { PageBackView } from "../../../../components/molecules/PageBackView";

function UtilityInfoScreen() {
  const { orderId } = useParams();
  const { data: billOrder, isFetching, refetch } = useGetUtilityById(orderId);
  useDocumentTitle("Orders");
  return (
    <div>
      <PageBackView to="/orders/bills" title="All Orders" />
      <LoadingBox
        loading={isFetching}
        sx={(theme) => ({
          display: "grid",
          gap: "2.375rem",
          gridTemplateColumns: "1fr",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "2fr 1fr",
          },
        })}
      >
        <RecipientSection data={billOrder} />
        <UtilityInfoSection data={billOrder} refetch={refetch} />
      </LoadingBox>
    </div>
  );
}

export default UtilityInfoScreen;
