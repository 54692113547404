import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Stack, useTheme } from "@mui/material";
import { FormLabel, formatMoney, formatNumber, useDocumentTitle } from "suregifts-component-lib";
import { useEffect, useState } from "react";
import { AddRecipientModel, UploadFileModel } from "../../../services/discount";
import { useDiscountContext } from "../DiscountContext";
import { ExtraPaymentSummaryView } from "./ExtraPaymentSummaryView";
import { DirectSubmitView } from "./DirectSubmitView";
import { PageTitleView } from "./PageTitleView";
import { PaymentViewConmponent } from "./PaymentViewConmponent";
import { CsvUploadComponent } from "./CsvUploadComponent";
import { IndividualUploadComponent } from "./IndividualUploadComponent";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { useEmployeeUploadContext } from "./EmployeeUploadContext";
import { navigate } from "../../calendar/landing/EventListScreen";
import { AddedEmployeeView } from "./AddedEmployeeView";

function EmployeeUploadScreen() {
  const theme = useTheme();
  const { clientPlan, price } = useDiscountContext();
  const [fileUrl, setFileUrl] = useState("");
  const [recipients, setRecipients] = useState<AddRecipientModel[]>([]);
  const { initialSetting } = useGlobalContext();
  const [mode, setMode] = useState<"csv" | "individual">("individual");
  useDocumentTitle("Employee Upload");
  const { totalUploadChanged, totalUpload } = useEmployeeUploadContext();
  useEffect(() => {
    totalUploadChanged(recipients.length);
  }, [recipients]);

  if (!clientPlan) return <></>;
  const removeStaff = (index) => {
    setRecipients((prev) => {
      var temp = [...prev];
      temp.splice(index, 1);
      return [...temp];
    });
  };
  return (
    <div style={{ margin: "3.125rem 0" }}>
      <PageTitleView />
      <Box
        sx={{
          display: "grid",
          gap: "36px",
          marginTop: "2rem",
          alignItems: "start",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "5fr 3fr",
          },
        }}
      >
        <Box
          sx={{
            padding: "10px 50px 40px",
            background: "#17171C",
            borderRadius: "30px",
            h3: {
              fontSize: "1.5rem",
              fontWeight: 700,
            },
            "div.desc": {
              color: "#8C8C9D",
              margin: "10px 0 30px",
            },
          }}
        >
          <h3>Upload Employees List</h3>
          <div className="desc">
            <span style={{ color: "white", fontWeight: 700 }}>Note:</span> You’ve paid for {formatNumber(clientPlan?.paidUnits)} number of employees. Uploading
            more than this number will require you to pay additional fee of {formatMoney(price, initialSetting?.currency)} per person
          </div>
          <FormControl sx={{ marginBottom: "24px" }}>
            <RadioGroup
              row
              value={mode}
              onChange={(value) => {
                setMode(value.target.value as any);
                setFileUrl("");
                setRecipients([]);
              }}
            >
              <FormControlLabel value="csv" control={<Radio color="success" />} label="Bulk CSV Upload" />
              <FormControlLabel value="individual" control={<Radio color="success" />} label="Upload Individually" />
            </RadioGroup>
          </FormControl>
          {mode === "csv" ? (
            <CsvUploadComponent uploadComplete={(fileUrl) => setFileUrl(fileUrl)} />
          ) : (
            <IndividualUploadComponent
              onStaffAdded={(staff) => {
                setRecipients((prev) => {
                  return [...prev, staff];
                });
              }}
            />
          )}
        </Box>

        <div>
          <Stack spacing={3} sx={{}}>
            <AddedEmployeeView
              recipients={recipients}
              removeStaff={removeStaff}
              staffEdited={(item, index) => {
                setRecipients((prev) => {
                  var temp = [...prev];
                  temp[index] = item;
                  return temp;
                });
              }}
            />
            <DirectSubmitView fileUrl={fileUrl} recipients={recipients} />
            <ExtraPaymentSummaryView />
            <PaymentViewConmponent fileUrl={fileUrl} recipients={recipients} />
          </Stack>
        </div>
      </Box>
    </div>
  );
}

export default EmployeeUploadScreen;
