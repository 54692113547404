import { Typography } from "@mui/material";
import { TypographyProps } from "@mui/system";
import React from "react";
type StaticHeaderInfoProps = {
  children: React.ReactNode;
} & TypographyProps;
function StaticHeaderInfo({ children, ...props }: StaticHeaderInfoProps) {
  return (
    <Typography
      sx={{
        fontSize: "1.125rem",
        lineHeight: "2rem",
        fontWeight: 400,
        color: "text.disabled",
        marginTop: "1.25rem",
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}

export default StaticHeaderInfo;
