import { Stack } from "@mui/material";
import React from "react";
import { FormButton } from "suregifts-component-lib";
import { navigate } from "../../calendar/landing/EventListScreen";
import { useNavigate } from "react-router-dom";
type AuthSectionProps = {
  justifyContent: "center" | "start";
};
export const AuthSection = ({ justifyContent }: AuthSectionProps) => {
  const navigate = useNavigate();
  return (
    <Stack
      direction={"row"}
      justifyContent={justifyContent}
      spacing={2}
      sx={{
        marginTop: "2rem",
        button: {
          minWidth: "213px",
        },
      }}
    >
      <FormButton label="Sign up" onClick={() => navigate("/register")} />
      <FormButton label="Sign in" onClick={() => navigate("/login")} color="primary" sx={{ border: 1, backgroundColor: "primary.dark" }} />
    </Stack>
  );
};
