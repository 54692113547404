import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useVerifyFunding } from "../../services/transaction";
import { ErrorTickIcon, FormButton, SuccessTickIcon, TransactionStatusType, TransactionType, WalletType, getPaymentType } from "suregifts-component-lib";
import { Button, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
type PaymentInfoProps = {
  title: string;
  desc: string;
  statusId: TransactionStatusType;
  primaryBtnText: string;
  secondaryBtnText?: string;
  primaryAction: () => void;
};
function PaymentCallbackScreen() {
  const [searchParams] = useSearchParams();
  const [state, setState] = useState({ statusId: TransactionStatusType.Pending } as PaymentInfoProps);
  const { type } = useParams();
  const navigate = useNavigate();

  const { mutate: verifyPayment, isLoading, data } = useVerifyFunding();

  useEffect(() => {
    const paymentMethod = getPaymentType(type);

    let invoiceNumber: any = "";
    let providerRef: any = "";
    switch (paymentMethod) {
      case WalletType.Paypal:
        providerRef = searchParams.get("token");
        break;
      case WalletType.Paystack:
        invoiceNumber = searchParams.get("trxref");
        break;
      case WalletType.Crypto:
        invoiceNumber = searchParams.get("token");
        break;
      case WalletType.Kora:
        invoiceNumber = searchParams.get("reference");
        break;
      default:
        break;
    }
    if ((!invoiceNumber && !providerRef) || !paymentMethod) {
      navigate("/dashboard");
      return;
    }
    var model = {
      invoiceNumber,
      paymentMethod,
      providerRef,
    };
    verifyPayment(model, {
      onSuccess: (data) => {
        const isDiscount = (data.typeId as number) === 7;
        if (data.statusId === TransactionStatusType.Successful) {
          setState({
            desc: isDiscount ? "" : "You have successfully topped up your balance",
            title: "Payment Successful",
            statusId: data.statusId,
            primaryBtnText: isDiscount ? "Add Employees" : "View Wallet",
            secondaryBtnText: "Go to Dashboard",
            primaryAction: () => {
              navigate(isDiscount ? "/discounts" : "/wallet");
            },
          });
        } else if (data.statusId === TransactionStatusType.Failed) {
          setState({
            desc: "There was an error precessing your transaction, please contact your bank or try again",
            title: "Payment Failed",
            statusId: data.statusId,
            primaryBtnText: "Try again",
            secondaryBtnText: "No Thanks",
            primaryAction: () => {
              navigate(isDiscount ? "/discounts" : "/wallet");
            },
          });
        } else {
          setState({
            title: "Processing",
            desc: "Your payment is currently been processed.",
            statusId: TransactionStatusType.Pending,
            primaryBtnText: isDiscount ? "Add employee" : "View Wallet",
            secondaryBtnText: "Go to Dashboard",
            primaryAction: () => {
              navigate(isDiscount ? "/discounts" : "/wallet");
            },
          });
        }
      },
    });
  }, []);
  return (
    <div style={{ padding: "3.125rem 4.5rem" }}>
      <div
        style={{
          zIndex: 1000,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box component={"img"} sx={{}} src="/assets/images/logo.png" />
        <IconButton onClick={() => navigate("/dashboard")}>
          <CloseIcon />
        </IconButton>
      </div>
      {data && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            position: "absolute",
            top: 0,
            bottom: 0,
            width: "100%",
            zIndex: 999,
          }}
        >
          <div style={{ textAlign: "center", maxWidth: "25rem", width: "100%" }}>
            {state.statusId === TransactionStatusType.Successful && <SuccessTickIcon sx={{ width: "5rem", height: "5rem" }} color="success" />}
            {state.statusId === TransactionStatusType.Failed && <ErrorTickIcon sx={{ width: "5rem", height: "5rem" }} color="error" />}
            {state.statusId === TransactionStatusType.Pending && <ErrorIcon sx={{ width: "5rem", height: "5rem" }} color="warning" />}
            <Typography sx={{ fontSize: "1.875rem", fontWeight: 700, marginTop: "1.5rem" }}>{state.title}</Typography>
            <Typography sx={{ fontSize: "1.125rem", fontWeight: 400, marginTop: "0.625rem" }}>{state.desc}</Typography>
            <div style={{ marginTop: "1.75rem" }}>
              <FormButton label={state.primaryBtnText} sx={{ width: "80%" }} onClick={state.primaryAction} />
            </div>
            {state.secondaryBtnText && (
              <Button variant="text" color="secondary" sx={{ color: "text.secondary", marginTop: 1 }} onClick={() => navigate("/dashboard")}>
                {state.secondaryBtnText}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentCallbackScreen;
