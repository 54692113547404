import React, { ReactNode } from "react";
export type DiscriptionSectionProps = {
  header: string;
  desc: ReactNode;
};
export const DiscriptionSection = (props: DiscriptionSectionProps) => {
  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "start", borderRadius: "" }}>
      <div
        style={{
          fontSize: "14px",
          fontWeight: 700,
          textAlign: "center",
          border: "1px solid white",
          width: "42px",
          height: "42px",
          borderRadius: "21px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        1
      </div>
      <div>
        <h3 style={{ fontSize: "24px", fontWeight: 500, lineHeight: "26px", margin: 0 }}>{props.header}</h3>
        <p style={{ fontWeight: 400, lineHeight: "24px", opacity: 0.7 }}>{props.desc}</p>
      </div>
    </div>
  );
};
