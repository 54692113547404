import { dateOnly } from "suregifts-component-lib";
import { Box, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useDiscountContext } from "../DiscountContext";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const ExpiryWarningView = () => {
  const theme = useTheme();
  const { clientPlan } = useDiscountContext();
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        borderRadius: "14px",
        padding: "21px",
        fontWeight: 500,
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
          flexDirection: "row",
        },
        background: "rgba(255, 0, 61, 0.09)",
      }}
    >
      <div style={{ flexGrow: 1, display: "flex", alignItems: "center", gap: "1rem" }}>
        <ErrorOutlineIcon color="warning" />
        {clientPlan?.expired ? "Your subscription has expired on " : "Your subscription will expire on "} {dateOnly(clientPlan?.expiryDate)}
      </div>
      <Link
        style={{
          color: "white",
        }}
        to="/discounts/renew-plan"
      >
        Renew Plan
      </Link>
    </Box>
  );
};
