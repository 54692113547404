import { Box, Button, Link, Stack } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { FileUploader, FormButton, FormInput, formatMoney, formatNumber } from "suregifts-component-lib";
import { useMemo, useState } from "react";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { useUploadFile } from "../../../services/discount";
import { useDiscountContext } from "../DiscountContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useEmployeeUploadContext } from "./EmployeeUploadContext";

const baseUrl = (window as any).BASE_URL;
type CsvUploadComponentProps = {
  uploadComplete: (string) => void;
};

export const CsvUploadComponent = ({ uploadComplete }: CsvUploadComponentProps) => {
  const { mutate: uploadFile, isLoading } = useUploadFile();
  const { totalUploadChanged, totalUpload } = useEmployeeUploadContext();
  const [fileUrl, setFileUrl] = useState<string | undefined>();

  const navigate = useNavigate();

  const label = useMemo(() => {
    if (!fileUrl) return "";
    return fileUrl?.replace(/^.*[\\\/]/, "");
  }, [fileUrl]);

  const handleChange = (files: string | string[] | undefined) => {
    if (typeof files !== "undefined") {
      setFileUrl(files as string);
    }
  };

  const submitUploadFile = () => {
    const requestModel = { fileUrl: fileUrl };
    uploadFile(requestModel, {
      onSuccess: (data) => {
        if (data.total <= 0) {
          toast.warning("Upload empyty or employee(s) were already added.");
          return;
        }
        totalUploadChanged(data.total);
        uploadComplete(fileUrl);
      },
    });
  };

  return (
    <>
      <FileUploader
        accept=".csv"
        renderControl={({ onClick }) => <FormInput onClick={onClick} label="Upload a list of employees" value={label} startAdornment={<AttachFileIcon />} />}
        onChange={handleChange}
      />
      <div className="download">
        Download example list?{" "}
        <Link
          sx={{
            textDecoration: "none",
            color: "success.main",
          }}
          target="_blank"
          href={`${baseUrl}/live/discounts/sample-recipients`}
        >
          Download
        </Link>
      </div>
      {!totalUpload && (
        <Stack direction={"row"} spacing={2} alignItems={"center"} sx={{ marginTop: "40px" }}>
          <FormButton loading={isLoading} disabled={!fileUrl} label="Upload list" fullWidth style={{ maxWidth: "200px" }} onClick={submitUploadFile} />
          <Button variant="text" onClick={() => navigate("/discounts/employees")} color="secondary">
            I’ll do this later
          </Button>
        </Stack>
      )}
    </>
  );
};
