import { useTheme } from "@mui/material/styles";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { FormInput, PageHeaderTitle } from "suregifts-component-lib";
import { VoucherStatsDto } from "../../services/general";
import { Stack } from "@mui/system";
import { IconButton, OutlinedInput } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { LocalizationProvider } from "@mui/lab";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Order Statistics",
    },
  },
};

type VoucherStatisticsViewProps = {
  data?: VoucherStatsDto;
};

function VoucherValueStatisticsView({ data }: VoucherStatisticsViewProps) {
  const theme = useTheme();

  return (
    <div>
      {data && (
        <Line
          options={options}
          data={{
            labels: data.labels,
            datasets: [
              {
                label: "Value",
                data: data.values,
                borderColor: theme.palette.warning.dark,
                backgroundColor: theme.palette.warning.dark,
              },
            ],
          }}
          style={{ maxHeight: "25rem" }}
        />
      )}
    </div>
  );
}

export default VoucherValueStatisticsView;
