import { Container, Divider } from "@mui/material";
import React from "react";
import { SectionOne } from "./SectionOne";
import { SectionTwo } from "./SectionTwo";
import { HowToUse } from "./HowToUse";

import { SectionFour } from "./SectionFour";
import { useDocumentTitle } from "suregifts-component-lib";
import { PricingSection } from "./PricingSection";
function DiscountLandingScreen() {
  useDocumentTitle("Discounts");
  return (
    <Container>
      <SectionOne />
      <SectionTwo />
      <HowToUse />
      <Divider />
      <PricingSection />
      <SectionFour />
    </Container>
  );
}

export default DiscountLandingScreen;
