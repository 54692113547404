import { Box, Divider, useTheme } from "@mui/material";
import { FormButton } from "suregifts-component-lib";
import { useNavigate } from "react-router-dom";
import { GreenBorderLabel } from "../../discounts/GreenBorderLabel";

export const PricingSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        h2: { textAlign: "center", fontWeight: 700, fontSize: "46px", lineHeight: "110%" },
      }}
    >
      <h2>Pricing</h2>
      <Box
        sx={{
          textAlign: "left",
          display: "grid",
          overflow: "hidden",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          backgroundColor: "#1C1B21",
          border: "1px solid #4E4D52",
          borderRadius: "20px",
          "&>div": {
            padding: "46px 92px",
            border: "1px solid #4E4D52",
          },
          h3: {
            fontSize: "38px",
            fontWeight: 700,
            color: "white",
            margin: 0,
          },
          fontSize: "14px",
          fontWeight: 400,
          color: "#8C8C9D",
        }}
        className="info"
      >
        <LeftView />
        <RightView />
      </Box>
    </Box>
  );
};
const RightView = () => {
  const navigate = useNavigate();
  return (
    <div style={{ textAlign: "center" }}>
      <div>Price:</div>
      <h3 style={{ fontSize: "40px", fontWeight: 700, lineHeight: "24px", marginTop: "12px", marginBottom: "12px" }}>2000</h3>
      <div>/EMPLOYEE/YEAR</div>
      <FormButton
        label="Get started"
        color="success"
        sx={{ marginTop: "20px" }}
        onClick={() => {
          navigate("/login");
        }}
      />
    </div>
  );
};
const LeftView = () => {
  return (
    <div style={{ display: "flex", gap: "24px", alignItems: "start", flexDirection: "column" }}>
      <h3
        style={{
          fontSize: "38px",
          fontWeight: 700,
        }}
      >
        Discount Plan
      </h3>
      <GreenBorderLabel label={"UNLIMITED EMPLOYEES"} />
      <p style={{ color: "#8C8C9D", fontSize: "14px" }}>
        You can add unlimited number of employees on this plan to enjoy big discounts when shopping at our merchants stores.
      </p>
    </div>
  );
};
