import { useQuery, useMutation } from "react-query";
import { useAxios, ServerError, WalletType, TransactionStatusType } from "suregifts-component-lib";

export const useGetDiscountSettings = () => {
  const axios = useAxios();
  return useQuery<DiscountPlanData, ServerError>({
    queryKey: ["discount-plans"],
    keepPreviousData: true,
    queryFn: () => axios.get("/discounts/api/discount-settings"),
  });
};
export const useGetDiscountUsageStats = () => {
  const axios = useAxios();
  return useQuery<UsageStatsData[], ServerError>({
    queryKey: ["discount-usage-status"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/discounts/api/usage-stats"),
  });
};

export const useGetClientPlan = () => {
  const axios = useAxios();
  return useQuery<ClientPlanData, ServerError>({
    queryKey: ["discount-client-plan"],
    keepPreviousData: true,
    queryFn: () => axios.get("/discounts/api/client-plan"),
  });
};
export const useSubscribe = () => {
  var axios = useAxios();
  return useMutation<PaymentResponse, ServerError, SubscribeModel>((model) => {
    return axios.post("/discounts/api/subscribe", model);
  });
};
export const useIncreaseUnits = () => {
  var axios = useAxios();
  return useMutation<PaymentResponse, ServerError, IncreaseUnitModel>((model) => {
    return axios.post("/discounts/api/add-units", model);
  });
};

export const useRenewPlan = () => {
  var axios = useAxios();
  return useMutation<PaymentResponse, ServerError, RenuewPlanModel>((model) => {
    return axios.post("/discounts/api/renew-plan", model);
  });
};
export const useCancelPlan = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError>(() => {
    return axios.post("/discounts/api/cancel-plan");
  });
};
export const useReactivatePlan = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError>((model) => {
    return axios.post("/discounts/api/reactivate-plan", model);
  });
};
export const useUploadFile = () => {
  var axios = useAxios();
  return useMutation<UploadFileData, ServerError, UploadFileModel>((model) => {
    return axios.post("/discounts/api/upload-file", model);
  });
};
export const useSaveUpload = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, SaveUploadModel>((model) => {
    return axios.post("/discounts/api/save-upload", model);
  });
};
export const useGetRecipients = () => {
  const axios = useAxios();
  return useQuery<RecipientData[], ServerError>({
    queryKey: ["discounts-recipients"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/discounts/api/recipients"),
  });
};
export const useAddStaff = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, AddStaffModel>((model) => {
    return axios.post("/discounts/api/recipients", model);
  });
};
export const useEditStaff = (recipientId: any) => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, EditRecipientModel>((model) => {
    return axios.post(`/discounts/api/recipients/${recipientId}`, model);
  });
};
export const useDeleteStaff = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, number[]>((model) => {
    return axios.delete("/discounts/api/recipients", { data: { recipientIds: model } as DeleteRecipientModel });
  });
};
export interface DiscountPlanData {
  price: number;
}
export interface UploadFileData {
  total: number;
}

export interface ClientPlanData {
  price: number;
  unitPrice: number;
  expiryDate: string;
  paidUnits: number;
  usedUnits: number;
  discountedUnits: number;
  discountedVouchers: number;
  totalDiscounts: number;
  expired: boolean;
  canRenew: boolean;
  renewalTypeId: DiscountStatusType;
  renewalType: string;
}
export interface SubscribeModel {
  units: string;
  paymentMethodId: WalletType;
  returnPath?: string;
}
export interface IncreaseUnitModel {
  units?: any;
  paymentMethodId?: WalletType;
  fileUrl?: string;
  recipients: AddRecipientModel[];
  returnPath?: string;
}

export interface RenuewPlanModel {
  paymentMethodId?: WalletType;
  returnPath?: string;
  units;
}
export interface UploadFileModel {
  fileUrl?: string;
  recipients?: AddRecipientModel[];
  paymentMethodId?: WalletType;
}
export interface SaveUploadModel {
  fileUrl?: string;
}
export interface UsageStatsData {
  name: string;
  email: string;
  totalOrders: number;
  discountsGiven: number;
  discountedVouchers: number;
}
export interface RecipientData {
  id: number;
  name: string;
  phone: string;
  email: string;
}
export interface AddRecipientModel {
  name: string;
  email: string;
  phone: string;
}
export interface AddStaffModel {
  recipients: AddRecipientModel[];
}
export interface DeleteRecipientModel {
  recipientIds: number[];
}
export interface EditRecipientModel {
  name: string;
  email: string;
  phone: string;
}
export interface PaymentResponse {
  amountPaid: number;
  outstanding: string;
  paymentLink?: string;
  paymentMethodId: WalletType;
  paymentMethod: string;
  paymentStatusId: TransactionStatusType;
  paymentStatus: string;
  invoiceNumber: string;
}
export enum DiscountStatusType {
  AutoRenuew = 1,
  OneTime = 2,
}
