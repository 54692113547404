import { Stack } from "@mui/material";
import { PageHeaderTitle, FormButton } from "suregifts-component-lib";
import { PaymentMethodCard } from "./PaymentMethodCard";

export const SubmitOrderView = ({ handleSubmit, isSending }) => {
  return (
    <Stack sx={{ marginTop: "1.875rem" }} spacing={3}>
      <PageHeaderTitle title="Payment Method" />
      <PaymentMethodCard />
      <FormButton label="Place Order" fullWidth onClick={handleSubmit} loading={isSending} />
    </Stack>
  );
};
