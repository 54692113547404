import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { DialogTitle, FormButton, FormInput, YupShape, useDocumentTitle } from "suregifts-component-lib";
import { ChangePasswordModel, useChangePassword } from "../../services/auth";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const schema = yup
  .object<YupShape<ChangePasswordModel>>({
    currentPassword: yup.string().required(),
    newPassword: yup.string().required(),
    confirmPassword: yup.string().required(),
  })
  .required();

function ChangePasswordModal({}) {
  const { mutate: changePassword, isLoading } = useChangePassword();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ChangePasswordModel>({
    resolver: yupResolver(schema),
  });
  const handleClose = () => {
    navigate("..");
  };
  const submitForm = (data) => {
    changePassword(data, {
      onSuccess: () => {
        toast.success("Password changed successfully.");
        handleClose();
      },
    });
  };
  return (
    <Dialog open maxWidth={"xs"}>
      <DialogTitle title="Change Password" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput type="password" label="Current Password" {...register("currentPassword")} helperText={errors.currentPassword?.message} />
          <FormInput type="password" label="New password" {...register("newPassword")} helperText={errors.newPassword?.message} />
          <FormInput type="password" label="Confirm Password" {...register("confirmPassword")} helperText={errors.confirmPassword?.message} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton disabled={!isValid} label="Change Password" loading={isLoading} fullWidth onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default ChangePasswordModal;
