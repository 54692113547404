import { Box, Stack, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";
import ChangePasswordModal from "./ChangePasswordModal";
import { useNavigate } from "react-router-dom";
type CompanyLogoViewprops = {
  photo?: string;
  onPhotoChange?: (photo: string) => void;
  onClick: () => void;
  files?: string | string[];
};
const CompanyLogoView = ({ photo, onPhotoChange = () => {}, files, onClick }: CompanyLogoViewprops) => {
  useEffect(() => {
    onPhotoChange(Array.isArray(files) ? files[0] : files!);
  }, [files]);
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", gap: "1.25rem", position: "relative" }}>
      <Box component={"img"} onClick={onClick} src={photo || "/assets/images/company-logo.png"} sx={{ width: "5.625rem", height: "5.625rem", overflow: "hidden", cursor: "pointer", borderRadius: "50%", objectFit: "cover" }}></Box>
      <Stack alignItems="start" justifyContent={"center"}>
        <Typography color="#AAAABE" sx={{ fontSize: "0.875rem" }}>
          Business Logo
        </Typography>
        <Button onClick={onClick} variant="text" color="success" sx={{ mt: "0", padding: "0", width: "auto" }}>
          Choose File
        </Button>
        <Typography color="#AAAABE" fontSize={"0.625rem"}>
          90px by 90px
        </Typography>
      </Stack>
      <Button onClick={() => navigate("change-password")} sx={{ position: "absolute", right: 1, top: 0 }} color="success" variant="text">
        Change Password
      </Button>
    </div>
  );
};
export default CompanyLogoView;
