import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { MainLayoutTopComponent } from "../../components/molecules/MainLayoutTopComponent";

function Main() {
  return (
    <Box
      sx={{
        bgcolor: "primary.dark",
        padding: "0.625rem 2.5rem 3.25rem",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
      component={"main"}
    >
      <MainLayoutTopComponent open={false} />
      <div style={{ flexGrow: 1 }}>
        <Outlet />
      </div>
    </Box>
  );
}

export default Main;
