import { Card, Stepper, Step, StepLabel, useTheme, Box } from "@mui/material";
import { PageBackView, PageBackViewProps } from "../../components/molecules/PageBackView";
import { useMemo } from "react";

type CustomSteperProps = {
  tabValue: number;
  tabs: string[];
  backs: any[];
};
export const CustomSteper = ({ tabValue, tabs, backs }: CustomSteperProps) => {
  const theme = useTheme();
  const back = useMemo(() => {
    if (!backs || tabs.length - 1 < tabValue) return null;
    return backs[tabValue];
  }, [tabValue]);
  return (
    <Box sx={{ display: "grid", gap: "10px", maxWidth: "md", marginX: "auto" }}>
      {back && <PageBackView {...back} />}
      <Card
        sx={{
          width: "100%",
          marginBottom: "30px",
          backgroundColor: theme.palette.common.black,
          borderRadius: "20px",
          padding: "18px 60px",
          borderWidth: 1,
          "& .MuiStepLabel-root  .MuiStepLabel-iconContainer": {
            "& .MuiSvgIcon-root": {
              color: "success.main",
              "& .MuiStepIcon-text": {
                fill: theme.palette.primary.dark,
              },
            },
            "&.Mui-disabled ": {
              "& .MuiSvgIcon-root": {
                color: theme.palette.grey[900],
              },
            },
          },
          "& span.MuiStepLabel-label.Mui-active.Mui-disabled": {
            color: "primary.light",
          },
        }}
      >
        <Stepper activeStep={tabValue}>
          {tabs.map((label) => (
            <Step key={label} active>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Card>
    </Box>
  );
};
