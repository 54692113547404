import { Box } from "@mui/material";
import OrderDetailsTabView from "./details/OrderDetailsTabView";
import { TemplateTabView } from "./personalize/TemplateTabView";
import ReviewMainTabView from "./review/ReviewMainTabView";
import { TabPanel, useDocumentTitle } from "suregifts-component-lib";
import { useVoucherRequestContext } from "./VoucherRequestContext";
import { CustomSteper } from "../order-request-download/CustomSteper";

function VoucherRequestScreen() {
  const { state: model, updateState } = useVoucherRequestContext();
  useDocumentTitle("Voucher Purchase");

  return (
    <Box>
      <CustomSteper
        tabValue={model.tabValue}
        tabs={["Voucher Details", "Personalize", "Review and send"]}
        backs={[
          undefined,
          {
            title: "Send Voucher",
            onClick: () => updateState({ tabValue: 0 } as any),
          },
          {
            title: "Personalize",
            onClick: () => updateState({ tabValue: 1 } as any),
          },
        ]}
      />
      <TabPanel index={0} value={model.tabValue}>
        <OrderDetailsTabView />
      </TabPanel>
      <TabPanel index={1} value={model.tabValue}>
        <TemplateTabView />
      </TabPanel>
      <TabPanel index={2} value={model.tabValue}>
        <ReviewMainTabView />
      </TabPanel>
    </Box>
  );
}

export default VoucherRequestScreen;
