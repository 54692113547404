import { Container, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { SocialSection } from "./SocialSection";
import moment from "moment";
import { Link } from "react-router-dom";
import { CopyrightSection } from "./CopyrightSection";

function FooterSection() {
  return (
    <Container>
      <Box
        sx={{
          paddingY: "3.75rem",
        }}
      >
        <SocialSection />

        <CopyrightSection />
      </Box>
    </Container>
  );
}

export default FooterSection;
