import { Box, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";
import { AuthSection } from "./AuthSection";

export const SectionOne = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: "#1D1C22",
        borderRadius: "2rem",
        backdropFilter: "27px",
        padding: "126px 32px",
        marginTop: "3rem",
        textAlign: "center",
        h2: {
          fontSize: "56px",
          lineHeight: "120%",
          fontWeight: "700",
        },
        p: {
          lineHeight: "30px",
          fontSize: "18px",
        },
        "*": {
          margin: "0",
        },
        display: "grid",
        gap: "2rem",
      }}
    >
      <h2>
        Enjoy Big Savings <br /> on the brands you love
      </h2>
      <p style={{ opacity: 0.7 }}>
        Your staff deserves to buy more with less. Give your employees the <br />
        opportunity to buy more with less.
      </p>
      <AuthSection justifyContent="center" />
    </Box>
  );
};
