import { createContext, useContext, useMemo, useState } from "react";
import { ClientPlanData, DiscountPlanData, useGetClientPlan, useGetDiscountSettings } from "../../services/discount";
import { DiscountIntroModal } from "../../contexts/DiscountIntroModal";
import { useLocation, useNavigate } from "react-router-dom";

type DiscountContextValueProps = {
  clientPlan?: ClientPlanData;
  isLoadingPlan: boolean;
  reloadPlan: () => void;
  openModal: () => void;
  price?: number;
  planSetting?: DiscountPlanData;
};
const DiscountContext = createContext({} as DiscountContextValueProps);

export const useDiscountContext = () => {
  return useContext(DiscountContext);
};

export const DiscountContextProvider = ({ children }) => {
  const { data: clientPlan, isFetching: isLoadingPlan, refetch: reloadPlan } = useGetClientPlan();
  const naviate = useNavigate();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const { data: planSetting } = useGetDiscountSettings();

  const price = useMemo(() => {
    return clientPlan?.unitPrice || planSetting?.price;
  }, [clientPlan, planSetting]);
  return (
    <>
      <DiscountContext.Provider
        value={{
          clientPlan,
          openModal: () => {
            if (pathname.indexOf("choose-plan") == -1 && !clientPlan) {
              setOpen(true);
            }
          },
          price,
          reloadPlan,
          isLoadingPlan,
          planSetting,
        }}
      >
        {children}
        <DiscountIntroModal
          open={open}
          hide={() => {
            setOpen(false);
          }}
          start={() => {
            setOpen(false);
            naviate("/discounts/subscribe");
          }}
        />
      </DiscountContext.Provider>
    </>
  );
};
