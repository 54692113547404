import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormSelect, FormInput, useUtilityContent } from "suregifts-component-lib";
import { useGetGroups } from "../../../../services/receipient";

type GropuAirtimeSectionProps = {
  airtime;
};
export const GropuAirtimeSection = ({ airtime }: GropuAirtimeSectionProps) => {
  const {
    form: {
      register,
      control,
      formState: { errors },
      watch,
      setValue,
    },
  } = useUtilityContent();
  const { data: groups } = useGetGroups({ page: 0, size: 9999999, search: "" });
  const model = watch();
  useEffect(() => {
    if (model.groupId) {
      var group = groups?.items.find((x) => x.id === parseInt(model.groupId!));
      setValue("quantity", group?.members ?? 0);
    } else {
      setValue("quantity", 0);
    }
  }, [model.groupId, groups]);
  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction="row">
        <Controller render={({ field }) => <FormSelect options={groups?.items.map((x) => ({ id: x.id, name: `${x.name}s (${x.members} members)` }))} label="Select Group" {...field} />} name={"groupId"} control={control} />
        <FormInput isNumber value={watch("amount")} label={`Amount (${airtime.currencySymbol})`} disabled={!!airtime?.amount} {...register("amount")} helperText={errors.amount?.message} />
      </Stack>
      <Typography sx={{ fontSize: "0.875rem !important ", fontWeight: 500 }}>
        Have a new list?&nbsp;&nbsp;
        <Typography to={"/recipients/groups"} sx={{ fontSize: "0.875rem !important " }} component={Link} color="success.main">
          Upload CSV File
        </Typography>
      </Typography>
    </Stack>
  );
};
