import { Controller } from "react-hook-form";
import { RecipientCsvFileUploader } from "./ButtonUpload";

export const GroupUploadView = ({ resetField, control }) => {
  return (
    <>
      <Controller control={control} name="fileUrl" render={({ field: { onChange } }) => <RecipientCsvFileUploader onChange={onChange} />} />
    </>
  );
};
