import { Box, Link, Typography } from "@mui/material";
import moment from "moment";

import { useGlobalContext } from "../../../contexts/GlobalContext";

export const CopyrightSection = () => {
  const { initialSetting } = useGlobalContext();
  return (
    <Box
      sx={{
        marginTop: "3.125rem",
        display: "flex",
        flexWrap: "wrap",
        gap: "1.875rem",
        justifyContent: "space-between",
        color: "text.disabled",
        lineHeight: "1.25rem",
        "& a": {
          color: "text.disabled",
          textDecoration: "none",
        },
      }}
    >
      <Typography>© {`${moment().format("YYYY")}`} Suregifts. All Rights Reserved.</Typography>
      <Typography>
        <Link href={`${initialSetting?.websiteUrl}/terms-and-condition`}>Terms</Link> | <Link href={`${initialSetting?.websiteUrl}/privacy-policy`}>Privacy Policy</Link>
      </Typography>
    </Box>
  );
};
