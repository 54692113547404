import { Box } from "@mui/material";
import { formatNumber } from "suregifts-component-lib";
import { useDiscountContext } from "../DiscountContext";
import { GreenBorderLabel } from "../GreenBorderLabel";

export const PageTitleView = () => {
  const { clientPlan } = useDiscountContext();
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          "div.logo": {
            width: "44px",
            height: "44px",
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50vh",
          },
          alignItems: "start",
          h1: {
            margin: 0,
          },
          justifyContent: "",
        }}
      >
        <div className="logo">
          <img src="/assets/images/discounts/discount 1.png" alt="Logo" />
        </div>
        <div>
          <h1> Discount Plan</h1>
          <div style={{ color: "#AAAABE" }}>
            You paid for &nbsp; <GreenBorderLabel label={`${formatNumber(clientPlan?.paidUnits)} employee`} />
          </div>
        </div>
      </Box>
    </div>
  );
};
