import { formatMoney } from "suregifts-component-lib";
import { Box } from "@mui/material";
import { useGlobalContext } from "../../contexts/GlobalContext";

export const SummaryPanel = ({ units, price }) => {
  const { initialSetting } = useGlobalContext();

  if (!units || !price) return <></>;

  return (
    <Box sx={{ display: "grid", gap: "6px", borderRadius: "21px", backgroundColor: "primary.dark", padding: "22px", fontWeight: 500, marginTop: "1rem" }}>
      <div>You’ll Pay</div>
      <div style={{ fontSize: "32px", fontWeight: "500" }}>
        {formatMoney(price * parseInt(units), initialSetting?.currency)}
        <span style={{ fontSize: "16px", opacity: "0.7" }}>/YEAR</span>
      </div>
      <div>For {units} employees</div>
    </Box>
  );
};
