import { TableHead, TableRow, TableCell, TableBody, IconButton } from "@mui/material";
import { Pagination, MainTable, MarginTop40Box, TransactionStatusLabel, dateOnly } from "suregifts-component-lib";
import moment from "moment";
import { useGetTransactions } from "../../services/transaction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

function TableView({ filter, handleFilter }) {
  const { data, isFetching } = useGetTransactions(filter);
  const navigate = useNavigate();
  return (
    <>
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Transaction Type</TableCell>

            <TableCell>Narration</TableCell>
            <TableCell>View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={`${item.transactionId}-${item.transactionTypeId}`}>
              <TableCell>{dateOnly(item.date)}</TableCell>
              <TableCell>
                <TransactionStatusLabel status={item.statusId} text={item.amount} />
              </TableCell>
              <TableCell>{item.transactionMode}</TableCell>

              <TableCell>{item.narration}</TableCell>
              <TableCell sx={{ width: "10%", whiteSpace: "nowrap" }}>
                <IconButton
                  onClick={() => {
                    sessionStorage.setItem("TRANSACTION_DATA", JSON.stringify(item));
                    navigate(`transaction-info/${item.transactionId}`);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          page={filter.page}
          total={data?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
    </>
  );
}

export default TableView;
