import { Dialog, DialogActions, DialogContent } from "@mui/material";

import { DialogTitle, FormButton, FormInput, FormSelect, YupShape } from "suregifts-component-lib";
import { Stack } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { EditManagerModel, ManagerData, useEditManager, useGetRoles } from "../../services/business";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { RecipientData } from "../../services/order";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
export const schema = yup
  .object<YupShape<EditManagerModel>>({
    role: yup.string().required(),
  })
  .required();
function EditManagerModal() {
  const { data: roles } = useGetRoles();
  const navigate = useNavigate();

  const [manager] = useState(() => {
    const data = sessionStorage.getItem("MANAGER_DATA");
    if (!data) return {} as ManagerData;

    return JSON.parse(data) as ManagerData;
  });
  const { mutate: addManager, isLoading } = useEditManager(manager!.id);
  const queryClient = useQueryClient();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<EditManagerModel>({
    defaultValues: {
      role: manager!.roleId,
    },
    resolver: yupResolver(schema),
  });
  const submitForm = (data) => {
    addManager(data, {
      onSuccess: () => {
        toast.success("User added successfully.");
        queryClient.invalidateQueries("managers");
        handleClose();
      },
    });
  };
  const handleClose = () => {
    navigate("..");
  };
  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title="Edit User" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput label="First Name" {...register("firstName")} helperText={errors.firstName?.message} />
          <FormInput label="Last Name" {...register("lastName")} helperText={errors.lastName?.message} />
          <FormInput label="Email Address" value={manager.email} helperText={errors.email?.message} />
          <Controller control={control} name="role" render={({ field }) => <FormSelect {...field} label="Role" options={roles} />} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton label="Update" fullWidth loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default EditManagerModal;
