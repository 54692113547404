import { AddBankTransferInfoModel, useAddBankTransferInfo } from "../../../services/transaction";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { DialogTitle, FormButton, YupShape } from "suregifts-component-lib";
import { BankTransferInfoForm } from "../../wallet/BankTransferInfoForm";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { toast } from "react-toastify";

const schema = yup
  .object<YupShape<AddBankTransferInfoModel>>({
    amount: yup.number().required(),
    accountUsed: yup.string().required(),
    depositorName: yup.string().required(),
    date: yup.date().required(),
    referenceNumber: yup.string().required(),
  })
  .required();

function AddBankTransferInfoModal({ invoiceNumber, onClose = () => {} }) {
  const { mutate: send, isLoading } = useAddBankTransferInfo();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<AddBankTransferInfoModel>({
    defaultValues: {
      accountUsed: "",
      date: moment().toISOString(),
      invoiceNumber,
    },
    resolver: yupResolver(schema),
  });
  const submitForm = (model) => {
    send(model, {
      onSuccess: () => {
        toast.success("Payment info received successfuly.");
        onClose();
      },
    });
  };
  const handleClose = () => {
    onClose();
  };
  if (!invoiceNumber) return <></>;
  return (
    <Dialog open>
      <DialogTitle title="Confirm Payment" handleClose={handleClose} />
      <DialogContent>
        <BankTransferInfoForm control={control} errors={errors} register={register} />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          button: {
            flexGrow: 1,
          },
          gap: 2,
        }}
      >
        <FormButton label="Close" color="primary" onClick={handleClose} />
        <FormButton loading={isLoading} disabled={!isValid} label="Send" onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default AddBankTransferInfoModal;
