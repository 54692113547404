import React from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";

function useNavigator() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return function (pathname: string) {
    navigate({
      pathname,
      search: `${createSearchParams(searchParams)}`,
    });
  };
}

export default useNavigator;
