import { Stack, FormControlLabel, Checkbox, styled } from "@mui/material";
import { FormInput, FormButton, YupShape, useDocumentTitle, FormSelect, useAuthContext } from "suregifts-component-lib";

import * as yup from "yup";
import { RegisterModel, useRegister } from "../../services/business";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Span } from "../../components/atoms/Span";
import { useGetIndustries } from "../../services/general";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { isRealValue } from "../../helpter";
export const schema = yup
  .object<YupShape<RegisterModel>>({
    email: yup.string().required().email(),
    password: yup.string().required(),
    name: yup.string().required(),
    confirmPassword: yup.string().required(),
    industryId: yup.string().required(),
    rcNumber: yup.string().required(),
    unit: yup.string().required(),
  })
  .required();
function SignUpView() {
  useDocumentTitle("Register");
  const [cookies, setCookie] = useCookies(["BUSINESS_ID"]);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<RegisterModel>({
    defaultValues: {
      name: "",
      password: "",
      confirmPassword: "",
      unit: "",
      industryId: "",
      rcNumber: "",
      email: "",
    },
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { saveToken, isAuthenticated } = useAuthContext();
  useEffect(() => {
    if (isAuthenticated) {
      if (isRealValue(cookies.BUSINESS_ID)) {
        navigate("/dashboard");
      }
      navigate("/my-accounts");
    }
  }, [isAuthenticated]);

  const { mutate: registerBusiness, isLoading } = useRegister();
  const { data: industries } = useGetIndustries();

  const submitForm = (data) => {
    registerBusiness(data, {
      onSuccess: (result) => {
        toast.success("Registration successful.");
        setCookie("BUSINESS_ID", result.businessId, { path: "/" });
        saveToken(result.token, result.refreshToken, result);
      },
    });
  };
  return (
    <Stack spacing={2}>
      <FormInput {...register("name")} helperText={errors.name?.message} label="Company Name" />
      <Controller
        control={control}
        name="industryId"
        render={({ field }) => <FormSelect label="Industry" {...field} options={industries} helperText={errors.industryId?.message} />}
      />
      <FormInput {...register("email")} helperText={errors.email?.message} label="Email" />
      <FormInput {...register("password")} helperText={errors.password?.message} label="Password" type="password" />
      <FormInput {...register("confirmPassword")} helperText={errors.confirmPassword?.message} label="Confirm Password" type="password" />
      <FormInput {...register("rcNumber")} helperText={errors.rcNumber?.message} label="CAC/RC Number" />
      <FormInput {...register("unit")} helperText={errors.unit?.message} label="Business Unit" />
      <div>
        <FormControlLabel
          sx={{ color: "text.secondary" }}
          control={<Checkbox color="success" />}
          label={
            <p>
              I Accept the <Span sx={{ color: "success.main" }}>terms & conditons</Span> and <Span sx={{ color: "success.main" }}>privacy policy</Span>
            </p>
          }
        />
        <FormControlLabel sx={{ color: "text.secondary" }} control={<Checkbox color="success" />} label="I want to receive Suregifts's Newsletter" />
      </div>

      <FormButton fullWidth label="Sign Up" onClick={handleSubmit(submitForm)} loading={isLoading} />
    </Stack>
  );
}

export default SignUpView;
