import { TableHead, TableRow, TableCell } from "@mui/material";
import moment from "moment";
import { PageHeaderTitle, MainTable, TableBody, OrderStatusLabel, dateTime } from "suregifts-component-lib";
import { useGetRecentOrders } from "../../services/order";

export const RecentOrderSection = () => {
  const { data: orders, isLoading } = useGetRecentOrders();
  return (
    <div>
      <PageHeaderTitle title={"Recent Orders"} />
      <MainTable sx={{ marginTop: "1.875rem" }}>
        <TableHead>
          <TableRow>
            <TableCell>Order No</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Recipients</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date - Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders?.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.orderNumber}</TableCell>
              <TableCell>{item.amountDue}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>
                <OrderStatusLabel label={item.status} status={item.statusId} />
              </TableCell>
              <TableCell>{dateTime(item.dateCreated)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
    </div>
  );
};
