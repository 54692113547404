import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormButton, FormInput, YupShape, useDocumentTitle } from "suregifts-component-lib";
import { ForgotPasswordModel, useForgotPassword } from "../../services/auth";
import * as yup from "yup";
import { toast } from "react-toastify";
export const schema = yup
  .object<YupShape<ForgotPasswordModel>>({
    email: yup.string().required().email(),
  })
  .required();

function ForgotPassword() {
  useDocumentTitle("Forgot Password");
  const navigate = useNavigate();
  const theme = useTheme();
  const { mutate: forgotPassword, isLoading } = useForgotPassword();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ForgotPasswordModel>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });
  const submitForm = (data) => {
    forgotPassword(data, {
      onSuccess: () => {
        toast.success("Success. Please check your email for reset link.");
        navigate("/login");
      },
    });
  };
  return (
    <div>
      <Typography sx={{ fontSize: "2.75rem", fontWeight: 700, marginTop: "2.3125rem" }}>Forgot Password</Typography>
      <div style={{ marginTop: "1.4375rem" }}>Not to worry! Enter the email address for your account and we'll send you a reset link.</div>
      <Stack spacing={2} marginTop="2.5rem">
        <FormInput size="medium" label="Email Address" {...register("email")} helperText={errors.email?.message} />
        <div style={{ display: "flex", justifyContent: "end", color: "green" }}>
          <Link style={{ color: theme.palette.success.main, textDecoration: "none" }} to={"/login"}>
            Login
          </Link>
        </div>
        <FormButton loading={isLoading} label="Send" fullWidth onClick={handleSubmit(submitForm)} />
      </Stack>
    </div>
  );
}

export default ForgotPassword;
