import { Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { FormButton, FormInput, LoadingBox, PageHeaderTitle, useDocumentTitle } from "suregifts-component-lib";
import { useRefreshCredential } from "../../services/business";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useBusinessContext } from "../../contexts/BusinessContext";
import { useQueryClient } from "react-query";

function DeveloperScreen() {
  useDocumentTitle("Developer");
  const [cookie] = useCookies(["APPLICATION_MODE"]);
  const [secret, setSecret] = useState<string>();
  const queryClient = useQueryClient();
  const { bizInfo: data } = useBusinessContext();

  const { mutate: refreshCredential, isLoading: isRefreshing } = useRefreshCredential();

  const handleRefresh = () => {
    refreshCredential(
      {},
      {
        onSuccess: (data) => {
          setSecret(data.secret);
          toast.success("App credential successfully updates.");
          queryClient.invalidateQueries("business-info");
        },
      }
    );
  };

  const mode = useMemo(() => {
    const md: string = cookie.APPLICATION_MODE || "live";
    return `${md} MODE`.toUpperCase();
  }, []);
  return (
    <div>
      <PageHeaderTitle title="Developer" />
      <LoadingBox
        loading={isRefreshing}
        sx={{
          gap: "40px",
          display: "grid",
          marginTop: "40px",
          borderRadius: "30px",
          backgroundColor: "primary.main",
          padding: "40px 50px",
        }}
      >
        <Typography style={{ fontSize: "1.5rem", fontWeight: 700, position: "relative" }}>API Configuration - {mode}</Typography>

        <Stack spacing={3} sx={{}}>
          <FormInput label="Public Key" value={data?.credential.key} endAdornment={<CopyIconButton value={data?.credential.key} />} />
          {secret && <FormInput label="Secret Key" value={secret} endAdornment={<CopyIconButton value={secret} />} />}

          <FormButton fullWidth sx={{ alignSelf: "flex-start" }} loading={isRefreshing} label="Generate Secret" onClick={handleRefresh} />
        </Stack>
      </LoadingBox>
    </div>
  );
}
const CopyIconButton = ({ value = "" }) => {
  return (
    <ContentCopyIcon
      sx={{ cursor: "pointer" }}
      onClick={() => {
        navigator.clipboard.writeText(value);
        toast.success("Key copied!");
      }}
    />
  );
};

export default DeveloperScreen;
