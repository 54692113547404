import { Box, TableRow, TableCell, IconButton, TableHead } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { PageHeaderTitle, OrderItemStatusLabel, DeliveryStatusType, usePagination, Pagination, MainTable, TableBody, ResendIcon, EditPencilIcon, PageFilter, SearchContainer, SearchIcon, SearchInput } from "suregifts-component-lib";
import { OrderInfoData, RecipientData, useResendVoucher } from "../../../../services/order";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import EditRecipientModal from "./EditRecipientModal";
type RecipientSectionProps = {
  data?: OrderInfoData;
};
function RecipientSection({ data }: RecipientSectionProps) {
  const { mutate: resendVoucher, isLoading } = useResendVoucher();
  const [filter, setFilter] = useState({ search: "", page: 1 } as PageFilter);

  const recipients = useMemo(() => {
    if (!data?.recipients) return [];
    if (!filter.search) return data.recipients;

    return data.recipients.filter((item) => {
      if (item.firstName?.toLowerCase().includes(filter.search.toLocaleLowerCase())) return true;
      if (item.lastName?.toLowerCase().includes(filter.search.toLocaleLowerCase())) return true;
      if (item.email?.toLowerCase().includes(filter.search.toLocaleLowerCase())) return true;

      return false;
    });
  }, [data?.recipients, filter.search]);

  const [openChangeEmail, setOpenChangeEmail] = useState<RecipientData | undefined>();
  const queryClient = useQueryClient();
  const handleResend = (item: RecipientData) => {
    resendVoucher(item.id, {
      onSuccess: () => {
        toast.success("Voucher resent successuflly.");
        refetch();
      },
    });
  };
  const refetch = () => {
    queryClient.invalidateQueries("order-by-id");
  };
  const { data: items, total } = usePagination(filter.page, recipients, 10);
  return (
    <div style={{}}>
      <SearchContainer>
        <PageHeaderTitle title="Recipients" />
        <SearchInput sx={{ maxWidth: "400px" }} placeholder="Search" value={filter.search} onChange={(search) => setFilter((prev) => ({ ...prev, search } as PageFilter))} />
      </SearchContainer>
      <Box sx={{ marginTop: "1.5625rem" }}>
        <MainTable sx={{}}>
          <TableHead>
            <TableRow>
              <TableCell>Recipient</TableCell>
              <TableCell>Voucher Value</TableCell>
              <TableCell>Voucher Serial</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell sx={{ wordBreak: "break-word" }}>
                  {item.firstName} {item.lastName} <br />
                  <SecondLabel>{item.email}</SecondLabel>
                </TableCell>
                <TableCell>{item.value}</TableCell>
                <TableCell>{item.serialNumber}</TableCell>
                <TableCell>
                  <OrderItemStatusLabel label={item.status} status={item.statusId} />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => setOpenChangeEmail(item)}>
                    <EditPencilIcon />
                  </IconButton>
                  <IconButton onClick={() => handleResend(item)} disabled={item.statusId !== DeliveryStatusType.Delivered}>
                    <ResendIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </Box>
      <Pagination
        page={filter.page}
        total={total}
        onChange={(page) => {
          setFilter((prev) => ({ ...prev, page }));
        }}
      />
      {openChangeEmail && (
        <EditRecipientModal
          handleClose={() => {
            setOpenChangeEmail(undefined);
            refetch();
          }}
          recipient={openChangeEmail}
        />
      )}
    </div>
  );
}
export const SecondLabel = ({ children }) => {
  return <Box sx={{ color: "text.secondary", fontSize: "14px" }}>{children}</Box>;
};
export default RecipientSection;
