import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { DialogTitle, FormButton, FormInput } from "suregifts-component-lib";
import { ChangeEmailModel, RecipientData, useChangeRecipientEmail } from "../../../../services/order";
import { toast } from "react-toastify";

type EditRecipientModalProps = {
  recipient: RecipientData;
  handleClose: () => void;
};

function EditRecipientModal({ recipient: data, handleClose }: EditRecipientModalProps) {
  const { mutate: changeEmail, isLoading } = useChangeRecipientEmail();
  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<ChangeEmailModel>({
    defaultValues: {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      itemId: data.id,
    },
  });
  const onSubmit = (model) => {
    changeEmail(model, {
      onSuccess: () => {
        toast.success("Email changed successfully");
        handleClose();
      },
    });
  };
  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title="Edit Recipient" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput label="First Name" {...register("firstName")} helperText={errors.firstName?.message} />
          <FormInput label="Last Name" {...register("lastName")} helperText={errors.lastName?.message} />
          <FormInput label="Email Address" {...register("email")} helperText={errors.email?.message} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <FormButton disabled={!isValid} label={"Submit"} fullWidth onClick={handleSubmit(onSubmit)} loading={isLoading} />
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
}

export default EditRecipientModal;
