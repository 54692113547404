import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useTemplateContext, useDeleteTemplate, TemplateData, TemplateFilterSection, TemplateThumbnail, FormButton, usePagination, Pagination, PageFilter, useTemplatesMemo } from "suregifts-component-lib";

export const TemplateSelectorView = ({ onComplete, edit }) => {
  const [filter, setFilter] = useState({ page: 1, size: 12 } as PageFilter);
  const { state, changeTemplate } = useTemplateContext();
  const templates = useTemplatesMemo();
  useEffect(() => {
    changeTemplate(templates?.at(0));
  }, [templates]);
  const { data, total } = usePagination(filter.page, templates, filter.size);
  return (
    <Box sx={{ maxWidth: "md", marginX: "auto" }}>
      <TemplateFilterSection />
      <Box sx={{ height: "100%", minHeight: "400px", marginTop: "30px" }}>
        <Box
          sx={{
            display: "grid",
            gap: "24px",
            gridTemplateColumns: "repeat(4,190px)",
            justifyContent: "space-between",
            overflow: "hidden",
            tableLayout: "fixed",
            position: "relative",
          }}
        >
          {data?.map((item, i) => (
            <Box
              component={"img"}
              key={item.id}
              src={item.imageUrl}
              sx={{
                cursor: "pointer",
                border: item.id === state.template?.id ? "4px solid red" : "none",
                width: "170px",
                maxWidth: "170px",
                maxHeight: "220px",
                height: "220px",
                borderRadius: "10px",
                objectFit: "cover",
                objectPosition: "0 0",
              }}
              onClick={() => {
                changeTemplate(item);
              }}
            />
          ))}
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              width: "100%",
              bottom: "30px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "primary.dark",
                borderRadius: "28px",
                padding: "16px",
                boxShadow: 1,
                display: "flex",
                gap: "16px",
              }}
            >
              <FormButton color="success" label="Edit Template" onClick={() => edit()} />
              <FormButton label="Skip Edit" onClick={() => onComplete(state.template?.id, state.template?.imageUrl)} />
            </Box>
          </div>
        </Box>

        <div style={{ marginTop: "1.25rem" }}>
          <Pagination
            align="center"
            size={12}
            page={filter.page}
            total={total}
            onChange={(page) => {
              setFilter((prev) => ({ ...prev, page }));
            }}
          />
        </div>
      </Box>
    </Box>
  );
};
