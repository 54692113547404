import { Dialog, DialogActions, DialogContent } from "@mui/material";

import { DatePicker, DialogTitle, FormButton, FormInput, YupShape } from "suregifts-component-lib";
import { Stack } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RecipientData, RecipientModel } from "../../../services/receipient";
export const schema = yup
  .object<YupShape<RecipientModel>>({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    // phone: yup.string().required(),
  })
  .required();
type RecipientDialogProps = {
  title: string;
  buttonTitle: string;
  onSubmit: (model: any) => void;
  isLoading?: boolean;
  handleClose: () => void;
  recipient?: RecipientData;
};
export const RecipientDialog = ({ title, buttonTitle, onSubmit, handleClose, recipient, isLoading }: RecipientDialogProps) => {
  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<RecipientModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: recipient?.firstName || "",
      lastName: recipient?.lastName || "",
      phone: recipient?.phone || "",
      email: recipient?.email || "",
      birthday: undefined,
    },
  });

  return (
    <Dialog open maxWidth="xs" onClose={handleClose}>
      <DialogTitle title={title} handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput label="First Name" {...register("firstName")} helperText={errors.firstName?.message} />
          <FormInput label="Last Name" {...register("lastName")} helperText={errors.lastName?.message} />
          <FormInput label="Email Address" {...register("email")} helperText={errors.email?.message} />
          <FormInput label="Phone" {...register("phone")} helperText={errors.phone?.message} />
          {!recipient?.id && (
            <Controller
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  fullWidth
                  label="Birthdate"
                  value={undefined}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
              control={control}
              name="birthday"
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton disabled={!isValid} label={buttonTitle} fullWidth onClick={handleSubmit(onSubmit)} loading={isLoading} />
      </DialogActions>
    </Dialog>
  );
};
