import { Button, Stack } from "@mui/material";
import { FormButton, FormInput, YupShape } from "suregifts-component-lib";
import { AddRecipientModel } from "../../../services/discount";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const schema = yup
  .object<YupShape<AddRecipientModel>>({
    name: yup.string().required(),
    email: yup.string().required().email(),
  })
  .required();
type IndividualUploadComponentProps = {
  onStaffAdded: (staff: AddRecipientModel) => void;
};
export const IndividualUploadComponent = ({ onStaffAdded }: IndividualUploadComponentProps) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm<AddRecipientModel>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const navigate = useNavigate();
  const submitForm = (model) => {
    onStaffAdded(model);
    reset({ email: "", name: "", phone: "" });
  };
  return (
    <div>
      <p style={{ fontSize: "14px", fontWeight: 700 }}>Add employee details</p>
      <Stack spacing={2}>
        <Stack direction={"row"} spacing={2}>
          <FormInput label="Full Name" helperText={errors.name?.message} {...register("name")} />
          <FormInput label="Phone Number" {...register("phone")} />
        </Stack>
        <FormInput label="Email" helperText={errors.email?.message} {...register("email")} />
      </Stack>
      <Stack direction={"row"} spacing={2} alignItems={"center"} sx={{ marginTop: "40px" }}>
        <FormButton disabled={!isValid} label="Save and add another employee" onClick={handleSubmit(submitForm)} />
        <Button variant="text" onClick={() => navigate("/discounts/employees")} color="secondary">
          I’ll do this later
        </Button>
      </Stack>
    </div>
  );
};
