import { Box, Stack } from "@mui/material";
import { FormButton, FundButton, WalletType, getPaymentPath, useNotificationContext } from "suregifts-component-lib";
import { useMemo, useState } from "react";
import { IncreaseUnitModel, useIncreaseUnits } from "../../../services/discount";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import AddBankTransferInfoModal from "../payment/AddBankTransferInfoModal";
import { useGetFundingMethods } from "../../../services/transaction";
import { useEmployeeUploadContext } from "./EmployeeUploadContext";

export declare var location;
export const PaymentViewConmponent = ({ fileUrl, recipients }) => {
  const { payDiff } = useEmployeeUploadContext();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data } = useGetFundingMethods();
  const methods = useMemo(() => {
    const cash = [{ id: WalletType.CashWallet, name: "Cash Wallet" }];
    if (!data) return cash;
    return cash.concat(data);
  }, [data]);
  const [paymentMethodId, setPaymentMethodId] = useState(WalletType.CashWallet);
  const { confirm } = useNotificationContext();
  const { mutate: increaseUnit, isLoading, data: paymentResponse } = useIncreaseUnits();

  const submitIncreaseRequest = () => {
    if (paymentMethodId === WalletType.CashWallet) {
      confirm({ onConfirm: send });
    } else {
      send();
    }
  };
  const send = () => {
    const requestModel = {
      paymentMethodId: paymentMethodId,
      fileUrl: fileUrl,
      units: 0,
      recipients,
      returnPath: `payment-callback/${getPaymentPath(paymentMethodId)}`,
    } as IncreaseUnitModel;
    increaseUnit(requestModel, {
      onSuccess: (data) => {
        queryClient.invalidateQueries("discount-client-plan");
        if (data.paymentLink) {
          location.href = data.paymentLink;
        }
        if (data.paymentMethodId === WalletType.CashWallet) {
          toast.success("Employee list uploaded successfully.");
          navigate("/discounts");
        }
      },
    });
  };
  if (!payDiff) return <></>;

  return (
    <div>
      <Box sx={{}}>
        <div style={{ fontSize: "24px", fontWeight: 700 }}>Make payment</div>
        <div style={{ fontSize: "14px", fontWeight: 500, lineHeight: "167%", color: "#8C8C9D" }}>Select how you want to pay</div>
      </Box>
      <Stack spacing={2} style={{ marginTop: "1rem" }}>
        {methods.map((item) => (
          <FundButton
            onClick={() => {
              setPaymentMethodId(item.id);
            }}
            key={item.id}
            selected={item.id == paymentMethodId}
            method={item}
          />
        ))}
      </Stack>
      <FormButton onClick={submitIncreaseRequest} label="Make Payment" disabled={!paymentMethodId} loading={isLoading} fullWidth sx={{ marginTop: "46px" }} />
      {paymentResponse && (
        <div>
          {paymentMethodId === WalletType.BankTransfer && (
            <AddBankTransferInfoModal invoiceNumber={paymentResponse?.invoiceNumber} onClose={() => navigate("/discounts/employees")} />
          )}
        </div>
      )}
    </div>
  );
};
