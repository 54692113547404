import { TableHead, TableRow, TableCell, IconButton, Checkbox } from "@mui/material";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { EditPencilIcon, DeleteIcon, Pagination, MainTable, useNotificationContext, TableBody } from "suregifts-component-lib";
import { useRecipientContext } from "../RecipientContext";
import { useDeleteRecipient, useGetRecipients } from "../../../services/receipient";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { StorageKeys } from "../../../routes/RequireAuth";

function RecipientTableView({ filter, handleFilter }) {
  const { dispatch, state } = useRecipientContext();
  const { pathname } = useLocation();
  const { data, isFetching, refetch } = useGetRecipients(filter);
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname === "/recipients/vouchers") {
      refetch();
    }
  }, [pathname]);

  const { mutate: deleteRecord, isLoading: deleting } = useDeleteRecipient();
  const { confirm } = useNotificationContext();
  const handleDelete = (recipientId: any) => {
    confirm({
      onConfirm: () => {
        deleteRecord(recipientId, {
          onSuccess: () => {
            toast.success("Merchant deleted successufully.");
            refetch();
          },
        });
      },
    });
  };

  return (
    <>
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "3%" }}></TableCell>
            <TableCell>First name</TableCell>
            <TableCell>Last name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell sx={{ width: "12%" }}>Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell sx={{ width: "1%", padding: 0 }}>
                <Checkbox
                  color="success"
                  sx={{ padding: 0 }}
                  checked={state.selection!.indexOf(item.id) !== -1}
                  onChange={(e) => {
                    dispatch({ type: "ItemChecked", checked: e.target.checked, id: item.id });
                  }}
                />
              </TableCell>
              <TableCell>{item.firstName}</TableCell>
              <TableCell>{item.lastName}</TableCell>
              <TableCell>{item.email}</TableCell>

              <TableCell>{item.phone}</TableCell>
              <TableCell sx={{ width: "10%", whiteSpace: "nowrap" }}>
                <IconButton
                  color="success"
                  onClick={() => {
                    const data = { recipients: [item.id], numberOfvouchers: 1, narration: "" };
                    sessionStorage.setItem(StorageKeys.RECIPIENT_VOUCHERS, JSON.stringify(data));
                    navigate("/order-request");
                  }}
                >
                  <SendIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    sessionStorage.setItem("RECIPIENT_DATA", JSON.stringify(item));
                    navigate(`${item.id}/edit`);
                  }}
                >
                  <EditPencilIcon />
                </IconButton>
                <IconButton color="error" onClick={() => handleDelete(item.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          page={filter.page}
          total={data?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>

      <Outlet />
    </>
  );
}

export default RecipientTableView;
