import React from "react";
import { SummaryCardsContainer, SummaryCard } from "suregifts-component-lib";
import { SummaryData } from "../../services/general";
type SummarySectionProps = {
  data?: SummaryData;
};
function SummarySection({ data }: SummarySectionProps) {
  return (
    <div style={{ marginTop: "24px" }}>
      <SummaryCardsContainer>
        <SummaryCard color="warning" title={data?.walletBalance} info="wallet balance" />
        <SummaryCard color="success" title={data?.amountSpent} info="Amount Spent" />
        <SummaryCard color="error" title={data?.vouchersIssued} info="Voucher Issued" />
        <SummaryCard color="info" title={data?.totalRecipients} info="Total Recipient" />
      </SummaryCardsContainer>
    </div>
  );
}

export default SummarySection;
