import { Box, useTheme } from "@mui/material";
import React from "react";

import { DiscriptionSection, DiscriptionSectionProps } from "./DiscriptionSection";

export const MiddleSection = () => {
  const theme = useTheme();
  const data: DiscriptionSectionProps[] = [
    {
      header: "Purchase discount",
      desc: (
        <>
          Businesses/companies will need purchase the discount <br />
          for a particular number of employees.
        </>
      ),
    },
    {
      header: "Upload the list of employees",
      desc: (
        <>
          Upload a csv file which includes the details of the <br />
          number of employees you paid for in step 1.
        </>
      ),
    },
    {
      header: "Employees can now access discount",
      desc: (
        <>
          Your employees can now purchase suregifts <br />
          vouchers at great discounts.
        </>
      ),
    },
  ];
  return (
    <div>
      <h2 style={{ fontSize: "2rem", fontWeight: "600", lineHeight: "40px", marginTop: "2rem", marginBottom: "2rem" }}>How it works</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "grid", gap: "2rem" }}>
          {data.map((item) => (
            <DiscriptionSection key={item.header} {...item} />
          ))}
        </div>
        <Box sx={{ display: "none", [theme.breakpoints.up("md")]: { display: "block" } }}>
          <img src="/assets/images/discounts/logo-lg.png" alt="" />
        </Box>
      </div>
    </div>
  );
};
