import { useMemo, useState } from "react";
import FilterSection from "./FilterSection";
import { BillCard, BillerFilter, LoadingBox, MarginTop40Box, PageFilter, Pagination, useAuthContext, useDocumentTitle, useFilter, useGetBillers } from "suregifts-component-lib";
import { Box, useTheme } from "@mui/material";

type FilterProps = {
  countryCode?: string;
  categoryId?: string;
} & PageFilter;
function BillScreen() {
  const { user } = useAuthContext();
  const params = useMemo(() => {
    let countryCode: any = undefined;
    if (user?.countryCode === "NG" || user?.countryCode === "KE") {
      countryCode = user.countryCode;
    }
    return { countryCode: countryCode, page: 1, size: 12 } as FilterProps;
  }, []);
  const [filter, setFilter] = useFilter<BillerFilter>(params);
  const { data: data, isFetching } = useGetBillers(filter);
  const theme = useTheme();
  const handleFilter = (props) => {
    setFilter((prev) => ({ ...prev, ...props }));
  };
  useDocumentTitle("Bills");

  return (
    <div>
      <FilterSection handleFilter={handleFilter} filter={filter} />
      <MarginTop40Box>
        <LoadingBox
          loading={isFetching}
          sx={{
            display: "grid",
            gap: "1.25rem",
            [theme.breakpoints.up("sm")]: {
              gridTemplateColumns: "repeat(2,1fr)",
            },
            [theme.breakpoints.up("md")]: {
              gridTemplateColumns: "repeat(3,1fr)",
            },
            [theme.breakpoints.up("xl")]: {
              gridTemplateColumns: "repeat(4,1fr)",
            },
          }}
        >
          {data?.items.map((item) => (
            <BillCard key={item.billerId} name={item.name} logoUrl={item.logoUrl} url={`${item.billerId}`} categoryName={`${item.categoryName} (${item.countries})`} />
          ))}
        </LoadingBox>
      </MarginTop40Box>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          align="center"
          page={filter.page}
          total={data?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
    </div>
  );
}

export default BillScreen;
