import { TableCell, TableHead, TableRow } from "@mui/material";
import { MainTable, PageHeaderTitle, TableBody, formatMoney } from "suregifts-component-lib";
import { useGetDiscountUsageStats as useGetDiscountUsageStats } from "../../../../services/discount";
import { useGlobalContext } from "../../../../contexts/GlobalContext";

export const DiscountAnalyticsScreen = () => {
  const { data } = useGetDiscountUsageStats();
  const { initialSetting } = useGlobalContext();
  return (
    <div>
      <PageHeaderTitle title={"Usage analytics"} />

      <MainTable sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email Address</TableCell>
            <TableCell>Order Total</TableCell>
            <TableCell>Discount Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.email}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{formatMoney(item.totalOrders, initialSetting?.currency)}</TableCell>
              <TableCell>{formatMoney(item.discountsGiven, initialSetting?.currency)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
    </div>
  );
};
