import { FormButton, WalletType, formatMoney, getPaymentPath, useDocumentTitle, useNotificationContext } from "suregifts-component-lib";
import { RenuewPlanModel, useRenewPlan } from "../../../services/discount";
import { Box, useTheme } from "@mui/material";
import AddBankTransferInfoModal from "../payment/AddBankTransferInfoModal";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDiscountContext } from "../DiscountContext";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { PaymentMethodView } from "../PaymentMethodView";
import ErrorIcon from "@mui/icons-material/Error";
import { useQueryClient } from "react-query";
import { GreenBorderLabel } from "../GreenBorderLabel";

import { toast } from "react-toastify";
import { SummaryPanel } from "../SummaryPanel";
declare var location;
export function RenewPlanScreen() {
  const theme = useTheme();

  const { mutate: renewPlan, isLoading, data: paymentResponse } = useRenewPlan();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { initialSetting } = useGlobalContext();
  const { clientPlan, planSetting } = useDiscountContext();
  const { confirm } = useNotificationContext();
  useDocumentTitle("Renew Subscription");
  const [model, setModel] = useState<RenuewPlanModel>({ paymentMethodId: WalletType.CashWallet, units: clientPlan?.paidUnits });

  const submitForm = () => {
    if (model.paymentMethodId === WalletType.CashWallet) {
      confirm({
        onConfirm: sendPayment,
      });
    } else {
      sendPayment();
    }
  };

  const sendPayment = () => {
    const data = { ...model, returnPath: `payment-callback/${getPaymentPath(model.paymentMethodId)}` };
    renewPlan(data as RenuewPlanModel, {
      onSuccess: (data) => {
        queryClient.invalidateQueries("discount-client-plan");
        if (data.paymentLink) {
          location.href = data.paymentLink;
        }
        if (data.paymentMethodId === WalletType.CashWallet) {
          toast.success("Subscription renewal was successful.");
          navigate("/discounts/index");
        }
      },
    });
  };
  const modelChanged = (data: any) => {
    setModel((prev) => ({ ...prev, ...data }));
  };

  return (
    <>
      <Box
        sx={{
          borderRadius: "2rem",
          background: "#17171C",
          overflow: "hidden",
          display: "grid",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "3fr 2fr",
            margin: "3rem 90px",
          },
        }}
      >
        <Box
          sx={{
            background: "#1D1C23",
            padding: "2rem 35px",
            [theme.breakpoints.up("md")]: {
              paddingX: "73px",
            },

            "h3.name": {
              fontSize: "40px",
              fontWeight: 500,
              margin: "0 0 1rem",
            },
            "div.price": {
              fontWeight: "500",
              margin: "8px 0 23px",
            },
            "p.desc": {
              opacity: 0.7,
              fontSize: "14px",
              margin: "1rem 0 2rem",
            },
            "div.warning": {
              opacity: 0.7,
              marginTop: "12px",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            },
          }}
        >
          <h3 className="name">Discount Plan</h3>
          <GreenBorderLabel label={`${formatMoney(planSetting?.price, initialSetting?.currency)} per employee`} />
          <p className="desc">You can add unlimited number of employees on this plan to enjoy big discounts when shopping at our merchants stores.</p>

          <SummaryPanel units={clientPlan?.paidUnits} price={planSetting?.price} />
          <div className="warning">
            <ErrorIcon sx={{ fontSize: "16px" }} color="warning" />
            <div>You will only be able to add the number of employee you’re paying for</div>
          </div>
        </Box>

        <Box sx={{ padding: "1.5rem 35px" }}>
          <>
            <h3>Payment Method</h3>
            <PaymentMethodView methodId={model.paymentMethodId} methodChanged={(paymentMethodId) => modelChanged({ paymentMethodId })} />
            <FormButton label="Make Payment" fullWidth sx={{ marginTop: "46px" }} onClick={submitForm} loading={isLoading} />
          </>
        </Box>
        {model.paymentMethodId === WalletType.BankTransfer && paymentResponse && (
          <AddBankTransferInfoModal
            invoiceNumber={paymentResponse?.invoiceNumber}
            onClose={() => {
              navigate("/discounts/index");
            }}
          />
        )}
      </Box>
    </>
  );
}
