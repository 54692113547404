import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { DialogTitle, FormButton, YupShape } from "suregifts-component-lib";
import { BankTransferModel, useFundWithTransfer } from "../../services/transaction";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { BankTransferInfoForm } from "./BankTransferInfoForm";
import useNavigator from "../../hooks/useNavigator";

const schema = yup
  .object<YupShape<BankTransferModel>>({
    amount: yup.number().required(),
    accountUsed: yup.string().required(),
    depositorName: yup.string().required(),
    date: yup.date().required(),
    referenceNumber: yup.string().required(),
  })
  .required();
function BankDepositModal() {
  const { mutate: fundAccount, isLoading } = useFundWithTransfer();

  const navigator = useNavigator();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<BankTransferModel>({
    defaultValues: {
      accountUsed: "",
      date: moment().toISOString(),
    },
    resolver: yupResolver(schema),
  });
  const handleClose = () => {
    navigator("..");
  };
  const queryClient = useQueryClient();

  const submitForm = (model) => {
    fundAccount(model, {
      onSuccess: (data) => {
        toast.success("Transfer details uploaded successfully.");
        queryClient.invalidateQueries("wallet-info");
        queryClient.invalidateQueries("transactions");
        handleClose();
      },
    });
  };
  return (
    <Dialog open maxWidth="sm" scroll="body">
      <DialogTitle title="Bank Deposit" handleClose={handleClose} />
      <DialogContent>
        <BankTransferInfoForm errors={errors} register={register} control={control} />
      </DialogContent>
      <DialogActions>
        <FormButton label="Upload" loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}
export default BankDepositModal;
