import { useMutation, useQuery } from "react-query";
import { PageFilter, Count, ServerError, OrderStatusType, DeliveryStatusType, useAxios, GiftCardData, GiftCardInfoData, NameAndId, WalletType, TransactionStatusType } from "suregifts-component-lib";

import { BusinessOrderType } from "../shared/enums";
export const useGetRecentOrders = () => {
  const axios = useAxios();
  return useQuery<Count<OrderData>, ServerError, OrderData[]>({
    queryKey: ["recent-orders"],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/orders/api", {
        params: { page: 1, size: 10 },
      }),
    select: (data) => data.items,
  });
};
export const useGetOrders = (filter: PageFilter) => {
  const axios = useAxios();
  return useQuery<Count<OrderData>, ServerError>({
    queryKey: ["orders", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/orders/api", {
        params: filter,
      }),
  });
};
export const useExportOrders = (filter: PageFilter) => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, any>(() => {
    return axios.get(`/orders/api/export`, {
      params: { search: filter.search },
    });
  });
};
export const useExportOrderRecipients = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, any>((orderId) => {
    return axios.post(`/orders/api/${orderId}/export`, {});
  });
};
export const useCancelOrder = (orderId) => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, string>((reason) => {
    return axios.post(`/orders/api/${orderId}/cancel`, {
      reason: reason,
    });
  });
};
export const useSendOrderExportToMail = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, any>((orderId) => {
    return axios.post(`/orders/api/${orderId}/send-to-email`, {});
  });
};
export const useResendOrder = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, any>((orderId) => {
    return axios.post(`/orders/api/${orderId}/resend-order`, {});
  });
};
export const useResendVoucher = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, number>((orderItemId) => {
    return axios.post(`/orders/api/resend-vouchers`, {
      items: [orderItemId],
    });
  });
};
export const useGetOrderById = (orderId) => {
  const axios = useAxios();
  return useQuery<OrderInfoData, ServerError>({
    queryKey: ["order-by-id", { orderId }],
    enabled: !!orderId,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    queryFn: () => axios.get(`/orders/api/${orderId}`, {}),
  });
};
export interface GiftCardFilter extends PageFilter {
  orderBy: string;
  countryCode: string;
  categoryId: string;
}
export const useGetGiftCards = (filter: GiftCardFilter, onSuccess?: (data: Count<GiftCardData>) => void) => {
  const axios = useAxios();
  return useQuery<Count<GiftCardData>, ServerError>({
    queryKey: ["gift-cards", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    onSuccess,
    queryFn: () =>
      axios.get("/orders/api/gift-cards", {
        params: filter,
      }),
  });
};
export const useGetCategories = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["giftcard-categories"],
    keepPreviousData: true,
    initialData: [],
    refetchOnWindowFocus: true,
    retry: false,
    queryFn: () => axios.get("/orders/api/categories", {}),
  });
};
export const useGetCountries = () => {
  const axios = useAxios();
  return useQuery<CountryDto[], ServerError>({
    queryKey: ["giftcard-countries"],
    keepPreviousData: true,
    initialData: [],
    refetchOnWindowFocus: true,
    retry: false,
    queryFn: () => axios.get("/orders/api/countries", {}),
  });
};
export const useGetGiftInfo = (giftId, onSuccess?: (data: GiftCardInfoData) => void) => {
  const axios = useAxios();
  return useQuery<GiftCardInfoData, ServerError>({
    queryKey: ["gift-card-byId", { giftId }],
    keepPreviousData: true,
    queryFn: () => axios.get(`/orders/api/gift-cards/${giftId}`, {}),
    enabled: !!giftId,
    onSuccess: onSuccess,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: false,
  });
};
export const useComputeOrderAmount = () => {
  var axios = useAxios();
  return useMutation<ComputeAmountResponse, ServerError, ComputeAmountModel>((model) => {
    return axios.post("/orders/api/compute-amount", model);
  });
};
export const usePlaceOrder = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, PlaceOrderModel>((model) => {
    return axios.post("/orders/api", {
      voucherValue: model.voucherValue,
      recipients: model.recipients,
      groupId: model.groupId,
      eventId: model.eventId,
      couponCode: model.couponCode,
      templateId: model.templateId,
      giftId: model.giftId,
      narration: model.narration,
      deliveryTime: model.deliveryTime,
      deliveryDate: model.deliveryDate,
    });
  });
};
export const usePlaceDownloadOrder = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, PlaceDownloadOrderModel>((model) => {
    return axios.post("/orders/api/download", model);
  });
};
export const useChangeRecipientEmail = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, ChangeEmailModel>((model) => {
    return axios.post("/orders/api/change-email", model);
  });
};
export interface CountryDto {
  name: string;
  code: string;
}

export interface OrderData {
  deliveryStatus: string;

  id: number;
  orderNumber: string;
  subTotal: string;
  amountDue: string;
  statusId: OrderStatusType;
  status: string;
  quantity: number;
  dateCreated: string;
  paymentStatusId: TransactionStatusType;
  paymentStatus: string;
  paymentMethodId: WalletType;
  paymentMethod: string;
  orderTypeId: BusinessOrderType;
  orderType: string;
  narration: string;
  merchantName: string;
  merchantCode: string;
  scheduledTime: string;
}
export interface PlaceOrderModel extends ComputeAmountModel {
  deliveryTime?: string;
  deliveryDate?: string;
  isClientTemplate: boolean;
  recipients: any[];
  groupId: any;
  eventId: number;
  templateId: number;
  schedule: boolean;
  imageUrl: string;
  giftName: string;
}
export interface PlaceDownloadOrderModel extends ComputeAmountModel {
  pin: string;
  giftName: string;
}
export interface ComputeAmountModel {
  giftId: string;
  voucherValue: any;
  couponCode: string;
  tabValue: number;
  numberOfvouchers: string;
  narration: string;
}
export interface ComputeAmountResponse {
  subTotal: number;
  shipping: number;
  couponDiscount: number;
  amountDue: number;
  message: string;
  code: string;
  codeId: number;
}

export interface OrderInfoData extends OrderData {
  recipients: RecipientData[];
  deliveryStatus: string;
  canCancel: boolean;
}

export interface RecipientData {
  serialNumber: number;
  id: number;
  value: string;
  imageUrl: string;
  statusId: DeliveryStatusType;
  status: string;
  title: string;
  email: string;
  firstName: string;
  lastName: string;
}
export interface ResendVouchersModel {
  items: number[];
}
export interface ChangeEmailModel {
  itemId: number;
  email: string;
  firstName: string;
  lastName: string;
}
