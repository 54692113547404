import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Typography } from "@mui/material";
export type PageBackViewProps = {
  to?: string;
  title: string;
  onClick?: () => void;
};
export const PageBackView = ({ title, to, onClick = () => {} }: PageBackViewProps) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "flex-start",
        cursor: "pointer",
        alignItems: "center",

        marginBottom: "20px",
        marginLeft: "-8px",
      }}
      onClick={() => {
        if (to) {
          navigate(to);
        } else {
          onClick();
        }
      }}
    >
      <ChevronLeftIcon fontSize="medium" sx={{}} />
      <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>{title}</Typography>
    </div>
  );
};
