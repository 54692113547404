export const GreenBorderLabel = ({ label }) => {
  return (
    <div
      style={{
        border: "1px solid #92FBB3",
        color: "#92FBB3",
        padding: "6px 10px",
        display: "inline-block",
        textTransform: "uppercase",
        borderRadius: "50vh",
      }}
    >
      {label}
    </div>
  );
};
