import { Outlet, createBrowserRouter } from "react-router-dom";
import { ScrollToTop, UtilityContentProvider } from "suregifts-component-lib";
import AuthScreen from "../pages/auth/AuthScreen";
import { RequireAuth } from "./RequireAuth";
import LoginScreen from "../pages/auth/LoginScreen";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import MyAccountsScreen from "../pages/my-accounts/MyAccountsScreen";
import DashboardScreen from "../pages/dashboard/DashboardScreen";
import SettingScreen from "../pages/settings/SettingScreen";
import { RecipientContextProvider } from "../pages/recipients/RecipientContext";

import PaymentCallbackScreen from "../pages/payment/PaymentCallbackScreen";
import BillScreen from "../pages/utility/bills/list/BillListScreen";
import AirtimeListScreen from "../pages/utility/airtime/list/AirtimeListScreen";
import OrderListScreen from "../pages/orders/vouchers/list/OrderListScreen";
import OrderInfoScreen from "../pages/orders/vouchers/details/OrderInfoScreen";
import RecipientScreen from "../pages/recipients/recipient/RecipientScreen";
import GroupScreen from "../pages/recipients/group/GroupScreen";
import SupportScreen from "../pages/support/SupportScreen";
import DeveloperScreen from "../pages/developer/DeveloperScreen";
import EmailTemplateScreen from "../pages/email-templates/EmailTemplateScreen";
import LoginView from "../pages/auth/LoginView";
import SignUpView from "../pages/auth/SignUpView";
import { VoucherRequestContextProvider } from "../pages/order-request/VoucherRequestContext";

import EventVouchersScreen from "../pages/calendar/order/EventVouchersScreen";
import { VoucherDownloadContextProvider } from "../pages/order-request-download/VoucherDownloadContext";
import HomeScreen from "../pages/landing/home/HomeScreen";
import { GlobalContextProvider } from "../contexts/GlobalContext";
import AddAccountDialog from "../pages/my-accounts/AddAccountDialog";
import { RecipientUploadDialog } from "../pages/recipients/recipient/RecipientUploadDialog";
import { AddRecipientModal } from "../pages/recipients/recipient/AddRecipientModal";
import { EditRecipientModal } from "../pages/recipients/recipient/EditRecipientModal";
import AddGroupModal from "../pages/recipients/group/AddGroupModal";
import AddGroupRecipientModal from "../pages/group-info/AddGroupRecipientModal";
import { EditGroupRecipientModal } from "../pages/group-info/EditGroupRecipientModal";
import EditGroupModal from "../pages/group-info/EditGroupModal";

import FundingModal from "../pages/wallet/FundingModal";
import TransactionViewDialog from "../pages/wallet/TransactionViewDialog";
import BankDepositModal from "../pages/wallet/BankDepositModal";
import WalletScreen from "../pages/wallet/WalletScreen";
import ManagerScreen from "../pages/manager/ManagerScreen";
import AddManagerModal from "../pages/manager/AddManagerModal";
import EditManagerModal from "../pages/manager/EditManagerModal";
import GroupInfoScreen from "../pages/group-info/GroupInfoScreen";
import EventInfoScreen from "../pages/calendar/details/EventInfoScreen";
import AddEventRecipientModal from "../pages/calendar/details/AddEventRecipientModal";
import EditEventModal from "../pages/calendar/details/EditEventModal";
import EventListScreen from "../pages/calendar/landing/EventListScreen";
import AddEventModal from "../pages/calendar/landing/AddEventModal";
import ChangePasswordModal from "../pages/settings/ChangePasswordModal";
import UtilityInfoScreen from "../pages/orders/utilities/details/UtilityInfoScreen";
import UtlitiesScreen from "../pages/orders/utilities/list/UtlitiesScreen";
import NotFoundPage from "../pages/root/NotFoundPage";
import ServerErrorPage from "../pages/root/ServerErrorPage";
import { BusinessContextProvider } from "../contexts/BusinessContext";

import AirtimePaymentScreen from "../pages/utility/airtime/payment/AirtimePaymentScreen";
import BillPaymentScreen from "../pages/utility/bills/payment/BillPaymentScreen";
import LandingScreen from "../pages/landing/LandingScreen";
import DiscountLandingScreen from "../pages/landing/discounts/DiscountLandingScreen";
import DiscountIndexScreen from "../pages/discounts/index/DiscountIndexScreen";

import DiscountSubscibeScreen from "../pages/discounts/subscribe/DiscountSubscibeScreen";
import ChoosePlanScreen from "../pages/discounts/choose-plan/ChoosePlanScreen";
import EmployeeUploadScreen from "../pages/discounts/upload/EmployeeUploadScreen";
import { EmployeeUploadContextProvider } from "../pages/discounts/upload/EmployeeUploadContext";
import { RenewPlanScreen } from "../pages/discounts/renew-plan/RenewPlanScreen";
import { DiscountAnalyticsScreen } from "../pages/discounts/index/statistics/DiscountAnalyticsScreen";
import { DiscountEmployeeListScreen } from "../pages/discounts/index/employee-views/EmployeeListSection";

export const router = createBrowserRouter([
  {
    errorElement: (
      <GlobalContextProvider>
        <NotFoundPage />
      </GlobalContextProvider>
    ),
    element: (
      <ScrollToTop>
        <GlobalContextProvider>
          <Outlet />
        </GlobalContextProvider>
      </ScrollToTop>
    ),
    children: [
      {
        path: "server-error",
        element: <ServerErrorPage />,
      },
      {
        element: <LandingScreen />,
        children: [
          {
            index: true,
            element: <HomeScreen />,
          },
          {
            path: "discounts-landing",
            element: <DiscountLandingScreen />,
          },
        ],
      },
      {
        path: "my-accounts",
        element: (
          <RequireAuth>
            <MyAccountsScreen />
          </RequireAuth>
        ),
        children: [{ path: "add", element: <AddAccountDialog /> }],
      },
      {
        element: <BusinessContextProvider />,
        children: [
          { path: "dashboard", element: <DashboardScreen /> },
          {
            path: "discounts",
            element: <Outlet />,
            children: [
              { path: "subscribe", element: <DiscountSubscibeScreen /> },
              { path: "choose-plan", element: <ChoosePlanScreen /> },
              { path: "renew-plan", element: <RenewPlanScreen /> },
              { path: "add-units", element: <ChoosePlanScreen /> },
              {
                path: "upload",
                element: <EmployeeUploadContextProvider />,
              },
              {
                element: <DiscountIndexScreen />,
                children: [
                  { index: true, element: <DiscountAnalyticsScreen /> },
                  { path: "index", element: <DiscountAnalyticsScreen /> },
                  { path: "employees", element: <DiscountEmployeeListScreen /> },
                ],
              },
              {
                index: true,
                element: <DiscountIndexScreen />,
              },
            ],
          },

          { path: "templates", element: <EmailTemplateScreen /> },
          { path: "events", element: <EventListScreen />, children: [{ path: "add", element: <AddEventModal /> }] },
          {
            path: "events/:eventId",
            element: <EventInfoScreen />,
            children: [
              { path: "add-recipients", element: <AddEventRecipientModal /> },
              { path: "edit", element: <EditEventModal /> },
            ],
          },
          { path: "events/:eventId/vouchers", element: <EventVouchersScreen /> },
          {
            path: "/orders/vouchers",
            element: <OrderListScreen />,
          },
          { path: "/orders/vouchers/:orderId", element: <OrderInfoScreen /> },
          { path: "/orders/bills", element: <UtlitiesScreen /> },
          { path: "/orders/bills/:orderId", element: <UtilityInfoScreen /> },
          { path: "order-request", element: <VoucherRequestContextProvider /> },
          {
            path: "order-request-download",
            element: <VoucherDownloadContextProvider />,
          },
          {
            path: "airtime",
            element: <AirtimeListScreen />,
          },
          {
            path: "airtime/:airtimeId",
            element: (
              <UtilityContentProvider>
                <AirtimePaymentScreen />
              </UtilityContentProvider>
            ),
          },
          {
            path: "bills",
            element: <BillScreen />,
          },
          {
            path: "bills/:billerId",
            element: (
              <UtilityContentProvider>
                <BillPaymentScreen />
              </UtilityContentProvider>
            ),
          },
          {
            element: <RecipientContextProvider />,
            children: [
              {
                path: "/recipients/vouchers",
                element: <RecipientScreen />,
                children: [
                  {
                    path: "upload",
                    element: <RecipientUploadDialog />,
                  },
                  {
                    path: "add",
                    element: <AddRecipientModal />,
                  },
                  {
                    path: ":recipientId/edit",
                    element: <EditRecipientModal />,
                  },
                ],
              },
              {
                path: "/recipients/groups",
                element: <GroupScreen />,
                children: [{ path: "add", element: <AddGroupModal /> }],
              },
              {
                path: "/recipients/groups/:groupId",
                element: <GroupInfoScreen />,
                children: [
                  { path: "add-recipient", element: <AddGroupRecipientModal /> },
                  {
                    path: "edit-recipient/:recipientId",
                    element: <EditGroupRecipientModal />,
                  },
                  { path: "edit", element: <EditGroupModal /> },
                ],
              },
            ],
          },
          {
            path: "/wallet",
            element: <WalletScreen />,
            children: [
              { path: "fund", element: <FundingModal /> },
              { path: "bank-deposit", element: <BankDepositModal /> },
              {
                path: "transaction-info/:transactionId",
                element: <TransactionViewDialog />,
              },
            ],
          },
          {
            path: "settings",
            element: <SettingScreen />,
            children: [{ path: "change-password", element: <ChangePasswordModal /> }],
          },
          {
            path: "managers",
            element: <ManagerScreen />,
            children: [
              { path: "add", element: <AddManagerModal /> },
              {
                path: ":managerId/edit",
                element: <EditManagerModal />,
              },
            ],
          },

          { path: "support", element: <SupportScreen /> },
          { path: "developer", element: <DeveloperScreen /> },
        ],
      },
      {
        path: "payment-callback/:type",
        element: <PaymentCallbackScreen />,
      },
      {
        element: <AuthScreen />,
        children: [
          {
            element: <LoginScreen />,
            children: [
              {
                path: "login",
                element: <LoginView />,
              },
              {
                path: "register",
                element: <SignUpView />,
              },
            ],
          },
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
          { path: "reset-password", element: <ResetPassword /> },
        ],
      },
    ],
  },
]);
