import { Box, Stack, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";
import StaticHeader from "./StaticHeader";

export const GetStartedSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        marginTop: "3.125rem",
        display: "grid",
        gap: "2rem",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "1fr 1fr",
        },
      }}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        <div>
          <StaticHeader>Branded and Personalised gift cards</StaticHeader>
          <Box
            component={"ul"}
            sx={{
              margin: 0,
              marginTop: "30px",
              color: "text.secondary",
              "& li": {
                padding: "5px",
              },
            }}
          >
            <li>For Employee Engagement and Rewards</li>
            <li>For Customer Rewards and Loyalty Programs</li>
            <li>For Sales Incentive</li>
            <li>For all Corporate gifting needs</li>
          </Box>
        </div>

        <Stack direction={"row"} gap="0.625rem" sx={{ marginTop: "2.5rem" }}>
          <FormButton
            onClick={() => {
              navigate("/register");
            }}
            label="Get started"
          />
        </Stack>
      </div>
      <Box component={"img"} src="/assets/images/business/top-bg.svg" sx={{ maxWidth: "100%" }} />
    </Box>
  );
};
