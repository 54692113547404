import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormButton, CountryDropdown, SiteSwitchButton } from "suregifts-component-lib";

import { useGlobalContext } from "../../../contexts/GlobalContext";

export const UserStatusSection = () => {
  const navigate = useNavigate();

  const { initialSetting } = useGlobalContext();

  return (
    <Stack direction={"row"} spacing={"0.625rem"} sx={{ alignItems: "center" }}>
      {initialSetting?.businessUrl && <SiteSwitchButton label="Suregifts Retail" url={initialSetting?.websiteUrl} />}
      <FormButton label="Sign up" onClick={() => navigate("/register")} />
      <FormButton label="Sign in" variant="outlined" onClick={() => navigate("/login")} />
      <CountryDropdown
        value={initialSetting?.countryCode}
        options={initialSetting?.countries.filter((x) => !!x.businessUrl)}
        onChange={(index, value) => {
          window.location.href = initialSetting!.countries[index]!.businessUrl;
        }}
        hideTitle
      />
    </Stack>
  );
};
