import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FormInput, FormButton, LoginModel, YupShape, useAuthContext, useDocumentTitle } from "suregifts-component-lib";
import * as yup from "yup";
import { useLogin } from "../../services/auth";
import { useCookies } from "react-cookie";
import { isRealValue } from "../../helpter";
export const schema = yup
  .object<YupShape<LoginModel>>({
    email: yup.string().required().email(),
    password: yup.string().required(),
  })
  .required();
function LoginView() {
  const [cookies, setCookie] = useCookies(["BUSINESS_ID"]);
  const theme = useTheme();
  useDocumentTitle("Login");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginModel>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });
  const { saveToken, user, isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const { mutate: login, isLoading } = useLogin();
  const submitForm = (data) => {
    login(data, {
      onSuccess: (result) => {
        setCookie("BUSINESS_ID", result.businessId, { path: "/" });
        saveToken(result.token, result.refreshToken, result);
      },
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (isRealValue(cookies.BUSINESS_ID)) {
        navigate("/dashboard");
      } else {
        navigate("/my-accounts");
      }
    }
  }, [isAuthenticated]);
  return (
    <div>
      <Stack spacing={2} marginTop="1.875rem">
        <FormInput label="Email" {...register("email")} helperText={errors.email?.message} />
        <FormInput label="Password" type="password" {...register("password")} helperText={errors.password?.message} />
        <div style={{ display: "flex", justifyContent: "end", color: "green" }}>
          <Link style={{ color: theme.palette.success.main, textDecoration: "none" }} to={"/forgot-password"}>
            Forgot Password?
          </Link>
        </div>
        <FormButton loading={isLoading} label="Sign in" onClick={handleSubmit(submitForm)} fullWidth />
      </Stack>
    </div>
  );
}

export default LoginView;
