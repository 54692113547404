import { Box, useTheme } from "@mui/material";
import { OtherSummaryBox } from "./OtherSummaryBox";
import { PlanSummaryBox } from "./PlanSummaryBox";
import { useDiscountContext } from "../DiscountContext";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { formatMoney, formatNumber } from "suregifts-component-lib";

export const SummarySection = () => {
  const theme = useTheme();
  const { clientPlan } = useDiscountContext();
  const { initialSetting } = useGlobalContext();
  return (
    <Box
      sx={{
        display: "grid",
        gap: "20px",

        [theme.breakpoints.up("lg")]: { gridTemplateColumns: "1fr 2fr" },
      }}
    >
      <PlanSummaryBox />
      <Box
        sx={{
          display: "grid",
          gap: "20px",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        }}
      >
        <OtherSummaryBox
          name="employees"
          value={
            <>
              {formatNumber(clientPlan?.usedUnits)} <span style={{ color: "#5A5A65" }}> of {formatNumber(clientPlan?.paidUnits)}</span>
            </>
          }
        />
        <OtherSummaryBox name="worth of discount accessed" value={formatMoney(clientPlan?.totalDiscounts, initialSetting?.currency)} />
        <OtherSummaryBox name={"staff used discount"} value={formatNumber(clientPlan?.discountedUnits)} />
        <OtherSummaryBox name="Vouchers purchased on discount" value={formatNumber(clientPlan?.discountedVouchers)} />
      </Box>
    </Box>
  );
};
