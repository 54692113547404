import { Box } from "@mui/material";
import { MarginTop40Box, SearchButton, SearchInput, TabSearchContainer } from "suregifts-component-lib";
import { useExportOrders, useGetOrders } from "../../../../services/order";

function FilterSection({ handleFilter, filter }) {
  const { mutate: exportOrders } = useExportOrders(filter);
  return (
    <MarginTop40Box>
      <TabSearchContainer
        tabs={[
          { label: "Orders", value: "/orders/vouchers" },
          { label: "Bill Payments", value: "/orders/bills" },
        ]}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchInput
            value={filter.search}
            sx={{ width: "25rem" }}
            onChange={(e) => {
              handleFilter({ search: e, page: 1 });
            }}
          />
          <SearchButton sx={{ width: "auto", py: "0.125rem" }} label="Export" onClick={() => exportOrders(undefined)} />
        </Box>
      </TabSearchContainer>
    </MarginTop40Box>
  );
}

export default FilterSection;
