import { useEffect } from "react";
import { Calendar as MainCalendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { styled, useTheme } from "@mui/material/styles";
import { FormButton, LoadingBox, PageHeaderTitle, useDocumentTitle } from "suregifts-component-lib";
import { Box, IconButton, Stack, SxProps, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useGetEvents } from "../../../services/event";

import { Outlet, useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);
function EventListScreen() {
  const { data: events, isFetching, refetch } = useGetEvents();
  const navigate = useNavigate();

  useDocumentTitle("Calendar");
  return (
    <LoadingBox loading={isFetching}>
      <Calendar
        components={{
          toolbar: CustomToolBar,
        }}
        localizer={localizer}
        events={events?.map((item) => ({
          ...item,
          start: moment(item.date).toISOString(),
          end: moment(item.date).toISOString(),
        }))}
        onSelectEvent={(e: any) => {
          navigate(`${e.id}`);
        }}
        sx={calendarStyles}
      />
      <Outlet />
    </LoadingBox>
  );
}
const CustomToolBar = ({ label, localizer: { messages }, onNavigate, onView, view, views }) => {
  const theme = useTheme();
  const nav = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "1.875rem",
        justifyContent: "space-between",
        [theme.breakpoints.up("sm")]: {
          flexDirection: "row",
        },
        marginBottom: "1.875rem",
      }}
    >
      <PageHeaderTitle title="Calendar" />
      <Stack spacing={2} direction="row" alignItems={"center"}>
        <IconButton onClick={() => onNavigate(navigate.PREVIOUS)}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography onClick={() => onNavigate(navigate.TODAY)} sx={{ fontSize: "1.5rem", fontWeight: 700, cursor: "pointer" }}>
          {label}
        </Typography>
        <IconButton onClick={() => onNavigate(navigate.NEXT)}>
          <ChevronRightIcon />
        </IconButton>
      </Stack>
      <FormButton color="success" label="Add new Event" sx={{ padding: "1rem 1.6875rem", width: "auto" }} onClick={() => nav("add")} />
    </Box>
  );
};
const calendarStyles: SxProps = {
  color: "text.primary",
  "& .rbc-event-content": {
    color: "success.main",
    fontSize: "0.875rem",
  },
  "& .rbc-event": {
    backgroundColor: "rgba(146, 251, 179, 0.1)",
    padding: "0.5rem",
    border: "0.0625rem solid rgba(146, 251, 179, 1)",
  },
  "& .rbc-month-row": {
    borderLeft: "0.125rem solid",
    borderColor: "primary.dark",
  },
  "& .rbc-month-view": {
    borderLeft: "0.125rem solid",
    borderColor: "primary.dark",
  },
  "& .rbc-header": {
    backgroundColor: "primary.main",
    padding: "1.375rem",
    fontSize: "0.875rem",
    fontWeight: 500,
    borderLeft: "0.125rem solid",
    borderColor: "primary.dark",
  },
  "& .rbc-button-link": {
    fontWeight: 500,
    fontSize: "0.75rem",
  },
  "& .rbc-day-bg": {
    backgroundColor: "primary.main",
    borderLeft: "0.125rem solid",
    borderColor: "primary.dark",

    "&.rbc-off-range-bg": {
      backgroundColor: "primary.light",
    },
  },
};
const Calendar = styled(MainCalendar)({
  height: 1000,
});
export let navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};
export default EventListScreen;
