import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useState } from "react";
import { DialogTitle, FormButton, FormSelect } from "suregifts-component-lib";
import { Controller, useForm } from "react-hook-form";
import { AddEventRecipientModel, useAddEventRecipients } from "../../../services/event";
import { Stack } from "@mui/system";
import { useGetGroupNames } from "../../../services/receipient";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { RadioGroup } from "../../../components/molecules/RadioGroup";
import { RecipientCsvFileUploader } from "../../recipients/group/ButtonUpload";
import { useQueryClient } from "react-query";
import useNavigator from "../../../hooks/useNavigator";

function AddEventRecipientModal() {
  const navigate = useNavigator();
  const queryClient = useQueryClient();
  const [isGroup, setisGroup] = useState(false);
  const { eventId } = useParams();
  const handleClose = () => {
    navigate("..");
  };
  const { mutate: addRecipients, isLoading } = useAddEventRecipients(eventId);
  const { data: groups } = useGetGroupNames();
  const { handleSubmit, control, resetField } = useForm<AddEventRecipientModel>({
    defaultValues: {
      recipientIds: [],
    },
  });

  const handleRadioChange = (value: any) => {
    setisGroup(value === "true");
    resetField("recipientIds");
    resetField("groupId");
  };
  const submitForm = (data) => {
    addRecipients(data, {
      onSuccess: () => {
        toast.success("Events added successfully.");
        queryClient.invalidateQueries("eventById");
        handleClose();
      },
    });
  };

  return (
    <Dialog open scroll="body" maxWidth="xs">
      <DialogTitle title="Add Recipients" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2} sx={{}}>
          <RadioGroup
            label="Recipient"
            value={isGroup}
            onChange={handleRadioChange}
            options={[
              { label: "Upload CSV", value: false },
              { label: "Select Group", value: true },
            ]}
          />

          {isGroup ? (
            <Controller control={control} name="groupId" render={({ field }) => <FormSelect label="Group" options={groups} {...field} />} />
          ) : (
            <Controller control={control} name="fileUrl" render={({ field: { onChange } }) => <RecipientCsvFileUploader onChange={onChange} />} />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton fullWidth label="Add" loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default AddEventRecipientModal;
