import { createContext, useContext, useMemo, useState } from "react";

import EmployeeUploadScreen from "./EmployeeUploadScreen";
import { useDiscountContext } from "../DiscountContext";

type EmployeeUploadContextValueProps = {
  totalUpload: number;
  totalUploadChanged: (total: number) => void;
  newTotal: number;
  payDiff: boolean;
};
const EmployeeUploadContext = createContext({} as EmployeeUploadContextValueProps);

export const useEmployeeUploadContext = () => {
  return useContext(EmployeeUploadContext);
};

export const EmployeeUploadContextProvider = ({}) => {
  const [totalUpload, setTotalUpload] = useState(0);
  const { clientPlan } = useDiscountContext();
  var newTotal = useMemo(() => {
    if (!clientPlan) return 0;
    return clientPlan!.usedUnits + totalUpload!;
  }, [totalUpload, clientPlan]);

  const payDiff = useMemo(() => {
    if (!clientPlan) return false;
    return newTotal > clientPlan.paidUnits;
  }, [newTotal, clientPlan]);

  return (
    <>
      <EmployeeUploadContext.Provider
        value={{
          totalUpload,
          totalUploadChanged: (total) => setTotalUpload(total),
          payDiff,
          newTotal,
        }}
      >
        <EmployeeUploadScreen />
      </EmployeeUploadContext.Provider>
    </>
  );
};
