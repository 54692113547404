import { useState } from "react";
import { useDeleteEvent, useDeleteEventRecipients, useGetEventById } from "../../../services/event";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  DeleteIcon,
  EditPencilIcon,
  MainTable,
  MarginTop40Box,
  PageHeaderTitle,
  SearchButton,
  SearchContainer,
  SearchInput,
  useDocumentTitle,
  useNotificationContext,
  usePagination,
  Pagination,
  TableBody,
  useFilter,
  PageFilter,
  LoadingBox,
} from "suregifts-component-lib";
import { Box, Divider, IconButton, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";

import { PageBackView } from "../../../components/molecules/PageBackView";
import AddIcon from "@mui/icons-material/Add";
import { StorageKeys } from "../../../routes/RequireAuth";
import useNavigator from "../../../hooks/useNavigator";
import { PlaceOrderModel } from "../../../services/order";
type TitleDetailsProps = {
  title;
  desc;
  descColor?: string;
};
export const TitleDetails = ({ title, desc, descColor = "inherit" }: TitleDetailsProps) => {
  return (
    <div>
      <Typography sx={{ textTransform: "uppercase", fontWeight: 500, fontSize: "0.75rem", color: "text.secondary" }}>{title}</Typography>
      <Typography sx={{ textTransform: "uppercase", fontWeight: 700, fontSize: "1.5rem", color: descColor }}>{desc}</Typography>
    </div>
  );
};

function EventInfoScreen() {
  const navigate = useNavigate();
  const navigator = useNavigator();
  const { eventId } = useParams();
  const { confirm } = useNotificationContext();
  const theme = useTheme();
  const { data, refetch, isFetching } = useGetEventById(eventId);
  const { mutate: deleteRecipient, isLoading: isDeletingRecipients } = useDeleteEventRecipients(eventId);
  const { mutate: deleteEvent, isLoading: isDeletingEvent } = useDeleteEvent();

  useDocumentTitle("Events");

  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, search: "" });
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const { total, data: items } = usePagination(filter.page, data?.recipients);

  const handleRecipientDelete = (item) => {
    confirm({
      onConfirm: () => {
        deleteRecipient(item.id, {
          onSuccess: () => {
            if (data?.recipients.length === 1) {
              navigate("/events");
            } else {
              toast.success("Recipient deleted successfully.");
              refetch();
            }
          },
        });
      },
    });
  };

  const handleDeleteEvent = () => {
    confirm({
      onConfirm: () => {
        deleteEvent(eventId, {
          onSuccess: () => {
            toast.success("Event deleted successfully.");
            navigate("/events");
          },
        });
      },
    });
  };

  return (
    <LoadingBox loading={isFetching}>
      <PageBackView to="/events" title="Calender" />
      <SearchContainer>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <PageHeaderTitle title={data?.title} />
          <Divider orientation="vertical" flexItem sx={{ bgcolor: "primary.light" }} variant="middle" />
          <span>{total} Member(s)</span>
          <div>
            <IconButton
              onClick={() => {
                sessionStorage.setItem("EVENT_DATA", JSON.stringify(data));
                navigator("edit");
              }}
            >
              <EditPencilIcon />
            </IconButton>
            <IconButton onClick={() => navigator("add-recipients")}>
              <AddIcon />
            </IconButton>
            <IconButton onClick={handleDeleteEvent} color="error">
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchInput
            value={filter.search}
            onChange={(e) => {
              handleFilter({ search: e, page: 1 });
            }}
          />
          {data?.orderId ? (
            <SearchButton
              sx={{ py: "0.125rem" }}
              label="Order History"
              onClick={() => {
                navigate("vouchers");
              }}
            />
          ) : (
            <SearchButton
              sx={{ py: "0.125rem" }}
              label="Schedule"
              color="success"
              onClick={() => {
                if (data) {
                  const model = {
                    eventId: data.id,
                    numberOfvouchers: data.recipientCount,
                    narration: data.title,
                    deliveryTime: data.date,
                    deliveryDate: data.date,
                    schedule: true,
                  };
                  const orderObj = JSON.stringify(model);
                  sessionStorage.setItem(StorageKeys.RECIPIENT_VOUCHERS, orderObj);
                  navigate("/order-request");
                }
              }}
            />
          )}
        </div>
      </SearchContainer>
      <MarginTop40Box>
        <Box
          sx={{
            display: "grid",
            gap: "1.25rem",
            [theme.breakpoints.up("sm")]: {
              gridTemplateColumns: "repeat(3,1fr)",
            },
          }}
        >
          <TitleDetails desc={moment(data?.date).format("DD.MM.YYYY")} title="Date" />
          <TitleDetails desc={data?.eventType} title="Category" />
          <TitleDetails desc={data?.repeatType} title="Repeat" />
        </Box>
      </MarginTop40Box>
      <MarginTop40Box>
        <MainTable>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  {item.firstName} {item.lastName}
                </TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.phone}</TableCell>
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => {
                      handleRecipientDelete(item);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </MarginTop40Box>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          page={filter.page}
          total={total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
      <Outlet />
    </LoadingBox>
  );
}

export default EventInfoScreen;
