import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MarginTop40Box, PageHeaderTitle, SearchButton, SearchContainer, SearchInput } from "suregifts-component-lib";
function FilterSection({ handleFilter, filter }) {
  const navigate = useNavigate();
  return (
    <SearchContainer>
      <PageHeaderTitle title={"Managers"} />
      <Box
        sx={{
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
        }}
      >
        <SearchInput
          value={filter.search}
          onChange={(e) => {
            handleFilter({ search: e });
          }}
        />

        <SearchButton sx={{ py: "0.125rem" }} label="Add Admin" onClick={() => navigate("add")} />
      </Box>
    </SearchContainer>
  );
}

export default FilterSection;
