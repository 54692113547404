import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetOrderById } from "../../../../services/order";
import { OrderInfoSection } from "./OrderInfoSection";
import RecipientSection from "./RecipientSection";
import { useDocumentTitle } from "suregifts-component-lib";
import { PageBackView } from "../../../../components/molecules/PageBackView";

function OrderInfoScreen() {
  const { orderId } = useParams();
  const { data: order, isLoading, refetch } = useGetOrderById(orderId);
  useDocumentTitle("Orders");
  return (
    <div>
      <PageBackView to="/orders/vouchers" title="All Orders" />

      <Box
        sx={(theme) => ({
          display: "grid",
          gap: "2.375rem",
          gridTemplateColumns: "1fr",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "1fr 380px",
          },
        })}
      >
        <RecipientSection data={order} />
        <OrderInfoSection data={order} refetch={refetch} />
      </Box>
    </div>
  );
}

export default OrderInfoScreen;
