import { Box, SxProps, Typography } from "@mui/material";
import React from "react";
import Phone from "@mui/icons-material/Phone";
import Email from "@mui/icons-material/Email";
import { useGlobalContext } from "../../contexts/GlobalContext";

function SupportScreen() {
  const { initialSetting } = useGlobalContext();
  return (
    <Box sx={{ backgroundColor: "primary.main", padding: "2.5rem", borderRadius: "1.875rem" }}>
      <Typography sx={{ fontSize: "2.75rem", fontWeight: 700 }}>We are here</Typography>
      <Typography sx={{ color: "text.secondary" }}>Our team is ready to listen to the need and proffer solutions</Typography>
      <SmallViewComponent icon={<Phone color="success" />} title={"Phone"} desc={initialSetting?.contactPhone} sx={{ marginTop: "3.4375rem" }} />
      <SmallViewComponent icon={<Email color="success" />} title={"Email"} desc={initialSetting?.contactEmail} sx={{ marginTop: "3.4375rem" }} />
    </Box>
  );
}
type SmallViewComponentProps = {
  icon: React.ReactNode;
  title: string;
  desc?: string;
  sx?: SxProps;
};
const SmallViewComponent = ({ icon, title, desc, sx }: SmallViewComponentProps) => {
  return (
    <Box sx={sx}>
      <div style={{ display: "flex", alignItems: "center", gap: "0.875rem" }}>
        {icon}
        <Typography component={"span"} sx={{ fontSize: "0.875rem" }}>
          {title}
        </Typography>
      </div>
      <Typography sx={{ fontSize: "1.25rem", marginTop: "1.125rem" }}>{desc}</Typography>
    </Box>
  );
};
export default SupportScreen;
