import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { DialogTitle, FormButton, FormInput } from "suregifts-component-lib";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useCancelOrder } from "../../../../services/order";

function CancelOrderModal({ handleClose, orderId }) {
  const { mutate: cancelOrder, isLoading: isSendingReminder } = useCancelOrder(orderId);
  const [cancelReason, setCancelReason] = useState("");
  const handleCancel = (reason: string) => {
    cancelOrder(reason, {
      onSuccess: () => {
        toast.success("Order canceled successfully.");
        handleClose();
      },
    });
  };
  return (
    <Dialog open={true} maxWidth="xs">
      <DialogTitle title="Cancel Reason" handleClose={handleClose} />
      <DialogContent>
        <FormInput label="Reason" size="medium" multiline value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <FormButton onClick={() => handleCancel(cancelReason)} fullWidth color="primary" sx={{ color: "error.main" }} />
      </DialogActions>
    </Dialog>
  );
}

export default CancelOrderModal;
