import { Stack } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { FileUploader, FormButton, FormInput, FormSelect, LoadingBox, YupShape, useDocumentTitle } from "suregifts-component-lib";
import { UpdateBusinessModel, useUpdateBusiness } from "../../services/business";
import * as yup from "yup";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import CompanyLogoView from "./CompanyLogoView";
import { useGetIndustries } from "../../services/general";
import { useBusinessContext } from "../../contexts/BusinessContext";
import { useQueryClient } from "react-query";

export const schema = yup
  .object<YupShape<UpdateBusinessModel>>({
    name: yup.string().required(),
    website: yup.string().required(),
    rcNumber: yup.string().required(),
    unit: yup.string().required(),
    employees: yup.string().required(),
    industryId: yup.string().required(),
    address: yup.string().required(),
  })
  .required();
function CompanyDetailsView() {
  useDocumentTitle("Company Details");
  const { bizInfo } = useBusinessContext();
  const queryClient = useQueryClient();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdateBusinessModel>({
    resolver: yupResolver(schema),
    defaultValues: { ...bizInfo },
  });
  const { data: industries } = useGetIndustries();
  const submitForm = (data) => {
    updateBusiness(data, {
      onSuccess: () => {
        toast.success("Company details updated successfully.");
        queryClient.invalidateQueries("business-info");
      },
    });
  };

  const { mutate: updateBusiness, isLoading: submitting } = useUpdateBusiness();
  return (
    <LoadingBox sx={{ marginTop: "2.5rem" }}>
      <Controller
        name="logo"
        control={control}
        render={({ field: { onChange, value } }) => {
          return <FileUploader multiple renderControl={(uploadControl) => <CompanyLogoView photo={value} onPhotoChange={onChange} {...uploadControl} />} />;
        }}
      />

      <Stack spacing={2} sx={{ marginTop: "2.5rem" }}>
        <FormInput label="Company Name" {...register("name")} helperText={errors.name?.message} />
        <FormInput label="Unit Name" {...register("unit")} helperText={errors.unit?.message} />
        <FormInput label="CAC /RC Number" {...register("rcNumber")} helperText={errors.rcNumber?.message} />
        <FormInput label="Number of Employees" {...register("employees")} helperText={errors.employees?.message} />
        <Controller control={control} name="industryId" render={({ field }) => <FormSelect label="Industry" {...field} options={industries} />} />
        <FormInput helperText={errors.address?.message} label="Office Address" {...register("address")} />
        <FormInput label="Website" {...register("website")} helperText={errors.website?.message} />
        <FormButton disabled={!isValid} label="Save Changes" fullWidth onClick={handleSubmit(submitForm)} loading={submitting} />
      </Stack>
    </LoadingBox>
  );
}

export default CompanyDetailsView;
