import { Box, Card, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { GiftCardData, useAuthContext, FormButton, GiftCardView } from "suregifts-component-lib";
import { GiftCardFilter, useGetGiftCards } from "../../../services/order";
import { GiftInfoView } from "./GiftInfoView";
import { SelectVoucherModal } from "./SelectVoucherModal";

type GiftDetailsContainerprops = {
  children: React.ReactNode;
  disableNext: boolean;
  updateState: (params: any) => void;
};
export const GiftDetailsContainer = ({ children, disableNext, updateState }: GiftDetailsContainerprops) => {
  const { user } = useAuthContext();
  const [openModal, setOpenModal] = useState(false);
  const [filter, setFilter] = useState(() => {
    let countryCode: any = undefined;
    if (user?.countryCode === "NG" || user?.countryCode === "KE") {
      countryCode = user.countryCode;
    }
    return { page: 1, size: 9, search: "", countryCode } as GiftCardFilter;
  });

  const [gift, setGift] = useState<GiftCardData | undefined>(undefined);

  useEffect(() => {
    updateState({ giftId: gift?.giftId, giftName: gift?.name } as any);
  }, [gift]);

  const { data: giftCards, isLoading } = useGetGiftCards(filter, (data) => {
    if (data.total > 0) {
      setGift(data.items[0]);
    }
  });

  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  if (isLoading || !giftCards) return <></>;
  return (
    <>
      <Box sx={{ marginTop: "16px", maxWidth: "md", margin: "0 auto" }}>
        <Card
          sx={{
            display: "grid",
            gridTemplateColumns: "5fr 4fr",
            borderWidth: 1,
            backgroundColor: "primary.main",
            borderRadius: "30px",
          }}
        >
          <Box sx={{ padding: "32px 42px 32px 32px" }}>{children}</Box>
          <Box
            sx={{
              backgroundColor: "primary.light",
              padding: "28px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <div>{gift && <GiftCardView gift={gift} />}</div>
            <div>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  borderRadius: "16px",
                  backgroundColor: "primary.dark",
                }}
                onClick={() => setOpenModal(true)}
              >
                Change Voucher
              </Button>
            </div>
          </Box>
        </Card>
        <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: "16px" }}>
          <FormButton
            label="Next"
            disabled={disableNext}
            onClick={() => {
              updateState({ tabValue: 1 } as any);
            }}
          />
        </div>
      </Box>
      {openModal && (
        <SelectVoucherModal
          cards={giftCards}
          filter={filter}
          onClose={() => setOpenModal(false)}
          onCardSelected={(gift) => {
            setGift(gift);
            setOpenModal(false);
          }}
          handleFilter={handleFilter}
        />
      )}
    </>
  );
};
