import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useState } from "react";

import { toast } from "react-toastify";
import { FormInput, FormButton, DialogTitle } from "suregifts-component-lib";
import { useSendOrderExportToMail } from "../../../../services/order";
import { useNavigate, useParams } from "react-router-dom";
type TemplateNameModalProps = {
  handleClose: () => void;
  orderId: any;
};
function EmailModal({ handleClose, orderId }: TemplateNameModalProps) {
  const { mutate: sendToMail, isLoading } = useSendOrderExportToMail();
  const [email, setEmail] = useState("");
  const handleSubmit = () => {
    sendToMail(orderId, {
      onSuccess: () => {
        toast.success("Email sent successfully.");
        close();
      },
    });
  };
  const close = () => {
    if (isLoading) return;
    handleClose();
  };
  return (
    <Dialog maxWidth="xs" open onClose={close}>
      <DialogTitle title="Enter Receiver Email" handleClose={close} />
      <DialogContent>
        <FormInput required label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <FormButton label="Send" disabled={!email} onClick={handleSubmit} loading={isLoading} fullWidth />
      </DialogActions>
    </Dialog>
  );
}

export default EmailModal;
