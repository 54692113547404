import { Box } from "@mui/material";
import { FormButton, formatNumber } from "suregifts-component-lib";
import { useAddStaff, useSaveUpload } from "../../../services/discount";
import { useDiscountContext } from "../DiscountContext";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useEmployeeUploadContext } from "./EmployeeUploadContext";

export const DirectSubmitView = ({ fileUrl, recipients }) => {
  const { clientPlan, price } = useDiscountContext();
  const { mutate: saveUpload, isLoading: isSaving } = useSaveUpload();
  const { mutate: addStaff, isLoading: isAdding } = useAddStaff();
  const navigate = useNavigate();
  const { totalUpload, newTotal, payDiff } = useEmployeeUploadContext();
  const queryClient = useQueryClient();

  const submitSaveUpload = () => {
    const request = { fileUrl, recipients };
    if (fileUrl) {
      saveUpload(request, {
        onSuccess: () => {
          toast.success("Employee list was successfully uploaded.");
          queryClient.invalidateQueries("discount-client-plan");
          navigate("/discounts/employees");
        },
      });
    } else {
      addStaff(request, {
        onSuccess: () => {
          toast.success("Employee list was successfully uploaded.");
          queryClient.invalidateQueries("discount-client-plan");
          navigate("/discounts/employees");
        },
      });
    }
  };

  if (!clientPlan || !totalUpload) return <></>;
  if (payDiff) return <></>;

  return (
    <div
      style={{
        display: "grid",
        gap: "1rem",
      }}
    >
      <Box
        sx={{
          fontWeight: "500",
          paddingX: " 16px",
          "div.info": {
            fontSize: "12px",
            fontWeight: 400,
          },
          "div.number": {
            fontSize: "2rem",
          },

          paddingY: "20px",
          overflow: "hidden",
          display: "grid",
          gap: "0.5rem",
          background: "#17171C",
          borderRadius: "30px",
        }}
      >
        <div className="title">Number of employee uploaded:</div>
        <div className="number">
          {formatNumber(newTotal)} / <span style={{ opacity: "0.7" }}>{formatNumber(clientPlan?.paidUnits)}</span>
        </div>
        <div className="info">You’ve successfully uploaded a list of {totalUpload} employee(s).</div>
      </Box>
      {newTotal <= clientPlan?.paidUnits && <FormButton loading={isSaving || isAdding} onClick={submitSaveUpload} label="Continue" color="success" fullWidth />}
    </div>
  );
};
