import { Box, IconButton, useTheme } from "@mui/material";
import { FormButton } from "suregifts-component-lib";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import { useDiscountContext } from "../discounts/DiscountContext";

export const DiscountBanner = () => {
  const theme = useTheme();
  const discountContext = useDiscountContext();
  const [cookie, setCookie] = useCookies(["HIDE_DISCOUNT_BANNER"]);
  if (cookie.HIDE_DISCOUNT_BANNER || discountContext.clientPlan) return <></>;
  return (
    <Box
      component="section"
      sx={{
        borderRadius: "18px",
        marginTop: "3.125rem",
        background: "#1C1B21",
        backdropFilter: "blur(27px)",
        display: "grid",
        gap: "2rem",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "1fr max-content",
        },
        padding: "18px 24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          gap: "2rem",
        }}
      >
        <img src="/assets/images/discounts/logo-sm.png" alt="" />
        <div>
          <h3 style={{ margin: "0" }}>Staff Discount</h3>
          <div style={{ margin: "0" }}>
            Your staff deserves to buy more with less. Give your employees the
            <br /> opportunity to buy more with less.
          </div>
        </div>
      </Box>
      <div>
        <FormButton
          label="Get Started"
          color="success"
          onClick={() => {
            discountContext.openModal();
          }}
        />
        <IconButton onClick={() => setCookie("HIDE_DISCOUNT_BANNER", true)}>
          <CloseIcon />
        </IconButton>
      </div>
    </Box>
  );
};
