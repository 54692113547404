import React from "react";

export const TopSection = () => {
  return (
    <div>
      <h1 style={{ fontSize: "38px", fontWeight: 700, lineHeight: "47px" }}>Welcome to Suregifts Discount Plan</h1>
      <p style={{ color: "#8C8C9D", fontSize: "1rem", lineHeight: "23px", fontWeight: 400 }}>
        Make your employees happy by giving the opportunity to shop at a big
        <br />
        discount and save more on shopping.
      </p>
    </div>
  );
};
