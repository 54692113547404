import { Box } from "@mui/material";
import { SuccessTickIcon, FormButton } from "suregifts-component-lib";
import { useNavigate } from "react-router-dom";
import { useDiscountContext } from "./DiscountContext";
import { useEffect } from "react";
import { useQueryClient } from "react-query";

export const PaymentResponseView = ({ desc }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          padding: "44px 75px",
          background: "#1D1C23",
          borderRadius: "35px",
          backdropFilter: "blur(20px)",
          textAlign: "center",
          h2: {
            fontSize: "30px",
            fontWeight: 700,
            lineHeight: "130%",
            margin: 0,
          },
          width: "100%",
          maxWidth: "490px",
        }}
      >
        <SuccessTickIcon color="success" sx={{ width: "80px", height: "80px" }} />
        <div style={{ margin: "1rem 0 2rem" }}>
          <h2>Payment Successful</h2>
          <div style={{ margin: "10px 0 1rem", fontWeight: 500 }}>{desc} </div>
        </div>
        <FormButton label="Continue" fullWidth onClick={() => navigate("/discounts")} />
      </Box>
    </div>
  );
};
