import { Dialog, DialogContent, Box } from "@mui/material";
import { TemplateEditorView, TemplateEditForm } from "suregifts-component-lib";

export const EditTemplateModal = ({ onClose }) => {
  return (
    <Dialog
      onClose={onClose}
      open
      maxWidth="lg"
      PaperProps={{
        sx: {
          padding: 0,
          "& div.template-edit-form": {
            alignSelf: "initial",
          },
        },
      }}
    >
      <DialogContent>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 360px" }}>
          <TemplateEditorView />
          <TemplateEditForm />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
