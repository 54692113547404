import { Stack } from "@mui/system";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormButton, FormInput, LoadingBox, YupShape, useDocumentTitle } from "suregifts-component-lib";
import { ProfileModel, useGetCountries, useGetProfile, useUpdateProfile } from "../../services/auth";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel, Checkbox } from "@mui/material";
import { toast } from "react-toastify";

export const schema = yup
  .object<YupShape<ProfileModel>>({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
  })
  .required();

function ContactDetailsView() {
  useDocumentTitle("Contact Details");
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<ProfileModel>({
    defaultValues: {
      firstName: "",
      lastName: "",
      receiveEmailLetter: false,
      receiveSmsLetter: false,
    },
    resolver: yupResolver(schema),
  });
  const { isLoading: loadindProfile } = useGetProfile((data) => {
    setValue("firstName", data.firstName || "");
    setValue("lastName", data.lastName || "");
    setValue("receiveEmailLetter", data.receiveEmailLetter);
    setValue("receiveSmsLetter", data.receiveSmsLetter);
  });

  const { mutate: updateProfile, isLoading } = useUpdateProfile();
  const submitForm = (data) => {
    updateProfile(data, {
      onSuccess: () => {
        toast.success("Profile updated successufully.");
      },
    });
  };
  return (
    <LoadingBox style={{ marginTop: "2.5rem" }} loading={loadindProfile}>
      <Stack spacing={2}>
        <FormInput label="First Name" {...register("firstName")} helperText={errors.firstName?.message} />
        <FormInput label="Last Name" {...register("lastName")} helperText={errors.lastName?.message} />

        <FormButton disabled={!isValid} label="Save Changes" fullWidth onClick={handleSubmit(submitForm)} loading={isLoading} />
      </Stack>
    </LoadingBox>
  );
}

export default ContactDetailsView;
