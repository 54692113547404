import { Box } from "@mui/material";
import { FilterDropdown, PageHeaderTitle, SearchContainer, useGetBillCategories, useGetBillCountries } from "suregifts-component-lib";

function FilterSection({ handleFilter, filter }) {
  const { data: countries } = useGetBillCountries();
  const { data: categories } = useGetBillCategories(filter.countryCode, (data) => {});

  return (
    <SearchContainer>
      <PageHeaderTitle title={"Bills"} />
      <Box
        sx={{
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
        }}
      >
        <FilterDropdown
          value={filter.countryCode}
          options={[{ text: "All" }, ...countries!.map((item) => ({ text: item.name, value: item.id }))]}
          onChange={(index, value) => {
            handleFilter({ countryCode: value, categoryId: null, page: 1 });
          }}
          display={"Country"}
        />
        <FilterDropdown
          value={filter.categoryId}
          options={[{ text: "All" }, ...categories!.map((item) => ({ text: item.name, value: item.id }))]}
          onChange={(index, value) => {
            handleFilter({ categoryId: value, page: 1 });
          }}
          display={"Cagetory"}
        />
      </Box>
    </SearchContainer>
  );
}

export default FilterSection;
