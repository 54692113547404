import { useEffect } from "react";
import {
  MainTable,
  MarginTop40Box,
  OrderStatusLabel,
  PageHeaderTitle,
  SummaryCard,
  SummaryCardsContainer,
  TableBody,
  useDocumentTitle,
} from "suregifts-component-lib";
import { LatestRedmeptionDto, TopRecipientDto, useGetSummaryData } from "../../services/general";
import { useTheme } from "@mui/material/styles";
import { Box, TableCell, TableHead, TableRow } from "@mui/material";
import { OrderData, useGetRecentOrders } from "../../services/order";
import moment from "moment";
import VoucherQuantityStatisticsView from "./VoucherQuantityStatisticsView";
import VoucherValueStatisticsView from "./VoucherValueStatisticsView";
import { publicDecrypt } from "crypto";
import { LatestRedemptionSection } from "./LatestRedemptionSection";
import { RecentOrderSection } from "./RecentOrderSection";
import { TopRecipientSection } from "./TopRecipientSection";
import SummarySection from "./SummarySection";
import { DiscountBanner } from "./DiscountBanner";

function DashboardScreen() {
  const { data } = useGetSummaryData();
  const theme = useTheme();

  useDocumentTitle("Dashboard");
  return (
    <div>
      <DiscountBanner />
      <SummarySection data={data} />
      <MarginTop40Box
        sx={{
          display: "grid",
          gap: "3.75rem",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "7fr 4fr",
          },
        }}
      >
        <div>
          <PageHeaderTitle title={"Voucher Statistics"} />
          <Box
            sx={{
              display: "grid",
              gap: "3.75rem",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                [theme.breakpoints.up("md")]: {
                  gridTemplateColumns: "repeat(2,1fr)",
                },
                gap: "20px",
              }}
            >
              <VoucherValueStatisticsView data={data?.voucherStats} />
              <VoucherQuantityStatisticsView data={data?.voucherStats} />
            </Box>
            <RecentOrderSection />
          </Box>
        </div>
        <div>
          <Box
            sx={{
              display: "grid",
              gap: "3.75rem",
              gridTemplateColumns: "1fr 1fr",
              [theme.breakpoints.up("md")]: {
                gridTemplateColumns: "1fr",
              },
            }}
          >
            <LatestRedemptionSection data={data?.latestRedmeptions} />
            <TopRecipientSection data={data?.topRecipients} />
          </Box>
        </div>
      </MarginTop40Box>
    </div>
  );
}

export default DashboardScreen;
