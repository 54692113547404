import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useState } from "react";
import { DatePicker, DialogTitle, FormButton, FormInput, FormSelect } from "suregifts-component-lib";
import { Controller, useForm } from "react-hook-form";
import { AddEventModel, RecipientEventType, useAddEvent } from "../../../services/event";
import { Stack } from "@mui/system";
import { useGetGroupNames } from "../../../services/receipient";
import moment from "moment";
import { toast } from "react-toastify";

import { RadioGroup } from "../../../components/molecules/RadioGroup";
import { useNavigate } from "react-router-dom";
import { StorageKeys } from "../../../routes/RequireAuth";
import { RecipientCsvFileUploader } from "../../recipients/group/ButtonUpload";
import { useQueryClient } from "react-query";
const eventTypes = [
  { id: "0", name: "Others" },
  { id: "1", name: "Birthday" },
  { id: "2", name: "Wedding" },
];
const repeatTypes = [
  { id: "0", name: "None" },
  { id: "1", name: "Monthly" },
  { id: "2", name: "Yearly" },
];
function AddEventModal() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isGroup, setisGroup] = useState(false);

  const [buyVoucher, setBuyVoucher] = useState(false);
  const handleClose = () => {
    navigate("..");
  };
  const { mutate: addEvent, isLoading } = useAddEvent();
  const { data: groups } = useGetGroupNames();
  const { register, handleSubmit, control, resetField } = useForm<AddEventModel>({
    defaultValues: {
      groupId: "",
      recipientIds: [],
      repeatTypeId: "0",
      eventTypeId: RecipientEventType.Others,
      date: moment().toISOString(),
    },
  });

  const handleRadioChange = (value: any) => {
    setisGroup(value === "true");
    resetField("recipientIds");
    resetField("groupId");
  };
  const submitForm = (data) => {
    addEvent(data, {
      onSuccess: (result) => {
        toast.success("Evnet added successfully.");

        if (buyVoucher) {
          const data = JSON.stringify({ eventId: result.id, numberOfvouchers: result.recipientCount, narration: result.title });
          sessionStorage.setItem(StorageKeys.RECIPIENT_VOUCHERS, data);
          navigate("/order-request");
          return;
        }
        queryClient.invalidateQueries("events");
        navigate("..");
      },
    });
  };

  return (
    <Dialog open scroll="body" maxWidth="xs">
      <DialogTitle title="Add Event" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2} sx={{}}>
          <RadioGroup
            value={isGroup}
            onChange={handleRadioChange}
            options={[
              { label: "Upload CSV", value: false },
              { label: "Select Group", value: true },
            ]}
            label={"Recipient"}
          />

          {isGroup ? (
            <Controller control={control} name="groupId" render={({ field }) => <FormSelect label="Group" options={groups} {...field} />} />
          ) : (
            <Controller control={control} name="fileUrl" render={({ field: { onChange } }) => <RecipientCsvFileUploader onChange={onChange} />} />
          )}

          <Controller
            render={({ field: { value, onChange } }) => (
              <DatePicker
                label="Event Date"
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
              />
            )}
            control={control}
            name="date"
          />
          <Stack direction={"row"} spacing={2}>
            <Controller control={control} name="eventTypeId" render={({ field }) => <FormSelect label="Category" {...field} options={eventTypes} />} />
            <Controller control={control} name="repeatTypeId" render={({ field }) => <FormSelect label="Repeat Type" options={repeatTypes} {...field} />} />
          </Stack>
          <FormInput label="Description" {...register("title")} multiline size="medium" />
          <RadioGroup
            value={buyVoucher}
            onChange={(value) => {
              setBuyVoucher(value === "true");
            }}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            label={"Schedule Event"}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton fullWidth label="Add" loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default AddEventModal;
