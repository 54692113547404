import { useMutation, useQuery } from "react-query";
import { LoginResponse, ServerError, LoginModel, UpdateProfileModel, useAxios, getCaptchaToken } from "suregifts-component-lib";
export const useLogOut = () => {
  var axios = useAxios();

  return useMutation<BusinessTokenDto, ServerError, any>(async (_) => {
    return axios.post("/business/api/logout");
  });
};
export const useLogin = () => {
  var axios = useAxios();

  return useMutation<BusinessTokenDto, ServerError, LoginModel>(async (values) => {
    return axios.post("/business/api/Login", values, { headers: { Captcha: await getCaptchaToken() } });
  });
};
export interface BusinessTokenDto extends LoginResponse {
  businessId: string;
}
export const useForgotPassword = () => {
  var axios = useAxios();

  return useMutation<unknown, ServerError, ForgotPasswordModel>(async (values) => {
    return axios.post("/business/api/forgot-password", values, { headers: { Captcha: await getCaptchaToken() } });
  });
};
export const useResetPassword = () => {
  var axios = useAxios();

  return useMutation<BusinessTokenDto, ServerError, ResetPasswordModel>((values) => {
    return axios.post("/business/api/reset-password", values);
  });
};
export const useChangePassword = () => {
  var axios = useAxios();

  return useMutation<BusinessTokenDto, ServerError, ChangePasswordModel>((values) => {
    return axios.post("/business/api/change-password", values);
  });
};
export const useGetProfile = (onSuccess: (data: ProfileData) => void) => {
  const axios = useAxios();
  return useQuery<ProfileData, any>({
    queryKey: ["profile"],
    queryFn: () => axios.get("/business/api/profile"),
    onSuccess: onSuccess,
  });
};
export const useUpdateProfile = () => {
  var axios = useAxios();

  return useMutation<unknown, ServerError, UpdateProfileModel>((values) => {
    return axios.post("/business/api/profile", values);
  });
};
export const useGetCountries = () => {
  const axios = useAxios();
  return useQuery<CountryNameData[], ServerError>({
    queryKey: ["phone-countries"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/business/api/phone-countries", {}),
  });
};
export interface ForgotPasswordModel {
  returnPath: string;
  email: string;
}
export interface ResetPasswordModel {
  email?: string;
  password: string;
  confirmPassword: string;
  resetToken?: string;
}
export interface ChangePasswordModel {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}
export interface CountryNameData {
  name: string;
  dailCode: string;
  isoCode: string;
}

export interface ProfileModel {
  firstName: string;
  lastName: string;
  receiveSmsLetter: boolean;
  receiveEmailLetter: boolean;
}
export interface ProfileData {
  email: string;
  firstName: string;
  lastName: string;
  photoUrl: any;
  address: any;
  phoneCountryCode: string;
  phoneNumber: string;
  receiveSmsLetter: boolean;
  receiveEmailLetter: boolean;
}
