import { FormControlLabel, Radio, RadioGroup as MuiRadioGroup } from "@mui/material";
import { FormLabel } from "suregifts-component-lib";
type RadioOptionType = {
  label: string;
  value: any;
};
type RadioGroupProps = {
  value: any;
  onChange: (value: any) => void;
  options: RadioOptionType[];
  label: string;
};
export const RadioGroup = ({ value, onChange, options, label }: RadioGroupProps) => {
  return (
    <div>
      <FormLabel label={label} error={false} />
      <MuiRadioGroup row color="success.main" sx={{ color: "#AAAABE" }} value={value} onChange={(e) => onChange(e.target.value)}>
        {options.map((option, index) => (
          <FormControlLabel key={`${option.value}-${index}`} value={option.value} control={<Radio color="success" />} label={option.label} />
        ))}
      </MuiRadioGroup>
    </div>
  );
};
