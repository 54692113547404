import { Box, Container, useTheme } from "@mui/material";

import { NavigationSection } from "./NavigationSection";
import { UserStatusSection } from "./UserStatusSection";

function HeaderSection() {
  const theme = useTheme();
  return (
    <Container>
      <Box
        sx={{
          marginTop: "2.5rem",
          display: "flex",
          justifyContent: "space-between",
          "& .suregifts-logo": {
            display: "none",
          },
          "& nav": {
            display: "none",
          },
          "& .country-dropdown": {
            display: "none",
          },

          [theme.breakpoints.up("md")]: {
            "& .suregifts-logo": {
              display: "block !important",
            },
            "& .country-dropdown": {
              display: "flex !important",
            },
          },
          [theme.breakpoints.up("lg")]: {
            "& .menu-button": {
              display: "none",
            },
            "& nav": {
              display: "block !important",
            },
          },
          [theme.breakpoints.up("xl")]: {},
        }}
      >
        <div style={{ display: "flex", gap: "1.25rem", alignItems: "center" }}>
          <img src="/assets/images/logo.png" />
          <NavigationSection />
        </div>
        <UserStatusSection />
      </Box>
    </Container>
  );
}

export default HeaderSection;
