import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import SignUpView from "./SignUpView";
import LoginView from "./LoginView";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { TabPanel } from "suregifts-component-lib";

function LoginScreen() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState(pathname);

  const handleChange = (e, newValue: string) => {
    setTabValue(newValue);
    navigate(newValue);
  };
  return (
    <div style={{}}>
      <Tabs
        color="green"
        value={tabValue}
        onChange={handleChange}
        sx={{
          "& .MuiTab-root": {
            fontSize: "2.75rem",
            fontWeight: 700,
            "&.Mui-selected": {
              color: "secondary.main",
            },
          },
        }}
      >
        <Tab sx={{ paddingLeft: "0" }} label="Sign Up" value={"/register"} />
        <Tab label="Sign In" value={"/login"} />
      </Tabs>
      <Outlet />
    </div>
  );
}

export default LoginScreen;
