import { useState } from "react";
import { PageFilter, useDocumentTitle, useFilter } from "suregifts-component-lib";
import FilterSection from "./FilterSection";
import TableView from "./TableView";
import WalletInfoSection from "./WalletInfoSection";
import { Outlet } from "react-router-dom";

function WalletScreen() {
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, search: "" });
  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  useDocumentTitle("Wallet");
  return (
    <div>
      <WalletInfoSection />
      <FilterSection handleFilter={handleFilter} filter={filter} />
      <TableView filter={filter} handleFilter={handleFilter} />
      <Outlet />
    </div>
  );
}

export default WalletScreen;
