import { Box, Card, Step, StepLabel, Stepper, Tab, Tabs, useTheme } from "@mui/material";
import { PlaceDownloadOrderModel } from "../../services/order";
import OrderInfoView from "./details/OrderInfoView";
import ReviewMainView from "./review/ReviewMainView";
import { TabController, TabPanel } from "suregifts-component-lib";
import { useVoucherDownloadContext } from "./VoucherDownloadContext";
import { CustomSteper } from "./CustomSteper";
import { PageBackViewProps } from "../../components/molecules/PageBackView";

function VoucherDownloadScreen() {
  const { state, updateState: updateState } = useVoucherDownloadContext();
  return (
    <Box
      sx={{
        maxWidth: "md",
        marginX: "auto",
      }}
    >
      <CustomSteper backs={[null, { title: "Send Voucher", onClick: () => updateState({ tabValue: 0 } as any) } as PageBackViewProps]} tabValue={state.tabValue} tabs={["Voucher Details", "Review and send"]} />

      <TabPanel index={0} value={state.tabValue}>
        <OrderInfoView />
      </TabPanel>

      <TabPanel index={1} value={state.tabValue}>
        <ReviewMainView />
      </TabPanel>
    </Box>
  );
}
export default VoucherDownloadScreen;
