import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { DialogTitle, FormButton, FormInput, YupShape } from "suregifts-component-lib";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddGroupModel, useAddGroup } from "../../../services/receipient";
import { GroupUploadView } from "./GroupUploadView";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
export const schema = yup
  .object<YupShape<AddGroupModel>>({
    name: yup.string().required(),
  })
  .required();
function AddGroupModal() {
  const { mutate: addManager, isLoading } = useAddGroup();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    resetField,
  } = useForm<AddGroupModel>({
    defaultValues: {
      // name: state.group?.name,
    },
    resolver: yupResolver(schema),
  });
  const submitForm = (data) => {
    addManager(data, {
      onSuccess: () => {
        toast.success("Group added successfully.");
        queryClient.invalidateQueries("groups");
        handleClose();
      },
    });
  };
  const handleClose = () => {
    navigate("..");
  };

  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title="Add Group " handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput {...register("name")} label="Group Name" helperText={errors.name?.message} />
          <GroupUploadView resetField={resetField} control={control} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton label="Create" fullWidth loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default AddGroupModal;
