import { useEffect, useMemo, useState } from "react";

import { Outlet, useNavigate, useParams } from "react-router-dom";
import { RecipientData, useDeleteGroup, useDeleteGroupRecipients, useGetGroupById } from "../../services/receipient";

import { DeleteIcon, EditPencilIcon, MainTable, MarginTop40Box, PageFilter, PageHeaderTitle, Pagination, SearchButton, SearchContainer, TableBody, useDocumentTitle, useFilter, useNotificationContext, usePagination } from "suregifts-component-lib";
import { Divider, IconButton, Box, Checkbox, TableCell, TableHead, TableRow, styled } from "@mui/material";
import { toast } from "react-toastify";
import { StorageKeys } from "../../routes/RequireAuth";
import { PageBackView } from "../../components/molecules/PageBackView";
import useNavigator from "../../hooks/useNavigator";
import { PlaceOrderModel } from "../../services/order";

export const LocalButton = styled(SearchButton)({
  width: "auto",
  height: "auto",
  padding: "0.75rem 1.75rem",
  borderRadius: "50vh",
});

function GroupInfoScreen() {
  const { groupId } = useParams();
  const { data, isLoading, refetch } = useGetGroupById(groupId);
  const { confirm } = useNotificationContext();
  useDeleteGroup();
  useDocumentTitle("Recipients");

  const navigate = useNavigator();
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1 });
  const [localRecipients, setLocalRecipients] = useState(data?.recipients);

  useEffect(() => {
    setLocalRecipients(data?.recipients);
  }, [data]);

  const { mutate: deleteRecipients, isLoading: deleting } = useDeleteGroupRecipients(groupId);

  const handleDeleteMany = () => {
    confirm({
      onConfirm: () => {
        const items = localRecipients!.filter((item) => item.checked);
        deleteRecipients(
          { recipientIds: items.map((item) => item.id) },
          {
            onSuccess: () => {
              toast.success("Recipient deleted successfully.");
              if (localRecipients?.length === items.length) {
                navigate("..");
              } else {
                refetch();
              }
            },
          }
        );
      },
    });
  };

  const handleDeleteOne = (item: RecipientData) => {
    confirm({
      onConfirm: () => {
        deleteRecipients(
          { recipientIds: [item.id] },
          {
            onSuccess: () => {
              toast.success("Recipient deleted successfully.");
              if (localRecipients?.length === 1) {
                navigate("..");
              } else {
                refetch();
              }
            },
          }
        );
      },
    });
  };
  const { data: recipients, total } = usePagination(filter.page, localRecipients);

  const anyChecked = useMemo(() => {
    if (!localRecipients) return false;
    var result = localRecipients.filter((item) => item.checked === true).length !== 0;
    return result;
  }, [localRecipients]);

  const allChecked = useMemo(() => {
    if (!localRecipients) return false;
    var result = localRecipients.filter((item) => !item.checked).length === 0;
    return result;
  }, [localRecipients]);

  return (
    <div>
      <PageBackView to="/recipients/groups" title="Recipient Lists" />
      <SearchContainer sx={{}}>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <PageHeaderTitle title={data?.name} />
          <Divider orientation="vertical" flexItem sx={{ bgcolor: "primary.light" }} variant="middle" />
          <span>{data?.recipients.length} Member(s)</span>

          <IconButton
            onClick={() => {
              sessionStorage.setItem("GROUP_DATA", JSON.stringify({ name: data?.name }));
              navigate("edit");
            }}
          >
            <EditPencilIcon />
          </IconButton>
        </div>
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <IconButton color="error" disabled={!anyChecked} onClick={handleDeleteMany}>
            <DeleteIcon sx={{ fontSize: "1.75rem" }} />
          </IconButton>
          <LocalButton
            label="Add Recipient"
            onClick={() => {
              navigate("add-recipient");
            }}
          />
          <LocalButton
            color="success"
            label="Send Voucher"
            disabled={!anyChecked}
            onClick={() => {
              let model;
              if (allChecked) {
                model = { groupId: data?.id, numberOfvouchers: data?.members, narration: data?.name };
              } else {
                const recipients = localRecipients?.filter((x) => x.checked).map((x) => x.recipientId) || [];
                model = { recipients, narration: data?.name, numberOfvouchers: recipients?.length };
              }
              sessionStorage.setItem(StorageKeys.RECIPIENT_VOUCHERS, JSON.stringify(model));
              navigate("/order-request");
            }}
          />
        </Box>
      </SearchContainer>
      <MainTable sx={{ marginTop: "40px" }} loading={isLoading}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={allChecked}
                color="success"
                sx={{ padding: 0 }}
                onChange={(e) => {
                  if (!localRecipients) return;
                  var r = [...localRecipients];
                  r.forEach((item) => (item.checked = e.target.checked));
                  setLocalRecipients(r);
                }}
              />
            </TableCell>
            <TableCell>First name</TableCell>
            <TableCell>Last name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recipients?.map((item) => (
            <TableRow key={item.id}>
              <TableCell sx={{ width: "0.0625rem", padding: 0 }}>
                <Checkbox
                  color="success"
                  sx={{ padding: 0 }}
                  checked={item.checked || false}
                  onChange={(e) => {
                    if (!localRecipients) return;
                    const i = localRecipients.find((x) => x.id == item.id);
                    i!.checked = !i!.checked;
                    setLocalRecipients([...localRecipients]);
                  }}
                />
              </TableCell>
              <TableCell>{item.firstName}</TableCell>
              <TableCell>{item.lastName}</TableCell>
              <TableCell>{item.email}</TableCell>

              <TableCell>{item.phone}</TableCell>
              <TableCell sx={{ width: "10%", whiteSpace: "nowrap" }}>
                <IconButton
                  onClick={() => {
                    sessionStorage.setItem("RECIPIENT_DATA", JSON.stringify(item));
                    navigate(`edit-recipient/${item.id}`);
                  }}
                >
                  <EditPencilIcon />
                </IconButton>
                <IconButton color="error" onClick={() => handleDeleteOne(item)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          page={filter.page}
          total={total}
          onChange={(page) => {
            setFilter((prev) => ({ ...prev, page }));
          }}
        />
      </div>
      <Outlet />
    </div>
  );
}
export default GroupInfoScreen;
