import { Stack } from "@mui/material";
import { toast } from "react-toastify";
import { GiftPriceView, FormInput, FormButton } from "suregifts-component-lib";
import { useGetGiftInfo, PlaceDownloadOrderModel } from "../../../services/order";
import { useVoucherDownloadContext } from "../VoucherDownloadContext";

export const GiftInfoView = () => {
  const { state, updateState } = useVoucherDownloadContext();
  const { data: gift } = useGetGiftInfo(state.giftId);
  if (!state.giftId) return <></>;
  return (
    <Stack spacing={2}>
      {gift && <GiftPriceView value={state.voucherValue} onChange={(value) => updateState({ voucherValue: value } as PlaceDownloadOrderModel)} gift={gift} />}
      <FormInput
        label="No of Vouchers"
        isNumber
        value={state.numberOfvouchers}
        onChange={(e) => {
          updateState({ numberOfvouchers: e.target.value } as PlaceDownloadOrderModel);
        }}
      />
    </Stack>
  );
};
