import { Typography, Stack, useTheme } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FormInput, FormButton, YupShape, useAuthContext, useDocumentTitle } from "suregifts-component-lib";
import * as yup from "yup";
import { ResetPasswordModel, useResetPassword } from "../../services/auth";
import { toast } from "react-toastify";

function ResetPassword() {
  const [searchParams, setSearchParam] = useSearchParams();
  const { saveToken } = useAuthContext();
  useDocumentTitle("Reset Password");
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<ResetPasswordModel>({
    defaultValues: {
      resetToken: searchParams.get("token") || "",
      email: searchParams.get("email") || "",
    },
  });
  const { mutate: reset, isLoading } = useResetPassword();
  const submitForm = (data) => {
    reset(data, {
      onSuccess: () => {
        toast.success("Password reset was successful");
        navigate("/login");
      },
    });
  };
  return (
    <div>
      <Typography sx={{ fontSize: "2.75rem", fontWeight: 700, marginTop: "2.3125rem" }}>Enter New Password</Typography>
      <Stack spacing={2}>
        <FormInput label="New Password" {...register("password")} helperText={errors.password?.message} />
        <FormInput label="Confirm Password" {...register("confirmPassword")} helperText={errors?.confirmPassword?.message} />
        <div style={{ display: "flex", justifyContent: "end", color: "green" }}>
          <Link style={{ color: theme.palette.success.main, textDecoration: "none" }} to={"/login"}>
            Login
          </Link>
        </div>
        <FormButton loading={isLoading} label="Reset Password" fullWidth onClick={handleSubmit(submitForm)} />
      </Stack>
    </div>
  );
}

export default ResetPassword;
