import { Box, Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import React from "react";
import { FormButton } from "suregifts-component-lib";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../contexts/GlobalContext";
export const SectionFour = () => {
  const [expanded, setExpanded] = React.useState<string | false>("panel2");

  const navigate = useNavigate();
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box
      sx={{
        marginTop: "5rem",
        "& .MuiPaper-root": {
          background: "none",
          boxShadow: "none",
          paddingTop: "3.125rem",
          pb: "1.5rem",
          "&::before": {
            backgroundColor: "secondary.main",
          },
          "&.Mui-expanded::before": {
            opacity: 1,
          },
          "& .MuiTypography-h2": {
            fontSize: "2.75rem",
            lineHeight: "130%",
          },
          "& .MuiTypography-body1": {
            fontSize: "1.5rem",
            lineHeight: "1.875rem",
            color: "text.disabled",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "3rem",
          },
        },
      }}
    >
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary expandIcon={expanded === "panel1" ? <RemoveIcon /> : <AddIcon />}>
          <Typography variant="h2">Channel Incentives</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Our personalized gift cards are designed to help organizations;</Typography>
          <ListBox>
            <li>Motivate sales force to achieve sales goals and boost profitability. </li>
            <li>Keep and nurture unflinching interest and loyalty</li>
            <li>Revive sales team value adding spirit.</li>
          </ListBox>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary expandIcon={expanded === "panel2" ? <RemoveIcon /> : <AddIcon />}>
          <Typography variant="h2">Employee Rewards</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>With well thought message to fit every occasion, our unique gift cards help organizations;</Typography>
          <ListBox>
            <li>Increase overall employee performance. </li>
            <li>Drive optimal productivity </li>
            <li>Create atmosphere for healthy rivalry</li>
            <li>Boost employee morale and improve wellness.</li>
          </ListBox>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary expandIcon={expanded === "panel3" ? <RemoveIcon /> : <AddIcon />}>
          <Typography variant="h2">Customer Rewards</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Our highly functional gift cards are proven to;</Typography>
          <ListBox>
            <li>Enhance consumers loyalty. </li>
            <li>Appreciate existing customers and recruit new brand advocates </li>
            <li>Build positive business affinity</li>
            <li>Reinforce brand retention.</li>
          </ListBox>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <AccordionSummary expandIcon={expanded === "panel4" ? <RemoveIcon /> : <AddIcon />}>
          <Typography variant="h2">Consumer Promotions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Empowered with the right to choose, our gift cards help organizations;</Typography>
          <ListBox>
            <li>Initiate positive conversations. </li>
            <li>Entice patronage through giveaways. </li>
            <li>Increases social media engagement.</li>
            <li>Build and sustain relationships.</li>
          </ListBox>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
const ListBox = ({ children }) => {
  return (
    <Box
      sx={{
        // listStyle: "none",
        color: "text.disabled",
        fontSize: "18px",
        "& li": {
          padding: "5px",
        },
      }}
      component={"ul"}
    >
      {children}
    </Box>
  );
};
