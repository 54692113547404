import FilterSection from "./FilterSection";
import { useDeleteManager, useGetManagers } from "../../services/business";

import { DeleteIcon, EditPencilIcon, MainTable, PageFilter, Pagination, TableBody, useDocumentTitle, useFilter, useNotificationContext, usePagination } from "suregifts-component-lib";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import moment from "moment";

function ManagerScreen() {
  const [filter, setFilter] = useFilter<PageFilter>({ search: "", page: 1 });
  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  useDocumentTitle("Managers");

  const { confirm } = useNotificationContext();
  const { data, isFetching, refetch } = useGetManagers(filter);
  const { data: items, total } = usePagination(filter.page, data);
  const { mutate: deleteRecord, isLoading: deleting } = useDeleteManager();
  const navigate = useNavigate();
  const handleDelete = (merchantId: any) => {
    confirm({
      onConfirm: () => {
        deleteRecord(merchantId, {
          onSuccess: () => {
            toast.success("Manager deleted successufully.");
            refetch();
          },
        });
      },
    });
  };

  return (
    <div>
      <FilterSection handleFilter={handleFilter} filter={filter} />
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Date Added</TableCell>
            <TableCell>Visibility</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.role}</TableCell>
              <TableCell>{moment(item.dateAdded).format("DD.MM.YYYY")}</TableCell>
              <TableCell sx={{ width: "10%", whiteSpace: "nowrap" }}>
                <IconButton
                  disabled={item.roleId === "BUSINESS_OWNER"}
                  onClick={() => {
                    sessionStorage.setItem("MANAGER_DATA", JSON.stringify(item));
                    navigate(`${item.id}/edit`);
                  }}
                >
                  <EditPencilIcon />
                </IconButton>
                <IconButton disabled={item.roleId === "BUSINESS_OWNER"} color="error" onClick={() => handleDelete(item.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          size={filter.size}
          page={filter.page}
          total={total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
      <Outlet />
    </div>
  );
}

export default ManagerScreen;
