import { LoadingBox, MarginTop40Box, Pagination, useNotificationContext } from "suregifts-component-lib";
import { useDeleteGroup, useGetGroups } from "../../../services/receipient";
import { Box, useTheme } from "@mui/material";
import { useRecipientContext } from "../RecipientContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { GroupCard } from "./GroupCard";
import { StorageKeys } from "../../../routes/RequireAuth";

function GroupListView({ filter, handleFilter }) {
  const { state } = useRecipientContext();
  const { confirm } = useNotificationContext();
  const { data, refetch, isFetching } = useGetGroups(filter);
  const theme = useTheme();
  const { mutate: deleteRecord } = useDeleteGroup();
  const navigate = useNavigate();
  const handleDelete = (groupId: any) => {
    confirm({
      onConfirm: () => {
        deleteRecord(groupId, {
          onSuccess: () => {
            toast.success("Group deleted successufully.");
            refetch();
          },
        });
      },
    });
  };
  return (
    <>
      <LoadingBox
        loading={isFetching}
        sx={{
          display: "grid",
          gap: "1.25rem",
          marginTop: "40px",
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2,1fr)",
          },
          [theme.breakpoints.up("xl")]: {
            gridTemplateColumns: "repeat(3,1fr)",
          },
        }}
      >
        {data?.items.map((item) => (
          <GroupCard
            key={item.id}
            group={item}
            onDelete={handleDelete}
            onClick={() => {
              var model = { groupId: item.id, numberOfvouchers: item.members, narration: item.name };
              const data = JSON.stringify(model);
              sessionStorage.setItem(StorageKeys.RECIPIENT_VOUCHERS, data);
              navigate("/order-request");
            }}
          />
        ))}
      </LoadingBox>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          size={filter.size}
          page={filter.page}
          total={data?.total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
    </>
  );
}

export default GroupListView;
