import { Box, Typography } from "@mui/material";
import moment from "moment";
import { AmountSummaryCard, FormButton, SummaryTitle, useUtilityContent } from "suregifts-component-lib";

export type BillPaymentSummaryProps = {
  subTotal: number;
  surcharge: number;
  total: number;
};

export const SummaryView = () => {
  const {
    form: {
      formState: { isValid },
    },
    amountSummary,
    bill,
    submitForm,
    sending,
  } = useUtilityContent();

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          alignItems: "center",
          overflow: "hidden",
          objectFit: "fill",
          backgroundColor: "secondary.main",
          color: "secondary.contrastText",
          fontWeight: 700,
          fontSize: "3.25rem",
          textTransform: "uppercase",
          height: "15rem",
          borderRadius: "2rem",
        }}
      >
        {bill.name?.match(/\b\w/g)?.join("")}
        <Box component={"img"} src={bill.logoUrl} sx={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0 }} />
      </Box>

      <AmountSummaryCard
        header={
          <div style={{ marginTop: "16px" }}>
            <SummaryTitle title={"Order Summary"} />
            <Box sx={{ margin: "16px 0 24px", display: "grid", gap: "10px" }}>
              <Typography sx={{ color: "text.secondary", fontSize: "16px" }}>{bill.name}</Typography>
              <Typography sx={{ color: "text.secondary", fontSize: "16px" }}>{moment().format("dddd, Do MMM yyyy")}</Typography>
            </Box>
          </div>
        }
      />
      <FormButton onClick={submitForm} loading={sending} type="submit" disabled={!isValid || (amountSummary?.amountPayable || 0) === 0} label="Place Order" fullWidth sx={{ marginTop: "1.875rem" }} />
    </div>
  );
};
