import { Typography, Stack, Box, useTheme } from "@mui/material";
import { useGetWalletInfo, useGetFundingMethods, FundingMethodData } from "../../services/transaction";

import { WalletType } from "suregifts-component-lib";
import { useMemo } from "react";
import useNavigator from "../../hooks/useNavigator";

export const BalanceCard = () => {
  const { data } = useGetWalletInfo();
  const { data: methods } = useGetFundingMethods();

  return (
    <div style={{ alignSelf: "center" }}>
      <Typography sx={{ fontSize: "0.625rem", color: "#AAAABE", textTransform: "uppercase" }}>Balance</Typography>
      <Typography sx={{ fontSize: "2.75rem", fontWeight: 700 }}>{data?.balanceString}</Typography>
      <Typography sx={{ fontSize: "0.625rem", color: "#AAAABE", textTransform: "uppercase" }}>top up wallet via:</Typography>
      <Box
        sx={(theme) => ({
          marginTop: "0.9375rem",
          display: "grid",
          gap: "12px",
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          [theme.breakpoints.up(1280)]: {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
          [theme.breakpoints.up(1648)]: {
            gridTemplateColumns: "repeat(4, 1fr)",
          },
        })}
      >
        {methods?.map((item) => (
          <FundButton key={item.id} method={item} />
        ))}
      </Box>
    </div>
  );
};
const FundButton = ({ method }: { method: FundingMethodData }) => {
  const navigator = useNavigator();
  const icon = useMemo(() => {
    switch (method.id) {
      case WalletType.BankTransfer:
        return "/assets/images/bank-transfer.png";
      case WalletType.Paypal:
        return "/assets/images/paypal.png";
      case WalletType.Crypto:
      case WalletType.FlutterwaveKenya:
      case WalletType.Paystack:
      case WalletType.Kora:
        return "/assets/images/card-payment.png";
      default:
        return "";
    }
  }, []);
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        cursor: "pointer",
        borderRadius: "0.9375rem",
        padding: "1.125rem 0.9375rem",
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        overflow: "hidden !important",
        "&:hover": {
          backgroundColor: "primary.light",
        },
      }}
      onClick={() => {
        if (method.id === WalletType.BankTransfer) {
          navigator("bank-deposit");
        } else {
          sessionStorage.setItem("PAYMENT_METHOD", JSON.stringify(method));
          navigator("fund");
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "1.25rem",
          width: "2.5rem",
          height: "2.5rem",
          backgroundColor: "primary.dark",
          overflow: "hidden",
        }}
      >
        <Box component={"img"} src={icon} />
      </Box>
      <span style={{ fontWeight: 600, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{method.name}</span>
    </Box>
  );
};
