import { Container } from "@mui/material";
import React from "react";
import { ErrorPageTemplate } from "suregifts-component-lib";

import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../contexts/GlobalContext";

function NotFoundPage() {
  const { initialSetting } = useGlobalContext();

  return (
    <Container sx={{ marginBottom: "60px", marginTop: "2.5rem" }}>
      <ErrorPageTemplate
        imageUrl="/assets/images/not-found.svg"
        button1Label={"Go Back"}
        button2Label={"Contact Us"}
        topText={"Ooops! Page not found"}
        button1Click={() => window.history.back()}
        button2Click={() => (window.location.href = `${initialSetting?.websiteUrl}/contact-support`)}
        bottomText={
          <span>
            We can't find what you're looking for because the page you're <br />
            looking for doesn't exist.
          </span>
        }
      />
    </Container>
  );
}

export default NotFoundPage;
