import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { FormButton, LoadingBox, MarginTop40Box, PageFilter, Pagination, SearchInput, useAuthContext, useDocumentTitle, useFilter } from "suregifts-component-lib";
import { BusinessData, useGetMyBusinesses } from "../../services/business";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BusinessCard } from "./BusinessCard";
import { useCookies } from "react-cookie";
import { useLogOut } from "../../services/auth";

function MyAccountsScreen() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [_, setCookie, removeCookie] = useCookies(["BUSINESS_ID"]);
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, size: 6, search: "" });
  const { data, isFetching, refetch } = useGetMyBusinesses(filter, (d) => {});
  const { signOut } = useAuthContext();
  const handleClick = (item: BusinessData) => {
    setCookie("BUSINESS_ID", item.id, { path: "/" });
    navigate("/dashboard");
  };
  const { mutate: logOut } = useLogOut();
  useDocumentTitle("Home");
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  useEffect(() => {
    refetch();
  }, [pathname]);

  return (
    <div style={{ textAlign: "center", padding: "5rem 6.25rem 2.5rem" }}>
      <HeaderSection />
      <Box sx={{ display: "flex", justifyContent: "center", gap: "20px", mt: "2.5rem" }}>
        <SearchInput
          placeholder="Search"
          value={filter.search}
          sx={{ maxWidth: "60%", flexGrow: 2, height: "4.375rem" }}
          onChange={(e) => {
            handleFilter({ search: e, page: 1 });
          }}
        />
        <FormButton label="Add Account" onClick={() => navigate("add")} />
        <FormButton
          label="Log out"
          onClick={() => {
            logOut(undefined);
            signOut();
            removeCookie("BUSINESS_ID");
            navigate("/");
          }}
          color="secondary"
          variant="outlined"
        />
      </Box>
      <LoadingBox loading={isFetching}>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(3,1fr)", gap: "1.25rem", marginTop: "3.75rem" }}>
          {data?.items.map((item) => (
            <BusinessCard key={item.id} business={item} onClick={() => handleClick(item)} />
          ))}
        </div>
        <MarginTop40Box>
          <Pagination
            total={data?.total}
            size={6}
            page={filter.page}
            align="center"
            onChange={(e) => {
              setFilter((prev) => ({ ...prev, page: e }));
            }}
          />
        </MarginTop40Box>
      </LoadingBox>

      <Outlet />
    </div>
  );
}
const HeaderSection = () => {
  const { user } = useAuthContext();
  return (
    <div>
      <a href="">
        <img src="/assets/images/logo.png" />
      </a>
      <Typography sx={{ fontSize: "3.5rem", fontWeight: 700, mt: "6.25rem" }}>Welcome! {user?.firstName}</Typography>
      <Typography sx={{ fontSize: "1.5rem", mt: "1.5625rem" }}>Continue to any of the dashboards you control by clicking one of the buttons below</Typography>
    </div>
  );
};
export default MyAccountsScreen;
