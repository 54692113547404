import StaticHeader from "./StaticHeader";
import StaticHeaderInfo from "./StaticHeaderInfo";

export const CorporateSolutionSection = () => {
  return (
    <div id="solutions" style={{ marginTop: "5rem", textAlign: "center" }}>
      <StaticHeader>Our Corporate Solutions</StaticHeader>
      <StaticHeaderInfo>
        With over 1000 merchants and 1000 corporate clients, <br />
        we are the leading rewards platform in Africa. Allowing you to send gift cards in bulk and via API
      </StaticHeaderInfo>
    </div>
  );
};
