import { Typography, Stack, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { useVoucherRequestContext } from "../VoucherRequestContext";

type DeliveryMethodViewProps = {
  schedule?: boolean;
  onChange?: (value: any) => void;
};
export const DeliveryMethodView = () => {
  const {
    state: { eventId, schedule },
    updateState,
  } = useVoucherRequestContext();
  return (
    <>
      <Typography sx={{ fontSize: "1.5rem", fontWeight: 700 }}>Delivery Method</Typography>
      <Stack spacing={3} mt="1.875rem">
        <RadioGroup row color="success.main" value={schedule} onChange={(e) => updateState({ schedule: e.target.value === "true" } as any)} sx={{ color: "#AAAABE" }}>
          <FormControlLabel value={false} control={<Radio disabled={!!eventId} color="success" />} label="Instant" />
          <FormControlLabel value={true} control={<Radio disabled={!!eventId} color="success" />} label="Scheduled" />
        </RadioGroup>
      </Stack>
    </>
  );
};
