import { useRecipientContext } from "./RecipientContext";
import { MarginTop40Box, SearchContainer } from "suregifts-component-lib";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";

function RecipientLandingScreen() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default RecipientLandingScreen;
