import { Box, IconButton } from "@mui/material";
import { DeleteIcon, EditPencilIcon, YupShape } from "suregifts-component-lib";
import { AddRecipientModel } from "../../../services/discount";
import * as yup from "yup";
import { useState } from "react";
import { EditStaffModal } from "./EditStaffModal";

type AddedEmployeeViewProps = {
  recipients: AddRecipientModel[];
  removeStaff: (index) => void;
  staffEdited: (item, index) => void;
};
export const AddedEmployeeView = ({ recipients, removeStaff, staffEdited }: AddedEmployeeViewProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  if (!recipients || recipients.length <= 0) return <></>;
  return (
    <Box
      sx={{
        padding: "1rem 20px",
        background: "#17171C",
        borderRadius: "30px",
        display: "grid",
        gap: "1rem",
      }}
    >
      <div style={{ fontWeight: 500 }}>Added employees list ({recipients.length})</div>
      <div>
        {recipients.map((item, index) => (
          <div
            key={item.email + item.phone + item.name}
            style={{ borderTop: "1px solid #3F3F3F", display: "flex", justifyContent: "space-between", gap: "1rem", alignItems: "center" }}
          >
            <Box sx={{ color: "#8C8C9D", fontSize: "14px", fontWeight: 500, paddingY: "0.5rem" }}>{item.name}</Box>
            <div>
              <IconButton
                onClick={() => {
                  setSelectedIndex(index);
                }}
              >
                <EditPencilIcon />
              </IconButton>
              <IconButton color="error" onClick={() => removeStaff(index)}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
      {typeof selectedIndex !== "undefined" && (
        <EditStaffModal
          handleClose={() => setSelectedIndex(undefined)}
          recipient={recipients[selectedIndex]}
          saveChanges={(item) => {
            staffEdited(item, selectedIndex);
            setSelectedIndex(undefined);
          }}
        />
      )}
    </Box>
  );
};
