import { Box } from "@mui/material";
import { MarginTop40Box, PageHeaderTitle, SearchButton, SearchContainer, SearchInput } from "suregifts-component-lib";
import { useExportPayments } from "../../services/transaction";
const statuses = [{ text: "All" }, { text: "Active", value: true }, { text: "Inactive", value: false }];
function FilterSection({ handleFilter, filter }) {
  const { mutate: exportData } = useExportPayments(filter);
  return (
    <MarginTop40Box>
      <SearchContainer>
        <PageHeaderTitle title={"Transactions"} />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchInput
            value={filter.search}
            onChange={(e) => {
              handleFilter({ search: e, page: 1 });
            }}
          />

          <SearchButton sx={{ py: "0.125rem" }} label="Export" onClick={() => exportData()} />
        </Box>
      </SearchContainer>
    </MarginTop40Box>
  );
}

export default FilterSection;
