import { createContext, useContext, useReducer } from "react";
import { GroupData, RecipientData } from "../../services/receipient";
import RecipientLandingScreen from "./RecipientLandingScreen";
import { useDocumentTitle } from "suregifts-component-lib";
export type ScreenContextValueProps = {
  state: ScreenState;
  dispatch: React.Dispatch<ActionProps>;
};
const RecipientContext = createContext({} as ScreenContextValueProps);

export const useRecipientContext = () => {
  return useContext(RecipientContext);
};

type ScreenState = {
  recipient?: RecipientData;
  group?: GroupData;
  selection?: number[];
};
type ItemCheckedActionProps = {
  type: "ItemChecked";
  id: number;
  checked: boolean;
};

type ActionProps = ItemCheckedActionProps;

const reducer = (state: ScreenState, action: ActionProps): ScreenState => {
  switch (action.type) {
    case "ItemChecked":
      var result = state.selection!;
      if (action.checked) {
        if (state.selection!.indexOf(action.id) === -1) {
          result.push(action.id);
        }
      } else {
        result = result.filter((x) => x !== action.id);
      }
      return { ...state, selection: result };
  }
};

export const RecipientContextProvider = () => {
  const [state, dispatch] = useReducer(reducer, {
    selection: [],
  });
  useDocumentTitle("Recipients");
  return (
    <RecipientContext.Provider value={{ state, dispatch }}>
      <RecipientLandingScreen />
    </RecipientContext.Provider>
  );
};
