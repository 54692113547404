import { createContext, useCallback, useContext, useState } from "react";
import { PlaceDownloadOrderModel, useGetGiftInfo } from "../../services/order";
import VoucherDownloadScreen from "./VoucherDownloadScreen";
import { useDocumentTitle } from "suregifts-component-lib";
import { StorageKeys } from "../../routes/RequireAuth";

type VoucherDownloadContextProps = {
  state: PlaceDownloadOrderModel;
  updateState: (params: PlaceDownloadOrderModel) => void;
};
const VoucherDownloadContext = createContext({} as VoucherDownloadContextProps);
export const VoucherDownloadContextProvider = () => {
  const [state, setModel] = useState({ voucherValue: "", tabValue: 0, couponCode: "", numberOfvouchers: "" } as PlaceDownloadOrderModel);
  useDocumentTitle("Voucher Purchase");
  const updateState = useCallback(
    (params: PlaceDownloadOrderModel) => {
      setModel((prev) => {
        return { ...prev, ...params };
      });
    },
    [state]
  );
  useGetGiftInfo(state.giftId, (data) => {
    const amount = data.denominations.length > 0 ? 0 : data.minAmount;
    updateState({ voucherValue: amount.toString() } as PlaceDownloadOrderModel);
  });
  return (
    <VoucherDownloadContext.Provider
      value={{
        state: state,
        updateState: updateState,
      }}
    >
      <VoucherDownloadScreen />
    </VoucherDownloadContext.Provider>
  );
};
export const useVoucherDownloadContext = () => {
  return useContext(VoucherDownloadContext);
};
