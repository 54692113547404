import {
  FormButton,
  FormInput,
  TransactionStatusType,
  WalletType,
  formatMoney,
  getPaymentPath,
  useDocumentTitle,
  useNotificationContext,
} from "suregifts-component-lib";
import { PaymentResponseView } from "../PaymentResponseView";
import { IncreaseUnitModel, SubscribeModel, useGetClientPlan, useIncreaseUnits, useSubscribe } from "../../../services/discount";
import { Box, useTheme } from "@mui/material";
import AddBankTransferInfoModal from "../payment/AddBankTransferInfoModal";

import { useLocation, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { useDiscountContext } from "../DiscountContext";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { PaymentMethodView } from "../PaymentMethodView";
import ErrorIcon from "@mui/icons-material/Error";
import { useQueryClient } from "react-query";
import { GreenBorderLabel } from "../GreenBorderLabel";
import { SummaryPanel } from "../SummaryPanel";
import { toast } from "react-toastify";

declare var location;
function ChoosePlanScreen() {
  const theme = useTheme();

  const { mutate: subscribe, isLoading: isSubscribing, data: paymentResponse } = useSubscribe();
  const { mutate: increateUnits, isLoading: isIncreasing } = useIncreaseUnits();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { initialSetting } = useGlobalContext();
  const { price } = useDiscountContext();
  const { confirm } = useNotificationContext();
  const { pathname } = useLocation();

  useDocumentTitle("Discount Subscription");
  const [model, setModel] = useState<SubscribeModel>({ paymentMethodId: WalletType.CashWallet, units: "10" });

  const submitForm = () => {
    if (model.paymentMethodId === WalletType.CashWallet) {
      confirm({
        onConfirm: sendRequest,
      });
    } else {
      sendRequest();
    }
  };

  const sendRequest = () => {
    const data = { ...model, returnPath: `payment-callback/${getPaymentPath(model.paymentMethodId)}` };
    if (pathname === "/discounts/add-units") {
      increateUnits(data as IncreaseUnitModel, {
        onSuccess: (data) => {
          queryClient.invalidateQueries("discount-client-plan");
          if (data.paymentLink) {
            location.href = data.paymentLink;
          }
          if (data.paymentMethodId === WalletType.CashWallet) {
            toast.success(`You have successfully added ${model.units} employees`);
            navigate("/discounts/index");
          }
        },
      });
    } else {
      subscribe(data as SubscribeModel, {
        onSuccess: (data) => {
          queryClient.invalidateQueries("discount-client-plan");
          if (data.paymentLink) {
            location.href = data.paymentLink;
          }
        },
      });
    }
  };
  const modelChanged = (data: any) => {
    setModel((prev) => ({ ...prev, ...data }));
  };
  if (paymentResponse && paymentResponse.paymentStatusId === TransactionStatusType.Successful && model.paymentMethodId !== WalletType.BankTransfer) {
    return <PaymentResponseView desc={"You have successfully subscribed to staff discounts"} />;
  }

  return (
    <>
      <Box
        sx={{
          borderRadius: "2rem",
          background: "#17171C",
          overflow: "hidden",
          display: "grid",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "3fr 2fr",
            margin: "3rem 90px",
          },
        }}
      >
        <Box
          sx={{
            background: "#1D1C23",
            padding: "2rem 35px",
            [theme.breakpoints.up("md")]: {
              paddingX: "73px",
            },

            "h3.name": {
              fontSize: "40px",
              fontWeight: 500,
              margin: "0 0 1rem",
            },
            "div.price": {
              fontWeight: "500",
              margin: "8px 0 23px",
            },
            "p.desc": {
              opacity: 0.7,
              fontSize: "14px",
              margin: "1rem 0 2rem",
            },
            "div.warning": {
              opacity: 0.7,
              marginTop: "12px",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            },
          }}
        >
          <h3 className="name">Discount Plan</h3>
          <GreenBorderLabel label={`${formatMoney(price, initialSetting?.currency)} per employee`} />
          <p className="desc">You can add unlimited number of employees on this plan to enjoy big discounts when shopping at our merchants stores.</p>
          <FormInput label="Number of Employee" isNumber value={model.units} onChange={(e) => modelChanged({ units: e.target.value })} />
          <SummaryPanel units={model.units} price={price} />
          <div className="warning">
            <ErrorIcon sx={{ fontSize: "16px" }} color="warning" />
            <div>You will only be able to add the number of employee you’re paying for</div>
          </div>
        </Box>

        <Box sx={{ padding: "1.5rem 35px" }}>
          <>
            <h3>Payment Method</h3>
            <PaymentMethodView methodId={model.paymentMethodId} methodChanged={(paymentMethodId) => modelChanged({ paymentMethodId })} />
            <FormButton
              disabled={!model.units}
              label="Make Payment"
              fullWidth
              sx={{ marginTop: "46px" }}
              onClick={submitForm}
              loading={isSubscribing || isIncreasing}
            />
          </>
        </Box>
        {model.paymentMethodId === WalletType.BankTransfer && paymentResponse && (
          <AddBankTransferInfoModal
            invoiceNumber={paymentResponse?.invoiceNumber}
            onClose={() => {
              navigate("/discounts/index");
            }}
          />
        )}
      </Box>
    </>
  );
}
export default ChoosePlanScreen;
