import { Box } from "@mui/material";
import StaticHeader from "./StaticHeader";

export const DashboardControlSection = () => {
  return (
    <div style={{ marginTop: "8rem", textAlign: "center" }}>
      <StaticHeader>A powerful dashboard to control how you gift</StaticHeader>
      <Box
        component={"img"}
        src="/assets/images/business/screenshot.png"
        sx={{
          marginTop: "2.5rem",
          width: "100%",
        }}
      />
    </div>
  );
};
