import { Dialog, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { useEffect, useMemo, useRef, useState } from "react";
import { DialogTitle, FormButton } from "suregifts-component-lib";
import { range } from "../../../helpter";
type PinInputProps = {
  stringInput: string;
  index: number;
  onClick: (index: number) => void;
};
const PinInput = ({ stringInput, index, onClick }: PinInputProps) => {
  const display = useMemo(() => {
    return stringInput.substring(index, index + 1);
  }, [stringInput]);
  return (
    <Typography
      onClick={() => onClick(index)}
      sx={{
        fontSize: "1.25rem",
        paddingY: "0.625rem",
        textAlign: "center",
        borderRadius: "1rem",
        width: "100%",
        height: "52px",
        border: "1px solid rgba(255, 255, 255, 0.3)",
        ...(index === stringInput.length && { borderColor: "text.primary" }),
      }}
    >
      {display}
    </Typography>
  );
};

function PinModal({ open, handleClose, submitClick }) {
  const [pin, setPin] = useState("");

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onKeyDown={(evt) => {
        if (evt.keyCode === 8) {
          setPin((prev) => prev.substring(0, prev.length - 1));
          return;
        }
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode >= 48 && charCode <= 57) {
          setPin((prev) => {
            if (prev.length === 4) return prev;
            return prev + String.fromCharCode(charCode);
          });
        }
      }}
    >
      <DialogTitle
        title={
          <div>
            <div>Set Pin</div>
            <Typography sx={{ color: "text.secondary" }}>This pin will be used to redeem this voucher(s)</Typography>
          </div>
        }
        handleClose={handleClose}
      />
      <DialogContent>
        <Stack direction={"row"} spacing={3}>
          {range(0, 3).map((index) => (
            <PinInput
              key={index}
              index={index}
              stringInput={pin}
              onClick={(index) => {
                setPin((prev) => prev.substring(0, index));
              }}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton label="Set PIN and Place Order" disabled={pin.length !== 4} fullWidth onClick={() => submitClick(pin)} />
      </DialogActions>
    </Dialog>
  );
}

export default PinModal;
