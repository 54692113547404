import { Box } from "@mui/system";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ReviewBoxView } from "../../../components/molecules/ReviewBoxView";
import { toast } from "react-toastify";
import { OrderAmountSummaryView } from "../../../components/organisms/OrderAmountSummaryView";
import { SxProps } from "@mui/material";
import { useVoucherRequestContext } from "../VoucherRequestContext";
import { PlaceOrderModel, usePlaceOrder } from "../../../services/order";
import { TemplateThumbnail } from "suregifts-component-lib";
import { SubmitOrderView } from "../../../components/organisms/SubmitOrderView";

type ReviewMainViewProps = {};
export const layoutStyle: SxProps = (theme: any) => ({
  marginTop: "1.875rem",
  display: "grid",
  gap: "60px",
  maxWidth: "md",
  marginX: "auto",
  paddingBottom: "80px",
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "1fr 350px",
  },
});
function ReviewMainTabView({}: ReviewMainViewProps) {
  const navigate = useNavigate();
  const { state: model, updateState } = useVoucherRequestContext();

  const { mutate: placeOrder, isLoading: isSending } = usePlaceOrder();
  const handleSubmit = () => {
    placeOrder(model, {
      onSuccess: () => {
        toast.success("Order submited successfully.");
        navigate("/orders/vouchers");
      },
    });
  };

  return (
    <Box sx={layoutStyle}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.9375rem",
        }}
      >
        <div style={{ borderRadius: "20px", overflow: "clip" }}>
          <ReviewBoxView dt={"Merchant"} dd={model.giftName} onEditClick={() => updateState({ tabValue: 0 } as PlaceOrderModel)} />
          <ReviewBoxView dt={"Recipients"} dd={model.numberOfvouchers} onEditClick={() => navigate("/recipients/vouchers")} />
          <ReviewBoxView
            showButton={!model.eventId}
            onEditClick={() => updateState({ tabValue: 0 } as PlaceOrderModel)}
            dt={"Delivery Date"}
            dd={model.schedule ? `${moment(model.deliveryDate).format("L")} ${moment(model.deliveryTime).format("LT")}` : "Instant"}
          />
          <ReviewBoxView dt={"Narration"} dd={model.narration} onEditClick={() => updateState({ tabValue: 0 } as PlaceOrderModel)} />
        </div>
        <div style={{ maxWidth: "170px" }}>
          <TemplateThumbnail id={model.templateId} imageUrl={model.imageUrl} isClient={false} />
        </div>
      </Box>
      <div>
        <OrderAmountSummaryView model={model} handleChange={updateState} />
        <SubmitOrderView handleSubmit={handleSubmit} isSending={isSending} />
      </div>
    </Box>
  );
}

export default ReviewMainTabView;
