import { toast } from "react-toastify";
import { RecipientData, useEditRecipient } from "../../../services/receipient";

import { RecipientDialog } from "./RecipientDialog";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useState } from "react";

export function EditRecipientModal() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [recipient] = useState(() => {
    const data = sessionStorage.getItem("RECIPIENT_DATA");
    if (!data) return {} as RecipientData;

    return JSON.parse(data) as RecipientData;
  });

  const { mutate: editRecipient, isLoading } = useEditRecipient(recipient?.id);

  const submitForm = (data) => {
    editRecipient(data, {
      onSuccess: () => {
        toast.success("Recipient added successfully.");
        queryClient.invalidateQueries("recipients");
        handleClose();
      },
    });
  };

  const handleClose = () => {
    if (isLoading) return;
    navigate("..");
  };
  return <RecipientDialog isLoading={isLoading} handleClose={handleClose} onSubmit={submitForm} title="Edit Recipient" buttonTitle={"Edit"} recipient={recipient} />;
}
