import { useState } from "react";
import RecipientTableView from "./RecipientTableView";
import RecipientFilterContainer from "../RecipientFilterContainer";
import { Box, IconButton } from "@mui/material";
import { PageFilter, SearchButton, SearchInput, useFilter } from "suregifts-component-lib";

import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useRecipientContext } from "../RecipientContext";
import { StorageKeys } from "../../../routes/RequireAuth";
function RecipientScreen() {
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, search: "" });
  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const { state } = useRecipientContext();
  const navigate = useNavigate();
  return (
    <>
      <RecipientFilterContainer>
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => navigate("upload")}>
            <FileUploadIcon />
          </IconButton>
          <SearchInput
            value={filter.search}
            onChange={(e) => {
              handleFilter({ search: e, page: 1 });
            }}
          />
          <SearchButton sx={{ py: "0.125rem" }} label="Add Recipient" onClick={() => navigate("add")} />
          <SearchButton
            onClick={() => {
              const data = { recipients: state.selection, numberOfvouchers: state.selection!.length, narration: "" };
              sessionStorage.setItem(StorageKeys.RECIPIENT_VOUCHERS, JSON.stringify(data));
              navigate("/order-request");
            }}
            color="success"
            sx={{ py: "0.125rem" }}
            label="Send Voucher"
            disabled={state.selection!.length === 0}
          />
        </Box>
      </RecipientFilterContainer>
      <RecipientTableView filter={filter} handleFilter={handleFilter} />
    </>
  );
}

export default RecipientScreen;
