import { createContext, useContext } from "react";
import { BusinessInfoData, useGetBusinessInfo } from "../services/business";
import { LoadingBox, RootScreen } from "suregifts-component-lib";
import Main from "../pages/root/Main";
import NavBar from "../pages/root/NavBar";
import { RequireAuth } from "../routes/RequireAuth";
import { GlobalContextProvider } from "./GlobalContext";
import { DiscountContextProvider } from "../pages/discounts/DiscountContext";
type BusinessContextProps = {
  bizInfo?: BusinessInfoData;
};
const BusinessContext = createContext({} as BusinessContextProps);

export const BusinessContextProvider = () => {
  const { data: bizInfo } = useGetBusinessInfo();

  if (!bizInfo) return <LoadingBox loading></LoadingBox>;
  return (
    <GlobalContextProvider>
      <BusinessContext.Provider value={{ bizInfo }}>
        <DiscountContextProvider>
          <RequireAuth>
            <RootScreen nav={<NavBar />} main={<Main />} />
          </RequireAuth>
        </DiscountContextProvider>
      </BusinessContext.Provider>
    </GlobalContextProvider>
  );
};
export const useBusinessContext = () => useContext(BusinessContext);
