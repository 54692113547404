import { toast } from "react-toastify";
import { useAddRecipient } from "../../../services/receipient";

import { RecipientDialog } from "./RecipientDialog";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

export function AddRecipientModal() {
  const { mutate: addRecipient, isLoading } = useAddRecipient();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const submitForm = (data) => {
    addRecipient(data, {
      onSuccess: () => {
        toast.success("Recipient added successfully.");
        queryClient.invalidateQueries("recipients");
        handleClose();
      },
    });
  };

  const handleClose = () => {
    if (isLoading) return;
    navigate("..");
  };
  return <RecipientDialog handleClose={handleClose} onSubmit={submitForm} title="Add Recipient" buttonTitle={"Create"} isLoading={isLoading} />;
}
