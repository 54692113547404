import { Box, useTheme } from "@mui/material";
import React from "react";

export const HowToUse = () => {
  const theme = useTheme();
  return (
    <Box
      component={"section"}
      sx={{
        h2: {
          fontSize: "46px",
          fontWeight: 700,
          lineHeight: "110%",
          textAlign: "center",
        },
        margin: "97px 0 86px",
        h3: {
          fontSize: "2rem",
          fontWeight: 500,
          lineHeight: "110%",
        },
      }}
    >
      <h2>How it works in 3 easy steps</h2>
      <Box
        sx={{
          display: "grid",
          gap: "60px",
          [theme.breakpoints.up("md")]: {
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
          },
        }}
      >
        {[
          { number: "1", title: "Login or Sign up", desc: "Log in to an existing SureGifts Business account  or sign up for a new one." },
          { number: "2", title: "Subscribe", desc: "Choose a discount plan for your staff, clients, etc." },
          { number: "3", title: "Upload List", desc: "Upload the list of staff and that’s it. They’ll be able to big discount  on voucher purchase." },
        ].map((item) => (
          <HowToUseStep {...item} />
        ))}
      </Box>
    </Box>
  );
};
const HowToUseStep = ({ number, title, desc }) => {
  return (
    <Box
      component={"article"}
      sx={{
        h3: {
          fontSize: "2rem",
          fontWeight: 500,
          lineHeight: "110%",
          marginTop: "24px",
        },
        display: "flex",
        gap: "1.5rem",
        alignItems: "start",
        "div.number": {
          minWidth: "77px",
          minHeight: "77px",
          borderRadius: "50%",
          border: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "110%",
          textAlign: "center",
        },
      }}
    >
      <div className="number">{number}</div>
      <div>
        <h3>{title}</h3>
        <div style={{ opacity: "0.7" }}>{desc}</div>
      </div>
    </Box>
  );
};
