import { Box, TableRow, TableCell } from "@mui/material";
import { PageHeaderTitle, SelectionTable, SelectionTableHead, SelectionTableBody, OrderItemStatusLabel } from "suregifts-component-lib";
import { BillOrderInfo } from "../../../../services/utilities";
import { SecondLabel } from "../../vouchers/details/RecipientSection";
import { fixedCellStyle } from "../../vouchers/list/OrderListScreen";
type RecipientSectionProps = {
  data?: BillOrderInfo;
};
function RecipientSection({ data }: RecipientSectionProps) {
  return (
    <div>
      <PageHeaderTitle title="Recipients" />
      <Box sx={{ marginTop: "1.5625rem", marginLeft: "-1.25rem", marginRight: "-1.25rem" }}>
        <SelectionTable>
          <SelectionTableHead>
            <TableRow>
              <TableCell>Customer ID</TableCell>
              <TableCell>Recipient</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </SelectionTableHead>
          <SelectionTableBody>
            {data?.recipients.map((item) => (
              <TableRow key={item.id}>
                <TableCell style={fixedCellStyle}>{item.customerId}</TableCell>
                <TableCell>
                  {item.name} <br />
                  <SecondLabel>{item.email}</SecondLabel>
                </TableCell>

                <TableCell style={fixedCellStyle}>
                  <OrderItemStatusLabel label={item.deliveryStatus} status={item.deliveryStatusId} />
                </TableCell>
                <TableCell style={fixedCellStyle}>{data.amountPerUnit}</TableCell>
              </TableRow>
            ))}
          </SelectionTableBody>
        </SelectionTable>
      </Box>
    </div>
  );
}

export default RecipientSection;
