import { MarginTop40Box, PageHeaderTitle, TemplateContextProdiver, TemplateFilterSection, TemplateLayoutView, useDocumentTitle } from "suregifts-component-lib";

import { Box } from "@mui/material";

function EmailTemplateScreen() {
  useDocumentTitle("Email Templates");

  return (
    <div>
      <PageHeaderTitle sx={{ marginTop: "40px" }} title="Email Templates" />
      <TemplateContextProdiver>
        <TemplateFilterSection />
        <TemplateLayoutView />
      </TemplateContextProdiver>
    </div>
  );
}

export default EmailTemplateScreen;
