import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import moment from "moment";
import { MainTable, OrderStatusLabel, Pagination, SearchIcon, TableBody, useDocumentTitle, useFilter } from "suregifts-component-lib";
import { FilterSection } from "./FilterSection";

import { useNavigate } from "react-router-dom";
import { BillOrderFilter, useGetUtilities } from "../../../../services/utilities";
function UtlitiesScreen() {
  const [filter, setFilter] = useFilter<BillOrderFilter>({ page: 1, search: "" } as BillOrderFilter);
  const { data, isFetching } = useGetUtilities(filter);
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  useDocumentTitle("Utilities");
  const navigate = useNavigate();
  return (
    <div>
      <FilterSection filter={filter} handleFilter={handleFilter} total={data?.total} />
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>No of Recipient</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{moment(item.dateCreated).format("DD.MM.YYYY")}</TableCell>
              <TableCell>{item.billType}</TableCell>
              <TableCell>
                <OrderStatusLabel label={item.status} status={item.statusId} />
              </TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.subTotal}</TableCell>
              <TableCell>
                <IconButton onClick={() => navigate(`${item.id}`)}>
                  <SearchIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={data?.total} page={filter.page} onChange={(page) => handleFilter({ page })} />
      </div>
    </div>
  );
}

export default UtlitiesScreen;
