import { Box, Typography, IconButton } from "@mui/material";
import { EditPencilIcon } from "suregifts-component-lib";

type ReviewBoxViewProps = {
  onEditClick?: () => void;
  dd: string;
  dt: string;
  showButton?: boolean;
};
export const ReviewBoxView = ({ dt, dd, onEditClick = () => {}, showButton = true }: ReviewBoxViewProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        padding: "1.25rem",
        position: "relative",
      }}
    >
      <Typography sx={{ color: "#AAAABE" }}>{dt}</Typography>
      <Typography sx={{ fontWeight: 500, lineHeight: "1.5rem" }}>{dd}</Typography>
      {showButton && (
        <IconButton sx={{ position: "absolute", right: "1.25rem", top: "1.25rem" }} onClick={onEditClick}>
          <EditPencilIcon />
        </IconButton>
      )}
    </Box>
  );
};
