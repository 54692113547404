import { Container } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ErrorPageTemplate } from "suregifts-component-lib";
import { useGlobalContext } from "../../contexts/GlobalContext";

function ServerErrorPage() {
  const navigate = useNavigate();
  const { initialSetting } = useGlobalContext();
  return (
    <Container sx={{ marginBottom: "60px", marginTop: "2.5rem" }}>
      <ErrorPageTemplate
        imageUrl="/assets/images/server-error.svg"
        button1Label={"Reload Page"}
        button2Label={"Contact Us"}
        topText={"Page not working"}
        button1Click={() => window.history.back()}
        button2Click={() => (window.location.href = `${initialSetting?.websiteUrl}/contact-support`)}
        bottomText={<span>We are currently unable to handle your request</span>}
      />
    </Container>
  );
}

export default ServerErrorPage;
