import { useQuery, useMutation } from "react-query";
import { PageFilter, ServerError, Count, NameAndId, useAxios } from "suregifts-component-lib";

export const useGetRecipients = (filter: PageFilter) => {
  const axios = useAxios();
  return useQuery<Count<RecipientData>, ServerError>({
    queryKey: ["recipients", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get("/recipients/api", {
        params: filter,
      }),
  });
};

export const useFindRecipientByEmail = (search?: string) => {
  const axios = useAxios();
  return useQuery<RecipientData[], ServerError>({
    queryKey: ["find-by-email", { search }],
    keepPreviousData: true,
    initialData: [],
    queryFn: () =>
      axios.get("/recipients/api/find-by-email", {
        params: { search },
      }),
  });
};
export const useAddRecipient = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, RecipientModel>((model) => {
    return axios.post("/recipients/api", model);
  });
};
export const useUploadRecipients = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, string | undefined>((fileUrl) => {
    return axios.post("/recipients/api/upload-csv", {
      fileUrl,
    });
  });
};
export const useEditRecipient = (recipientId?: any) => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, RecipientModel>((model) => {
    return axios.post(`/recipients/api/${recipientId}`, model);
  });
};
export const useDeleteRecipient = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, any>((recipientId) => {
    return axios.delete(`/recipients/api/${recipientId}`);
  });
};
export const useGetGroupNames = () => {
  const axios = useAxios();
  return useQuery<NameAndId[], ServerError>({
    queryKey: ["group-names"],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    initialData: [],
    queryFn: () => axios.get("/recipients/api/group-names", {}),
  });
};
export const useGetGroups = (filter: PageFilter) => {
  const axios = useAxios();
  return useQuery<Count<GroupData>, ServerError>({
    queryKey: ["groups", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    refetchOnWindowFocus: false,
    queryFn: () =>
      axios.get("/recipients/api/groups", {
        params: filter,
      }),
  });
};
export const useGetGroupById = (groupId, onSuccess?: (data: GroupInfoData) => void) => {
  const axios = useAxios();
  return useQuery<GroupInfoData, ServerError>({
    queryKey: ["groupById", { groupId }],
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    refetchInterval: 0,
    queryFn: () => axios.get(`/recipients/api/groups/${groupId}`, {}),
    onSuccess: onSuccess,
  });
};
export const useAddGroup = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, AddGroupModel>((model) => {
    return axios.post("/recipients/api/groups", model);
  });
};
export const useEditGropup = (groupId?: any) => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, EditGroupModel>((model) => {
    return axios.post(`/recipients/api/groups/${groupId}`, model);
  });
};
export const useDeleteGroup = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, any>((groupId) => {
    return axios.delete(`/recipients/api/groups/${groupId}`);
  });
};
export const useDeleteGroupRecipients = (groupId) => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, RemoveGroupRecipientModel>((model) => {
    return axios.delete(`/recipients/api/groups/${groupId}/recipients`, {
      data: model,
    });
  });
};
export const useAddGroupRecipients = (groupId) => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, AddGroupRecipientModel>((model) => {
    return axios.post(`/recipients/api/groups/${groupId}/recipients`, model);
  });
};
export const useEditGroupRecipient = (groupId) => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, RecipientModel>((model) => {
    return axios.post(`/recipients/api/groups/${groupId}/recipients/${model.id}`, model);
  });
};
export interface RecipientData {
  id: number;
  checked: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  recipientId: string;
}
export interface RecipientModel {
  firstName: string;
  phone: string;
  email: string;
  lastName: string;
  birthday: string | null;
  id: number;
}
export interface GroupData {
  id: number;
  name: string;
  members: number;
  dateCreated: string;
}
export interface AddGroupModel extends AddGroupRecipientModel {
  name: string;
}
export interface EditGroupModel {
  name: string;
}
export interface RemoveGroupRecipientModel {
  recipientIds: number[];
}
export interface AddGroupRecipientModel {
  recipientIds: number[];
  fileUrl: string;
}
export interface GroupInfoData {
  id: number;
  name: string;
  members: number;
  dateCreated: string;
  recipients: RecipientData[];
}
