import { useState } from "react";
import { useGetEventById } from "../../../services/event";
import { useNavigate, useParams } from "react-router-dom";
import {
  MainTable,
  MarginTop40Box,
  PageHeaderTitle,
  SearchButton,
  SearchContainer,
  SearchInput,
  useDocumentTitle,
  usePagination,
  Pagination,
  TableBody,
  ResendIcon,
  DeliveryStatusType,
  useFilter,
  PageFilter,
  OrderItemStatusLabel,
} from "suregifts-component-lib";
import { Box, IconButton, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { PageBackView } from "../../../components/molecules/PageBackView";
import { TitleDetails } from "../details/EventInfoScreen";
import { RecipientData, useGetOrderById, useResendVoucher } from "../../../services/order";
import { toast } from "react-toastify";
import CancelOrderModal from "../../orders/vouchers/details/CancelOrderModal";
import { orderStatusColor } from "suregifts-component-lib";

function EventVouchersScreen() {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const theme = useTheme();
  const { data: event, refetch, isLoading: isGettingEvent } = useGetEventById(eventId);
  const { data: order, isLoading: isGettingOrders, refetch: refetchVouchers } = useGetOrderById(event?.orderId);
  const [openCancel, setOpenCancel] = useState(false);
  useDocumentTitle("Event Vouchers");

  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, search: "" });
  const { total } = usePagination(filter.page, order?.recipients);
  const { mutate: resendVoucher, isLoading } = useResendVoucher();
  const handleResend = (item: RecipientData) => {
    resendVoucher(item.id, {
      onSuccess: () => {
        refetchVouchers();
        toast.success("Voucher resent successufully.");
      },
    });
  };

  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };

  return (
    <div>
      <PageBackView to={`/events/${eventId}`} title="Event" />
      <SearchContainer>
        <div style={{ display: "grid", alignItems: "center", gap: "10px" }}>
          <PageHeaderTitle title={event?.title} />
          <Typography sx={{ fontSize: "14px", fontWeight: 500, color: "text.secondary" }}>Order ID: #{event?.orderId}</Typography>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchInput value={filter.search} onChange={(e) => handleFilter({ search: e })} />
          {order?.canCancel && <SearchButton sx={{ py: "0.125rem", color: "error.main" }} label="Cancel Order" color="primary" onClick={() => setOpenCancel(true)} />}
        </div>
      </SearchContainer>
      <MarginTop40Box>
        <Box
          sx={{
            display: "grid",
            gap: "1.25rem",
            [theme.breakpoints.up("sm")]: {
              gridTemplateColumns: "repeat(3,1fr)",
            },
          }}
        >
          <TitleDetails desc={order?.status} title="Status" descColor={orderStatusColor(order?.statusId)} />
          <TitleDetails desc={moment(event?.date).format("DD.MM.YYYY - h:mma")} title="Date-Time" />
          <TitleDetails desc={order?.amountDue} title="Total" />
        </Box>
      </MarginTop40Box>
      <MarginTop40Box>
        <MainTable>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order?.recipients.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  {item.firstName} {item.lastName}
                </TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>
                  <OrderItemStatusLabel label={item.status} status={item.statusId} />
                </TableCell>
                <TableCell>{item.value}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleResend(item)} disabled={item.statusId !== DeliveryStatusType.Delivered}>
                    <ResendIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MainTable>
      </MarginTop40Box>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          page={filter.page}
          total={total}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
      {openCancel && (
        <CancelOrderModal
          orderId={event?.orderId}
          handleClose={() => {
            setOpenCancel(false);
            navigate(`/events/${eventId}`);
          }}
        />
      )}
    </div>
  );
}

export default EventVouchersScreen;
