import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { DialogTitle, FormButton, FormInput, YupShape } from "suregifts-component-lib";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { EditGroupModel, GroupInfoData, useEditGropup } from "../../services/receipient";

import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useQueryClient } from "react-query";
export const schema = yup
  .object<YupShape<EditGroupModel>>({
    name: yup.string().required(),
  })
  .required();

function EditGroupModal() {
  const { groupId } = useParams();

  const { mutate: editGroup, isLoading } = useEditGropup(groupId);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [group] = useState(() => {
    const data = sessionStorage.getItem("GROUP_DATA");
    if (!data) return {} as GroupInfoData;

    return JSON.parse(data) as GroupInfoData;
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<EditGroupModel>({
    defaultValues: {
      name: group.name,
    },
    resolver: yupResolver(schema),
  });
  const submitForm = (data) => {
    editGroup(data, {
      onSuccess: () => {
        toast.success("Group edited successfully.");
        queryClient.invalidateQueries("groupById");
        handleClose();
      },
    });
  };
  const handleClose = () => {
    navigate("..");
  };
  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title="Edit Group Name" handleClose={handleClose} />
      <DialogContent>
        <FormInput {...register("name")} label="Group Name" helperText={errors.name?.message} />
      </DialogContent>
      <DialogActions>
        <FormButton label="Update" fullWidth loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default EditGroupModal;
