import { Box, useTheme } from "@mui/material";
import React from "react";

export const SectionTwo = () => {
  const theme = useTheme();
  return (
    <Box
      component={"section"}
      sx={{
        marginTop: "25px",
        borderRadius: "2rem",
        background: "#fff",
        padding: "38px",
        textAlign: "center",
        h2: {
          color: "#7d7d7d",
          fontSize: "1.5rem",
          lineHeight: "180%",
          fontWeight: 500,
        },
      }}
    >
      <h2>You’re in great company</h2>
      <Box
        sx={{
          display: "grid",

          justifyContent: "center",
          gap: "76px",
          img: {
            objectFit: "contain",
            display: "block",
            textAlign: "center",
            marginX: "auto",
          },
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2,1fr)",
          },
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(4,1fr)",
          },
        }}
      >
        <img src="/assets/images/discounts/fidelity.png" alt="Fidelity logo" />
        <img src="/assets/images/discounts/fbn.png" alt="First bank logo" />
        <img src="/assets/images/discounts/airtel.png" alt="Airtel logo" />
        <img src="/assets/images/discounts/dangote.png" alt="Dangote logo" />
      </Box>
    </Box>
  );
};
