import { Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { useDiscountContext } from "../DiscountContext";
import { useMemo } from "react";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { formatMoney, formatNumber } from "suregifts-component-lib";
import { useEmployeeUploadContext } from "./EmployeeUploadContext";

export const ExtraPaymentSummaryView = ({}) => {
  const { clientPlan, price } = useDiscountContext();
  const { initialSetting } = useGlobalContext();
  const { totalUpload, payDiff, newTotal } = useEmployeeUploadContext();

  const diff = useMemo(() => {
    return totalUpload + clientPlan!.usedUnits - clientPlan!.paidUnits;
  }, [totalUpload, clientPlan]);

  if (!payDiff) return <></>;

  return (
    <>
      <Box
        sx={{
          fontWeight: 500,
          paddingTop: "20px",
          overflow: "hidden",
          display: "grid",
          gap: "0.5rem",
          background: "#17171C",
          borderRadius: "30px",
        }}
      >
        <Box
          sx={{
            fontWeight: "500",
            paddingX: " 16px",
            "div.info": {
              fontSize: "12px",
              fontWeight: 400,
            },
            "div.number": {
              fontSize: "2rem",
            },
            overflow: "hidden",
            display: "grid",
            gap: "0.5rem",
            background: "#17171C",
            borderRadius: "30px",
            "div.warning": {
              display: "flex",
              alignItems: "start",
              gap: "0.75rem",
              fontSize: "12px",
            },
          }}
        >
          <div className="title">Number of employee uploaded:</div>
          <div className="number">
            {formatNumber(newTotal)} / <span style={{ opacity: "0.7" }}>{formatNumber(clientPlan?.paidUnits)}</span>
          </div>
          <div className="warning">
            <ErrorOutlineIcon color="warning" />
            <div style={{ flexGrow: 1 }}>
              You’ve exceed the number of employee subscribed on this plan. You are required to pay for {diff} additional employee
            </div>
          </div>
        </Box>

        <Box
          sx={{
            padding: "0.5rem 16px 20px 16px",
            background: "#444447",
            "div.info": {
              fontSize: "10px",
              fontWeight: 500,
              color: "#C4C4C4",
            },
            "div.number": {
              fontSize: "1.5rem",
              fontWeight: 700,
            },
            "div.title": {
              fontSize: "12px",
            },
          }}
        >
          <div className="title">Amount to pay for {diff} addtional employees</div>
          <div className="number">{formatMoney(price! * diff, initialSetting?.currency)}</div>
          <div className="info">Billed at {formatMoney(price!, initialSetting?.currency)}/EMPLOYEE/YEAR</div>
        </Box>
      </Box>
    </>
  );
};
