import { useState } from "react";
import { PageFilter, SearchButton, SearchInput, useDocumentTitle, useFilter } from "suregifts-component-lib";
import { useRecipientContext } from "../RecipientContext";
import GroupListView from "./GroupListView";
import { Outlet, useNavigate } from "react-router-dom";
import RecipientFilterContainer from "../RecipientFilterContainer";
import { Box } from "@mui/material";

function GroupScreen() {
  useDocumentTitle("Groups");
  const { state } = useRecipientContext();
  const [filter, setFilter] = useFilter<PageFilter>({ page: 1, search: "", size: 6 });
  const handleFilter = (params) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  const navigate = useNavigate();
  return (
    <>
      <RecipientFilterContainer>
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <SearchInput
            value={filter.search}
            onChange={(e) => {
              handleFilter({ search: e });
            }}
          />
          <SearchButton sx={{ py: "0.125rem" }} label="Add Group" onClick={() => navigate("add")} />
        </Box>
      </RecipientFilterContainer>
      <GroupListView filter={filter} handleFilter={handleFilter} />

      <Outlet />
    </>
  );
}

export default GroupScreen;
