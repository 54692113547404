import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { DialogTitle, FormButton, FormInput, FormSelect, YupShape } from "suregifts-component-lib";
import { AddAccountModel, useAddAccount } from "../../services/business";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetIndustries } from "../../services/general";
import { useNavigate } from "react-router-dom";

export const schema = yup
  .object<YupShape<AddAccountModel>>({
    name: yup.string().required(),
    industryId: yup.string().required(),
    rcNumber: yup.string().required(),
    unit: yup.string().required(),
  })
  .required();
function AddAccountDialog({}) {
  const { mutate: addAccount, isLoading } = useAddAccount();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm<AddAccountModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      industryId: "",
    },
  });
  const handleClose = () => {
    if (isLoading) return;

    navigate("/my-accounts");
  };
  const { data: industries } = useGetIndustries();
  const submitForm = (data) => {
    addAccount(data, {
      onSuccess: () => {
        toast.success("Account Added successully.");
        handleClose();
      },
    });
  };
  return (
    <Dialog open maxWidth={"xs"} onClose={handleClose}>
      <DialogTitle title="Add Account" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput {...register("name")} helperText={errors.name?.message} label="Company Name" />
          <FormInput {...register("unit")} helperText={errors.unit?.message} label="Business Unit" />
          <Controller control={control} name="industryId" render={({ field }) => <FormSelect helperText={errors.industryId?.message} label="Industry" {...field} options={industries} />} />
          <FormInput {...register("rcNumber")} helperText={errors.rcNumber?.message} label="CAC/RC Number" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton disabled={!isValid} fullWidth label="Add" onClick={handleSubmit(submitForm)} loading={isLoading} />
      </DialogActions>
    </Dialog>
  );
}

export default AddAccountDialog;
