import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { DatePicker, DialogTitle, FormButton, FormInput, FormSelect } from "suregifts-component-lib";
import { Controller, useForm } from "react-hook-form";
import { EditEventModel, EventInfoData, RecipientEventType, useDeleteEvent, useEditEvent } from "../../../services/event";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useState } from "react";
import useNavigator from "../../../hooks/useNavigator";

function EditEventModal() {
  const navigate = useNavigate();
  const navigator = useNavigator();

  const handleClose = () => {
    sessionStorage.removeItem("EVENT_DATA");
    navigator("..");
  };

  const [event] = useState(() => {
    const data = sessionStorage.getItem("EVENT_DATA");
    if (!data) return {} as EventInfoData;

    return JSON.parse(data) as EventInfoData;
  });
  const { mutate: editEvent, isLoading: isEditing } = useEditEvent(event!.id);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditEventModel>({
    defaultValues: {
      repeatTypeId: event!.repeatTypeId,
      date: event!.date,
      title: event!.title,
    },
  });

  const submitForm = (data) => {
    editEvent(data, {
      onSuccess: () => {
        toast.success("Evnet added successfully.");
        handleClose();
      },
    });
  };
  const { mutate: deleteEvent, isLoading: isDeleting } = useDeleteEvent();
  const handleDelete = () => {
    deleteEvent(event!.id, {
      onSuccess: () => {
        toast.success("Event deleted successfully.");
        navigate("/events");
      },
    });
  };

  return (
    <Dialog open maxWidth="xs" scroll="body">
      <DialogTitle title="Edit Event" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput label="Title" {...register("title")} />
          {event?.eventTypeId === RecipientEventType.Others && (
            <>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    label="Event Date"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
                control={control}
                name="date"
              />

              <Controller
                control={control}
                name="repeatTypeId"
                render={({ field }) => (
                  <FormSelect
                    label="Event Repeat Type"
                    options={[
                      { id: "0", name: "None" },
                      { id: "1", name: "Monthly" },
                      { id: "2", name: "Yearly" },
                    ]}
                    {...field}
                  />
                )}
              />
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton fullWidth label="Edit" loading={isEditing} onClick={handleSubmit(submitForm)} />
        <FormButton fullWidth label="Delete" color="primary" sx={{ color: "error.main" }} loading={isDeleting} onClick={handleDelete} />
      </DialogActions>
    </Dialog>
  );
}

export default EditEventModal;
