import { Box } from "@mui/material";
import { SearchContainer, PageHeaderTitle, SearchInput, SearchButton, MarginTop40Box, FilterDropdown, TabSearchContainer } from "suregifts-component-lib";

type SearchSectionProps = {
  filter: any;
  handleFilter: (params: any) => void;
  total?: number;
};

export const FilterSection = ({ filter, handleFilter, total }: SearchSectionProps) => {
  return (
    <MarginTop40Box>
      <TabSearchContainer
        tabs={[
          { label: "Order", value: "/orders/vouchers" },
          { label: "Bill Payments", value: "/orders/bills" },
        ]}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <FilterDropdown value={filter.type} onChange={(index, value) => handleFilter({ type: value, page: 1 })} display="Type" options={[{ text: "All" }, { text: "Airtime", value: 5 }, { text: "Bills", value: 6 }]} />
          <SearchInput value={filter.search} onChange={(e) => handleFilter({ search: e, page: 1 })} />
          <SearchButton label="Export" />
        </Box>
      </TabSearchContainer>
    </MarginTop40Box>
  );
};
