import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PinModal from "./PinModal";
import { useState } from "react";
import { useVoucherDownloadContext } from "../VoucherDownloadContext";
import { ReviewBoxView } from "../../../components/molecules/ReviewBoxView";
import { OrderAmountSummaryView } from "../../../components/organisms/OrderAmountSummaryView";
import { usePlaceDownloadOrder } from "../../../services/order";
import { SubmitOrderView } from "../../../components/organisms/SubmitOrderView";
type ReviewMainViewProps = {};
function ReviewMainView({}: ReviewMainViewProps) {
  const { state, updateState } = useVoucherDownloadContext();
  const navigate = useNavigate();
  const [openPin, setOpenPin] = useState(false);
  const { mutate: placeOrder, isLoading: isSending } = usePlaceDownloadOrder();
  const handleSubmit = (pin) => {
    setOpenPin(false);

    placeOrder(
      { ...state, pin },
      {
        onSuccess: () => {
          toast.success("Order submited successfully.");
          navigate("/orders/vouchers");
        },
      }
    );
  };

  return (
    <Box
      sx={(theme) => ({
        marginTop: "1.875rem",
        display: "grid",
        gap: "3.75rem",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "1fr 350px",
        },
      })}
    >
      <div style={{ borderRadius: "20px", overflow: "clip", alignSelf: "start" }}>
        <ReviewBoxView dt={"Merchant"} dd={state.giftName} onEditClick={() => updateState({ tabValue: 0 } as any)} />
        <ReviewBoxView dt={"No of Vouchers"} dd={`${state.numberOfvouchers} Voucher(s)`} onEditClick={() => updateState({ tabValue: 0 } as any)} />
      </div>
      <div>
        <OrderAmountSummaryView model={state} handleChange={updateState} />
        <SubmitOrderView handleSubmit={() => setOpenPin(true)} isSending={isSending} />
        {openPin && <PinModal open={openPin} handleClose={() => setOpenPin(false)} submitClick={handleSubmit} />}
      </div>
    </Box>
  );
}

export default ReviewMainView;
