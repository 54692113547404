import { Card, Divider, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import moment from "moment";
import { FormButton, OrderStatusLabel, OrderStatusType, PageHeaderTitle, TitleDetailView, TransactionStatusLabel } from "suregifts-component-lib";
import { BillOrderInfo } from "../../../../services/utilities";

export function UtilityInfoSection({ data, refetch }: { data?: BillOrderInfo; refetch: () => void }) {
  const { orderId } = useParams();

  return (
    <div>
      <PageHeaderTitle title="Order Details" />
      <Card variant="outlined" sx={{ p: "1.85rem", mt: "1.25rem", bgcolor: "primary.main", boxShadow: 2, borderRadius: "1.25rem" }}>
        <Stack spacing={"1.85rem"}>
          <TitleDetailView title="Date Created" detail={moment(data?.dateCreated).format("DD.MM.YYYY-hh:mma")} />

          <TitleDetailView title="Total Value" detail={data?.subTotal} />
          <TitleDetailView title="Utility Type" detail={data?.billType} />
        </Stack>
        <Divider sx={{ my: "2.5rem" }} />
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Order Status" detail={<OrderStatusLabel label={data?.status} status={data?.statusId} />} />
          </Stack>
          {/* {data?.statusId !== OrderStatusType.Canceled && (
            <Stack spacing={2} direction={"row"}>
              {(data?.statusId === OrderStatusType.Delivered || data?.statusId === OrderStatusType.PartiallyDelivered) && <FormButton sx={{ px: "0.125rem" }} onClick={handleResend} fullWidth label={"Resend Order"} color="success" />}
              <FormButton onClick={() => setOpenCancel(true)} sx={{ px: "0.125rem", color: "error.main" }} fullWidth label={"Cancel"} color="primary" />
            </Stack>
          )} */}
        </Stack>
      </Card>
    </div>
  );
}
