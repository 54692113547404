import { createContext, useContext, useEffect, useState } from "react";
import { initialData, useGetInitialData } from "../services/general";

type GlobalContextValueProps = {
  initialSetting?: initialData;
};
const GlobalContext = createContext({} as GlobalContextValueProps);

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export const INITIAL_SETTINGS: string = "INITIAL_SETTINGS";

export const GlobalContextProvider = ({ children }) => {
  const [initialSetting, setInitialSetting] = useState(() => {
    const d = localStorage.getItem(INITIAL_SETTINGS);
    if (!d) return undefined;
    return JSON.parse(d) as initialData;
  });
  const { mutate: getInitialState } = useGetInitialData();

  useEffect(() => {
    getInitialState(
      {},
      {
        onSuccess: (data) => {
          localStorage.setItem(INITIAL_SETTINGS, JSON.stringify(data));
          setInitialSetting(data);
        },
      }
    );
  }, []);

  return (
    <>
      <GlobalContext.Provider value={{ initialSetting }}>{children}</GlobalContext.Provider>
    </>
  );
};
