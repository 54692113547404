import { TabSearchContainer, useDocumentTitle } from "suregifts-component-lib";
import { DiscountAnalyticsScreen } from "./statistics/DiscountAnalyticsScreen";
import { SummarySection } from "./SummarySection";
import { DiscountEmployeeListScreen } from "./employee-views/EmployeeListSection";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDiscountContext } from "../DiscountContext";
import { ExpiryWarningView } from "./ExpiryWarningView";
import { useQueryClient } from "react-query";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function DiscountIndexScreen() {
  useDocumentTitle("Staff Discounts");
  const [tab, setTab] = useState<"index" | "employees">("index");
  const { clientPlan, isLoadingPlan } = useDiscountContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    queryClient.invalidateQueries("discount-client-plan");
  }, []);
  useEffect(() => {
    if (!isLoadingPlan && !clientPlan) {
      navigate("/dashboard");
    }
  }, [isLoadingPlan]);

  if (isLoadingPlan && !clientPlan) {
    return <></>;
  }
  return (
    <div>
      <div style={{ marginTop: "3.125rem" }}>
        <TabSearchContainer
          value={pathname}
          onChange={(value) => {
            navigate(value);
          }}
          tabs={[
            { label: "Staff Discount Overview", value: "/discounts/index" },
            {
              label: "View Employees",
              value: "/discounts/employees",
            },
          ]}
        >
          <></>
        </TabSearchContainer>
      </div>
      <div style={{ marginTop: "3.125rem", display: "grid", gap: "1.5rem" }}>
        {clientPlan?.canRenew && <ExpiryWarningView />}
        <SummarySection />
      </div>
      <div style={{ marginTop: "3.125rem" }}>
        <Outlet />
      </div>
    </div>
  );
}
export default DiscountIndexScreen;
