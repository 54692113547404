import { TableRow, TableCell } from "@mui/material";
import { PageHeaderTitle, MainTable, TableBody } from "suregifts-component-lib";
import { TopRecipientDto } from "../../services/general";

type TopRecipientSectionProps = {
  data?: TopRecipientDto[];
};
export const TopRecipientSection = ({ data }: TopRecipientSectionProps) => {
  return (
    <div>
      <PageHeaderTitle title={"Top Recipients"} />
      <MainTable>
        <TableBody>
          {data?.map((item, i) => (
            <TableRow key={`${item.name}-${i}`}>
              <TableCell sx={{ color: "#AAAABE" }}>{item.name}</TableCell>
              <TableCell>{item.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
    </div>
  );
};
