import { SxProps } from "@mui/material";
import { GiftInfoView } from "./GiftInfoView";
import { useVoucherRequestContext } from "../VoucherRequestContext";
import { GiftDetailsContainer } from "./GiftDetailsContainer";
type OrderDetailsViewProps = {};
export const layoutStyle: SxProps = (theme: any) => ({
  marginTop: "1.875rem",
  display: "grid",
  gap: "3.75rem",
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "1fr 280px",
  },
});
function OrderDetailsTabView({}: OrderDetailsViewProps) {
  const { state, updateState } = useVoucherRequestContext();

  return (
    <>
      <GiftDetailsContainer disableNext={!(state.narration && state.voucherValue)} updateState={updateState}>
        <GiftInfoView />
      </GiftDetailsContainer>
    </>
  );
}

export default OrderDetailsTabView;
