import { DatePicker, FormInput, GiftPriceView, TimePicker } from "suregifts-component-lib";
import { DeliveryMethodView } from "./DeliveryMethodView";
import { useGetGiftInfo } from "../../../services/order";
import { Stack } from "@mui/material";
import { useVoucherRequestContext } from "../VoucherRequestContext";
import moment, { duration } from "moment";
import { useEffect } from "react";

type GiftInfoViewProps = {};
export const GiftInfoView = ({}: GiftInfoViewProps) => {
  const { state: model, updateState: handleChange } = useVoucherRequestContext();
  const { data: gift } = useGetGiftInfo(model.giftId);
  return (
    <div>
      {gift && <GiftPriceView gift={gift} onChange={(value) => handleChange({ voucherValue: value } as any)} value={model.voucherValue} />}
      <Stack spacing={2} sx={{ marginTop: "30px" }}>
        <ScheduleSection />

        <FormInput label="Narration" value={model.narration} onChange={(e) => handleChange({ narration: e.target.value } as any)} />
      </Stack>
    </div>
  );
};
const ScheduleSection = () => {
  const { state: model, updateState: handleChange } = useVoucherRequestContext();

  return (
    <>
      <DeliveryMethodView />
      {model.schedule && (
        <Stack spacing={2} direction={"row"}>
          <DatePicker disabled={!!model.eventId} value={model.deliveryDate} onChange={(e) => handleChange({ deliveryDate: e } as any)} />
          <TimePicker value={model.deliveryTime} onChange={(e) => handleChange({ deliveryTime: e } as any)} />
        </Stack>
      )}
    </>
  );
};
