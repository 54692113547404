import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";

function AuthScreen() {
  return (
    <Container
      sx={{
        height: "100vh",
        display: "grid",
        gridTemplateColumns: "3fr 2fr",
      }}
    >
      <Box
        sx={{
          height: "100vh",
          fontSize: "0",
          padding: "36px",
          "& img": {
            height: "100%",
            width: "100%",

            objectFit: "cover",
            borderRadius: "24px",
            overflow: "hidden",
          },
        }}
      >
        <img src="/assets/images/login.png" alt="" />
      </Box>
      <div style={{ height: "100vh", overflow: "scroll", scrollbarWidth: "none" }}>
        <div style={{ padding: "2.5rem 0", display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <img src="/assets/images/logo.png" style={{ alignSelf: "start" }} />
          <Outlet />
        </div>
      </div>
    </Container>
  );
}

export default AuthScreen;
