import { Box, IconButton } from "@mui/material";
import { EditPencilIcon, MarginTop40Box, SearchButton, SearchInput, TabSearchContainer } from "suregifts-component-lib";
import { useRecipientContext } from "./RecipientContext";
import { StorageKeys } from "../../routes/RequireAuth";
import { useLocation, useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useMemo } from "react";

function RecipientFilterContainer({ children }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tab = useMemo(() => {
    return pathname.startsWith("/recipients/groups") ? 0 : 1;
  }, [pathname]);
  return (
    <TabSearchContainer
      value={tab}
      onChange={(value) => {
        if (value === 0) {
          navigate("/recipients/groups");
        } else if (value === 1) {
          navigate("/recipients/vouchers");
        }
      }}
      tabs={[
        { label: "Groups", value: 0 },
        {
          label: "Recipients",
          value: 1,
        },
      ]}
    >
      {children}
    </TabSearchContainer>
  );
}

export default RecipientFilterContainer;
