import { Box, Typography, Stack, IconButton, useTheme } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FormButton, DeleteIcon, EditPencilIcon, TitleDatailInlineV2 } from "suregifts-component-lib";
import { GroupData } from "../../../services/receipient";
import { StorageKeys } from "../../../routes/RequireAuth";
import Card from "@mui/material/Card/Card";

type GroupCardProps = {
  group: GroupData;
  onDelete: (groupId) => void;
  onClick: () => void;
};
export const GroupCard = ({ group: item, onDelete = () => {}, onClick }: GroupCardProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Card
      sx={{
        position: "relative",
        backgroundColor: "primary.main",
        borderRadius: "1.875rem",
        padding: "1.875rem 1.5625rem 1.5625rem",
        "&:hover ": {
          boxShadow: 1,
          "& .option-container": {
            display: "block",
          },
        },
      }}
    >
      <Typography sx={{ fontSize: "1.25rem", fontWeight: 700 }}>{item.name}</Typography>
      <Stack sx={{ mt: "1.5625rem" }} spacing={"1.25rem"}>
        <TitleDatailInlineV2 dt="Members" dd={item.members} />
        <TitleDatailInlineV2 dt="Date Created" dd={moment(item.dateCreated).format("DD.MM.YYYY")} />
      </Stack>
      <FormButton onClick={onClick} variant="contained" color="primary" label="Send Voucher" fullWidth sx={{ padding: "1.3125rem", marginTop: "2.5rem" }} />
      <Stack className="option-container" direction={"row"} sx={{ display: "none", position: "absolute", top: "18px", right: "12px" }}>
        <IconButton color="error" onClick={() => onDelete(item.id)}>
          <DeleteIcon />
        </IconButton>
        <IconButton onClick={() => navigate(`/recipients/groups/${item.id}`)}>
          <EditPencilIcon />
        </IconButton>
      </Stack>
    </Card>
  );
};
