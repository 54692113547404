import { Box } from "@mui/material";
import React from "react";
type OtherSummaryBoxProps = {
  name;
  value: React.ReactNode;
};
export const OtherSummaryBox = ({ name, value }: OtherSummaryBoxProps) => {
  return (
    <Box
      sx={{
        background: "#1D1C23",
        borderRadius: "24px",
        padding: "20px 30px",
        display: "grid",
        gap: "5px",
        "div.number": {
          fontSize: "30px",
          fontWeight: 700,
          lineHeight: "130%",
        },
        "div.desc": {
          fontSize: "10px",
          textTransform: "uppercase",
          fontWeight: 500,
          lineHeight: "0.4px",
          color: "#AAAABE",
        },
      }}
    >
      <div className="number">{value}</div>
      <div style={{ textTransform: "uppercase" }} className="desc">
        {name}
      </div>
    </Box>
  );
};
export const OtherSummaryBoxV2 = () => {};
