import { Box, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { SearchContainer, PageHeaderTitle, FilterDropdown, MarginTop40Box, useAuthContext, BillCard, useDocumentTitle, Pagination, useFilter, LoadingBox, useGetAirtimes, AirtimeFilter, useGetAirtimeCountries } from "suregifts-component-lib";

function AirtimeListScreen() {
  const { user } = useAuthContext();

  const params = useMemo(() => {
    let countryCode: any = undefined;
    if (user?.countryCode === "NG" || user?.countryCode === "KE") {
      countryCode = user.countryCode;
    }
    return { countryCode: countryCode, page: 1, size: 12 } as AirtimeFilter;
  }, []);
  const [filter, setFilter] = useFilter<AirtimeFilter>(params);
  const { data: countries } = useGetAirtimeCountries();
  const { data: data, isFetching } = useGetAirtimes(filter);
  useDocumentTitle("Airtimes");
  const handleFilter = (props) => {
    setFilter((prev) => ({ ...prev, ...props }));
  };

  const theme = useTheme();

  return (
    <div>
      <SearchContainer>
        <PageHeaderTitle title={"Airtimes"} />
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <FilterDropdown
            value={filter.countryCode}
            options={[{ text: "All" }, ...countries!.map((item) => ({ text: item.name, value: item.id }))]}
            onChange={(index, value) => {
              handleFilter({ countryCode: value, page: 1 });
            }}
            display={"Country"}
          />
        </Box>
      </SearchContainer>
      <LoadingBox
        loading={isFetching}
        sx={{
          marginTop: "40px",
          display: "grid",
          gap: "1.25rem",
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2,1fr)",
          },
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(3,1fr)",
          },
          [theme.breakpoints.up("xl")]: {
            gridTemplateColumns: "repeat(4,1fr)",
          },
        }}
      >
        {data?.items.map((item) => (
          <BillCard categoryName={`Airtime (${item.countries})`} key={item.airtimeId} url={`${item.airtimeId}`} name={item.name} logoUrl={item.logoUrl} />
        ))}
      </LoadingBox>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination
          align="center"
          page={filter.page}
          total={data?.total}
          size={filter.size}
          onChange={(page) => {
            handleFilter({ page });
          }}
        />
      </div>
    </div>
  );
}

export default AirtimeListScreen;
