import { Dialog, DialogContent, DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DialogTitle, FormButton } from "suregifts-component-lib";
import { RecipientCsvFileUploader } from "../group/ButtonUpload";
import { useState } from "react";
import { useUploadRecipients } from "../../../services/receipient";
import { toast } from "react-toastify";

export const RecipientUploadDialog = () => {
  const [fileUrl, setFileUrl] = useState<string>();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/recipients/vouchers");
  };
  const { isLoading, mutate: upload } = useUploadRecipients();
  return (
    <Dialog open onClose={handleClose} maxWidth="xs">
      <DialogTitle title="Upload Recipients" handleClose={handleClose} />
      <DialogContent>
        <RecipientCsvFileUploader onChange={(file) => setFileUrl(file)} />
      </DialogContent>
      <DialogActions>
        <FormButton
          loading={isLoading}
          label="Upload"
          fullWidth
          disabled={!fileUrl}
          onClick={() => {
            upload(fileUrl, {
              onSuccess: () => {
                toast.success("Recipients uploaded successfully.");
                handleClose();
              },
            });
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
