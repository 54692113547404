import { Box, Typography } from "@mui/material";
import { SuccessTickIcon } from "suregifts-component-lib";
import { useGetWalletInfo } from "../../services/transaction";

export const PaymentMethodCard = () => {
  const { data: wallet } = useGetWalletInfo();
  return (
    <Box sx={{ backgroundColor: "primary.main", borderRadius: "1.25rem", border: "0.125rem solid white", padding: "1.25rem 0.9375rem", gap: "0.9375rem", display: "flex", alignItems: "center" }}>
      <Box sx={{ backgroundColor: "primary.dark", padding: "0.75rem", borderRadius: "50%" }}>
        <Box component={"img"} src={"/assets/images/card-payment.png"} />
      </Box>
      <div style={{ flexGrow: 1 }}>
        <Typography sx={{ fontSize: "0.8125rem", opacity: 0.7 }}>Wallet Balance</Typography>
        <Typography>{wallet?.balanceString}</Typography>
      </div>
      <SuccessTickIcon color="success" sx={{ fontSize: "1.8125rem" }} />
    </Box>
  );
};
