import { Button, Link } from "@mui/material";
import { useMemo, useState } from "react";
import { useCookies } from "react-cookie";

import { FileUploader, FormInput } from "suregifts-component-lib";

type RecipientCsvFileUploaderProps = {
  onChange?: (file: any) => void;
};

const baseUrl = (window as any).BASE_URL;
export const RecipientCsvFileUploader = ({ onChange = () => {} }: RecipientCsvFileUploaderProps) => {
  const [label, setLabel] = useState("Click to upload CSV");
  const [cookies] = useCookies(["APPLICATION_MODE"]);

  const handleChange = (files: string | string[] | undefined) => {
    if (typeof files !== "undefined") {
      onChange(files);
      const temp = (Array.isArray(files) ? files : [files]).map((x) => x.replace(/^.*[\\\/]/, ""));
      setLabel(temp.join(", "));
    }
  };
  const applicationMode = useMemo(() => {
    return cookies.APPLICATION_MODE ?? "live";
  }, [cookies.APPLICATION_MODE]);
  return (
    <div>
      <FileUploader
        accept=".csv"
        onChange={handleChange}
        renderControl={({ onClick }) => (
          <FormInput
            onClick={onClick}
            label={
              <div style={{ display: "flex", gap: "10px" }}>
                <span>Upload (.csv file containing list) </span>
                <Link
                  target="_blank"
                  href={`${baseUrl}/${applicationMode}/recipients/sample-recipients`}
                  sx={{
                    textDecoration: "none",
                    color: "success.main",
                  }}
                >
                  Download sample CSV file
                </Link>
              </div>
            }
            value={label}
          />
        )}
      />
    </div>
  );
};
