import { Dialog, DialogContent, Stack } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DialogTitle, TitleDatailInlineV2, TransactionStatusLabel, TransactionStatusType } from "suregifts-component-lib";
import { TransactionData } from "../../services/transaction";

function TransactionViewDialog() {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("..");
  };

  const [item] = useState(() => {
    const data = sessionStorage.getItem("TRANSACTION_DATA");
    if (!data) return {} as TransactionData;

    return JSON.parse(data) as TransactionData;
  });
  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle title={"Transaction Details"} handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={3}>
          <TitleDatailInlineV2 dt={"Status"} dd={<TransactionStatusLabel status={item?.statusId} text={item?.status} />} />
          <TitleDatailInlineV2 dt={"Transaction Type"} dd={item?.transactionType} />
          <TitleDatailInlineV2 dt={"Amount"} dd={item?.amount} />
          {item?.newBalance && <TitleDatailInlineV2 dt={"Wallet Balance"} dd={item?.newBalance} />}
          {item?.previousBalance && <TitleDatailInlineV2 dt={"Previous Balance"} dd={item?.previousBalance} />}
          {item.extras && Object.keys(item!.extras).map((x) => <TitleDatailInlineV2 dt={x} dd={item?.extras[x]} />)}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default TransactionViewDialog;
