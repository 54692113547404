import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { DialogTitle, FormButton, FormInput, YupShape } from "suregifts-component-lib";
import { AddRecipientModel, EditRecipientModel, RecipientData, useAddStaff, useEditStaff } from "../../../../services/discount";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useState } from "react";

export const schema = yup
  .object<YupShape<EditRecipientModel>>({
    name: yup.string().required(),
    email: yup.string().required().email(),
  })
  .required();

export const EditStaffModal = ({ handleClose = () => {} }) => {
  const [recipient] = useState(() => {
    const data = sessionStorage.getItem("RECIPIENT_DATA");
    if (!data) return {} as RecipientData;

    return JSON.parse(data) as RecipientData;
  });
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
  } = useForm<EditRecipientModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: recipient.email,
      name: recipient.name,
      phone: recipient.phone,
    },
  });
  const queryClient = useQueryClient();
  const { mutate: editStaff, isLoading } = useEditStaff(recipient.id);

  const submitForm = (model) => {
    editStaff(model, {
      onSuccess: () => {
        toast.success("Staff edited successfully.");
        handleClose();
        queryClient.invalidateQueries("discounts-recipients");
      },
    });
  };
  return (
    <Dialog open maxWidth="xs" onClose={handleClose}>
      <DialogTitle title="Edit Staff" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput label="Full Name" helperText={errors.name?.message} {...register("name")} />
          <FormInput label="Email" helperText={errors.email?.message} {...register("email")} />
          <FormInput label="Phone Number" {...register("phone")} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton label="Save" loading={isLoading} fullWidth disabled={!isValid} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
};
