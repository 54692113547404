import { toast } from "react-toastify";
import { RecipientData, useEditGroupRecipient } from "../../services/receipient";
import { useNavigate, useParams } from "react-router-dom";
import { RecipientDialog } from "../recipients/recipient/RecipientDialog";
import { useState } from "react";
import { useQueryClient } from "react-query";

export function EditGroupRecipientModal() {
  const { groupId } = useParams();
  const { mutate: editRecipient, isLoading: editting } = useEditGroupRecipient(groupId);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [recipient] = useState(() => {
    const data = sessionStorage.getItem("RECIPIENT_DATA");
    if (!data) return {} as RecipientData;

    return JSON.parse(data) as RecipientData;
  });

  const submitForm = (data) => {
    editRecipient(
      { ...data, id: recipient!.id },
      {
        onSuccess: () => {
          toast.success("Recipient updated successfully.");
          queryClient.invalidateQueries("groupById");
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    navigate("..");
  };
  return <RecipientDialog handleClose={handleClose} onSubmit={submitForm} title="Edit Recipient" buttonTitle={"Edit"} recipient={recipient} />;
}
