import { IconButton, Box, useTheme } from "@mui/material";
import { BellIcon, FilterDropdown, FormButton, SystemModeView } from "suregifts-component-lib";
import { useNavigate } from "react-router-dom";
import { useBusinessContext } from "../../contexts/BusinessContext";

type MainLayoutTopComponentProps = {
  open: boolean;
  openClicked?: () => void;
};

export const MainLayoutTopComponent = ({}: MainLayoutTopComponentProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box sx={{ marginY: "1.875rem", display: "flex", gap: "1.875rem", alignItems: "center" }}>
      <CompanyLogoView />
      <div style={{ flexGrow: 1 }}></div>
      <SystemModeView />
      <FormButton
        color={theme.palette.mode === "dark" ? "secondary" : "success"}
        label="Fund Wallet"
        sx={{ padding: "1rem 2.25rem", width: "auto" }}
        onClick={() => navigate("/wallet")}
      />
      <BuyVoucherFilterView />
      <IconButton>
        <BellIcon />
      </IconButton>
    </Box>
  );
};
const BuyVoucherFilterView = () => {
  const navigate = useNavigate();
  const handleChange = (index) => {
    if (index === 0) {
      navigate("/recipients/groups");
    } else if (index === 1) {
      navigate("/recipients/vouchers");
    } else {
      navigate("/order-request-download");
    }
  };
  return (
    <FilterDropdown
      color="secondary"
      display={"Send Voucher"}
      options={[{ text: "Group Voucher" }, { text: "Individual Voucher" }, { text: "Bulk Download" }]}
      onChange={handleChange}
    />
  );
};

const CompanyLogoView = () => {
  const { bizInfo } = useBusinessContext();
  return (
    <Box
      component={"img"}
      sx={{
        height: "3.125rem",
        width: "3.125rem",
        borderRadius: "1.125rem",
        background:
          "conic-gradient(from 151.11deg at 50% 50%, #CC9AC7 0deg, #A082F4 13.37deg, #A6E8F7 106.88deg, #FFF5DD 204.37deg, #FFF4D9 305.62deg, #CC9AC7 360deg)",
        objectFit: "contain",
      }}
      src={bizInfo?.logo}
    ></Box>
  );
};
