import { Box, Link, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
export const links = [
  { text: "Solutions", href: "#solutions" },
  { text: "Clients", href: "#clients" },
  { text: "Testimonials", href: "#testimonials" },
];
export const NavigationSection = () => {
  const theme = useTheme();

  return (
    <Box className="nav" component={"nav"}>
      <ul style={{ display: "flex", gap: "0.125rem", listStyle: "none", margin: 0, padding: 0, fontSize: "0.875rem", fontWeight: 500, lineHeight: "1.6875rem" }}>
        {links.map((link) => (
          <li key={link.text}>
            <Box
              component={Link}
              sx={(theme) => ({
                textDecoration: "none",
                color: theme.palette.text.primary,
                padding: "1rem",
                borderRadius: "1rem",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              })}
              href={link.href}
            >
              {link.text}
            </Box>
          </li>
        ))}
      </ul>
    </Box>
  );
};
