import { useEffect } from "react";

import { BillFormSection, LoadingBox, PageHeaderTitle, TransactionType, WalletType, useDocumentTitle, useUtilityContent } from "suregifts-component-lib";
import { Box } from "@mui/material";

import { SummaryView } from "../../SummaryView";
import { PaymentMethodView } from "../../PaymentMethodView";
import { useGetWalletInfo } from "../../../../services/transaction";

function BillPaymentScreen() {
  const { data: wallet } = useGetWalletInfo();
  const {
    form: { setValue },
    bill: biller,
    amountSummary,
  } = useUtilityContent();

  useEffect(() => {
    setValue("billTypeId", TransactionType.BillsPayment);
    setValue("paymentMethodId", WalletType.CashWallet);
  }, []);

  useDocumentTitle("Bills Payment");

  if (!biller || !wallet) {
    return <LoadingBox loading></LoadingBox>;
  }

  return (
    <LoadingBox>
      <PageHeaderTitle title="Bills" />
      <Box
        sx={(theme) => ({
          display: "grid",
          marginTop: "40px",
          gap: "3.125rem",
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "2fr 1fr",
          },
        })}
      >
        <div>
          <BillFormSection />
          <PaymentMethodView wallet={wallet} totalAmount={amountSummary?.amountPayable || 0} />
        </div>
        <SummaryView />
      </Box>
    </LoadingBox>
  );
}

export default BillPaymentScreen;
