import { LoadingBox } from "suregifts-component-lib";
import { CompanyCard } from "./CompanyCard";
import { BalanceCard } from "./BalanceCard";

function WalletInfoSection() {
  return (
    <LoadingBox
      sx={(theme) => ({
        display: "grid",
        gap: "1.875rem",
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "minmax(320px,1fr) 5fr",
        },
      })}
    >
      <CompanyCard />
      <BalanceCard />
    </LoadingBox>
  );
}

export default WalletInfoSection;
