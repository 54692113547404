import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { FormInput, TabPanel } from "suregifts-component-lib";
import ContactDetailsView from "./ContactDetailsView";
import CompanyDetailsView from "./CompanyDetailsView";
import { Outlet } from "react-router-dom";

function SettingScreen() {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (e, newValue: number) => {
    setTabValue(newValue);
  };
  return (
    <div style={{ maxWidth: "34.374rem", marginInline: "auto" }}>
      <div>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          sx={{
            "& .MuiTab-root": {
              fontSize: "1.5rem",
              fontWeight: 700,
              "&.Mui-selected": {
                color: "secondary.main",
              },
            },
          }}
        >
          <Tab sx={{ paddingLeft: "0" }} label="Company Details" value={0} />
          <Tab label="Contact Details" value={1} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <CompanyDetailsView />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ContactDetailsView />
        </TabPanel>
      </div>
      <Outlet />
    </div>
  );
}

export default SettingScreen;
