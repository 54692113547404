import React from "react";
import FooterSection from "./footer/FooterSection";
import HeaderSection from "./header/HeaderSection";
import { Outlet } from "react-router-dom";

function LandingScreen() {
  return (
    <>
      <HeaderSection />
      <Outlet />
      <FooterSection />
    </>
  );
}

export default LandingScreen;
