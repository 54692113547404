import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { router } from "./routes/routes";
import { AuthContextProvider, NotificationContextProvider, ThemeContextProvider } from "suregifts-component-lib";
import { CookiesProvider } from "react-cookie";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <>
      <ToastContainer />
      <CookiesProvider>
        <ThemeContextProvider>
          <QueryClientProvider client={queryClient}>
            <NotificationContextProvider>
              <AuthContextProvider>
                <RouterProvider router={router} />
              </AuthContextProvider>
            </NotificationContextProvider>
          </QueryClientProvider>
        </ThemeContextProvider>
      </CookiesProvider>
    </>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
