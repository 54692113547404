import { Box, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { CustomSearchInput, FilterDropdown, FilterElement, SearchIcon } from "suregifts-component-lib";

import Card from "@mui/material/Card";
import { useGetCategories, useGetCountries } from "../../../services/order";

function FilterSection({ handleFilter, filter }) {
  const { data: countryNames } = useGetCountries();
  const { data: categoryNames } = useGetCategories();
  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundColor: "primary.main",
        padding: "16px",
        borderRadius: "30px",
        display: "grid",
        gap: "24px",
        "& button.MuiButton-root.MuiButton-containedPrimary": {
          bgcolor: "primary.dark",
        },
        [theme.breakpoints.up("sm")]: {
          gridTemplateColumns: "repeat(3,1fr)",
        },
      }}
    >
      <FilterElement
        render={() => (
          <FilterDropdown
            fullWidth
            display={"All Countries"}
            onChange={(index, value) => handleFilter({ countryCode: value })}
            value={filter.countryCode}
            options={[{ text: "All" }, ...(countryNames?.map((x) => ({ text: x.name, value: x.code })) || [])]}
            variant="contained"
            color="primary"
            colorLevel="dark"
          />
        )}
      />

      <FilterElement
        render={() => (
          <FilterDropdown
            onChange={(index, value) => handleFilter({ categoryId: value })}
            fullWidth
            value={filter.categoryId}
            options={[{ text: "All" }, ...(categoryNames?.map((x) => ({ text: x.name, value: x.id })) ?? [])]}
            display={"All Categories"}
            variant="contained"
            color="primary"
            colorLevel="dark"
          />
        )}
      />
      <Box sx={{}}>
        <FilterElement render={() => <CustomSearchInput value={filter.search} onChange={(e) => handleFilter({ search: e })} startAdornment={<SearchIcon />} />} />
      </Box>
    </Card>
  );
}

export default FilterSection;
