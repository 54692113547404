import { Stack, Typography, FormControlLabel, Radio, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { WalletInfoData, FormInput } from "suregifts-component-lib";

type PaymentMethodViewProps = {
  wallet: WalletInfoData;
  totalAmount: number;
};

export const PaymentMethodView = ({ wallet, totalAmount }: PaymentMethodViewProps) => {
  const theme = useTheme();
  return (
    <>
      <Stack spacing={3} marginTop="1.875rem">
        <Typography fontSize={"1.25rem"} sx={{ fontWeight: 700 }}>
          Payment Method
        </Typography>
        <FormControlLabel sx={{ color: "#AAAABE" }} checked={true} control={<Radio color="success" />} label="Wallet" />
        <FormInput label="Balance" value={`Balance: ${wallet?.balanceString}`} />
      </Stack>
      {wallet?.balance < totalAmount && (
        <p style={{ color: "#AAAABE" }}>
          Insufficient Balance?{" "}
          <Link to={"/wallet"} style={{ color: theme.palette.success.main, textTransform: "none", textDecoration: "none" }}>
            Fund Wallet
          </Link>
        </p>
      )}
    </>
  );
};
