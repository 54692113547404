import { useEffect, useState, useCallback, createContext, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { PlaceOrderModel, useGetGiftInfo } from "../../services/order";
import { StorageKeys } from "../../routes/RequireAuth";
import moment from "moment";
import VoucherRequestScreen from "./VoucherRequestScreen";

const defaultValue = {
  voucherValue: "",
  deliveryTime: moment().toISOString(),
  tabValue: 0,
  couponCode: "",
};
type VoucherRequestContextProps = {
  state: PlaceOrderModel;
  updateState: (params: PlaceOrderModel) => void;
};
const VoucherRequestContext = createContext({} as VoucherRequestContextProps);

export const VoucherRequestContextProvider = ({}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!sessionStorage.getItem(StorageKeys.RECIPIENT_VOUCHERS)) {
      navigate("/recipients/vouchers");
    }
  }, []);

  const [state, setModel] = useState(() => {
    const data: any = sessionStorage.getItem(StorageKeys.RECIPIENT_VOUCHERS);
    if (!data) {
      navigate("/recipients/vouchers");
      return {} as PlaceOrderModel;
    }

    var dataObj = JSON.parse(data) as PlaceOrderModel;

    var model = {
      schedule: false,
      narration: "",
      deliveryDate: moment().toISOString(),
      deliveryTime: moment().toISOString(),
      numberOfvouchers: "0",
      recipients: [],
      couponCode: "",
      tabValue: 0,
      voucherValue: "",
    };

    return { ...model, ...dataObj };
  });

  const updateState = useCallback((params: PlaceOrderModel) => setModel((prev) => ({ ...prev, ...params })), [state.giftId]);
  useGetGiftInfo(state.giftId, (gift) => {
    const amount = gift.denominations.length > 0 ? 0 : gift.minAmount;
    setModel((prev) => ({ ...prev, voucherValue: amount }));
  });
  return (
    <VoucherRequestContext.Provider
      value={{
        state,
        updateState,
      }}
    >
      <VoucherRequestScreen />
    </VoucherRequestContext.Provider>
  );
};

export const useVoucherRequestContext = () => {
  return useContext(VoucherRequestContext);
};
