import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useMemo, useState } from "react";
import { DialogTitle, FormButton, FormInput, YupShape, getPaymentPath, useAuthContext } from "suregifts-component-lib";

import { useForm } from "react-hook-form";
import { FundingMethodData, FundingModel, useFundWallet } from "../../services/transaction";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import useNavigator from "../../hooks/useNavigator";
declare var location;
const schema = yup
  .object<YupShape<FundingModel>>({
    amount: yup.number().required(),
  })
  .required();
function FundingModal() {
  const navigate = useNavigator();
  const { user } = useAuthContext();
  const { currency } = user!;
  const handleClose = () => {
    navigate("..");
  };

  const [paymentMethod] = useState(() => {
    const data = sessionStorage.getItem("PAYMENT_METHOD");
    if (!data) return {} as FundingMethodData;

    return JSON.parse(data) as FundingMethodData;
  });
  const { mutate: fundWallet, isLoading } = useFundWallet();
  const returnPath = useMemo(() => getPaymentPath(paymentMethod!.id), []);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FundingModel>({
    defaultValues: {
      paymentMethod: paymentMethod?.id,
      returnPath: `payment-callback/${returnPath}`,
    },
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();
  const submitForm = (data) => {
    fundWallet(data, {
      onSuccess: (result) => {
        if (result.paymentLink) {
          location.href = result.paymentLink;
        } else {
          toast.success("Wallet funding successful");
          queryClient.invalidateQueries("wallet-info");
          queryClient.invalidateQueries("transactions");
          handleClose();
        }
      },
    });
  };

  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title={`${paymentMethod?.name} Payment`} handleClose={handleClose} />
      <DialogContent>
        <FormInput
          {...register("amount")}
          label="Enter Amount"
          startAdornment={
            <Box component={"span"} sx={{ p: "0.25rem", backgroundColor: "primary.light", borderRadius: "0.1875rem" }}>
              {currency}
            </Box>
          }
        />
      </DialogContent>
      <DialogActions>
        <FormButton fullWidth loading={isLoading} label={`Pay with ${paymentMethod?.name}`} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default FundingModal;
