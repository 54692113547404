import { Box, Dialog, DialogContent, Stack, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";

export const DiscountIntroModal = ({ open, hide = () => {}, start = () => {} }) => {
  const theme = useTheme();
  const naviate = useNavigate();
  return (
    <Dialog
      open={open}
      sx={{
        ".MuiPaper-root": {
          padding: "0",
          maxWidth: "758px",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "grid",
          alignItems: "center",
          minHeight: "455px",
          [theme.breakpoints.up("md")]: { gridTemplateColumns: "1fr 1fr" },
        }}
      >
        <Box
          className="logo"
          sx={{
            display: "none",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#1D1C22",
            height: "100%",
            [theme.breakpoints.up("md")]: { display: "flex" },
          }}
        >
          <img src="/assets/images/discounts/logo-lg.png" alt="Discount Logo" />
        </Box>
        <Box
          sx={{
            padding: "2rem",
            height: "100%",
            pt: "90px",
            h1: {
              fontSize: "2rem",
              fontWeight: 600,
              color: "#fff",
            },
            p: {
              color: "#8C8C9D",
            },
            position: "relative",
          }}
        >
          <h1>Staff Discount</h1>
          <p>Make your employees happy by giving the opportunity to shop at a big discount and save more on shopping.</p>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{
              button: {
                whiteSpace: "nowrap",
              },
              position: "absolute",
              left: "2rem",
              right: "2rem",
              bottom: "2rem",
            }}
          >
            <FormButton label="Cancel" fullWidth color="secondary" onClick={hide} />
            <FormButton label="Get Started" fullWidth color="success" onClick={start} />
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
