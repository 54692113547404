import { FundButton, WalletType } from "suregifts-component-lib";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { useGetFundingMethods } from "../../services/transaction";

export const PaymentMethodView = ({ methodId, methodChanged }) => {
  const { data } = useGetFundingMethods();
  const methods = useMemo(() => {
    const cash = [{ id: WalletType.CashWallet, name: "Cash Wallet" }];
    if (!data) return cash;
    return cash.concat(data);
  }, [data]);
  return (
    <Stack spacing={2}>
      {methods.map((item) => (
        <FundButton key={item.id} selected={item.id == methodId} onClick={() => methodChanged(item.id)} method={item} />
      ))}
    </Stack>
  );
};
