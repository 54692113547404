import { Box, Typography, Button } from "@mui/material";
import { BusinessData } from "../../services/business";

type BusinessCardProps = {
  business: BusinessData;
  onClick: () => void;
};
export const BusinessCard = ({ business, onClick }: BusinessCardProps) => {
  return (
    <Box
      sx={{
        p: "3.75rem",
        backgroundColor: "rgba(255, 254, 251, 0.01)",
        border: "0.0625rem solid ",
        borderLeftColor: "#28272c",
        borderTopColor: "#28272c",
        borderBottomColor: "#807f82",
        borderRightColor: "#807f82",
        backdropFilter: "blur(1.25rem)",
        borderRadius: "3.125rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Typography
        style={{
          fontWeight: 700,
          fontSize: "1.25rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {business.name}
      </Typography>
      <Typography sx={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.5)", mt: "8px" }}>{business.unit}</Typography>
      <Typography sx={{ fontWeight: 400, color: "text.secondary", mt: "8px", fontSize: "12px" }}>{business.email}</Typography>
      <Button onClick={onClick} sx={{ padding: " 1rem 2.9375rem", fontSize: "0.875rem", borderRadius: "1.125rem", mt: "1.875rem" }} color="primary" variant="contained" disabled={!business.isActive}>
        Go to dashboard
      </Button>
    </Box>
  );
};
