import Box from "@mui/material/Box";
import { useVoucherRequestContext } from "../VoucherRequestContext";
import { PlaceOrderModel } from "../../../services/order";
import { DialogTitle, TemplateContextProdiver, TemplateEditForm, TemplateEditorView, TemplateFilterSection, TemplateLayoutView } from "suregifts-component-lib";
import { useState } from "react";
import { TemplateSelectorView } from "./TemplateSelectorView";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";
import { EditTemplateModal } from "./EditTemplateModal";

type TemplateTabViewProps = {};

export const TemplateTabView = ({}: TemplateTabViewProps) => {
  const { updateState } = useVoucherRequestContext();
  const onComplete = (templateId, imageUrl) => {
    updateState({ tabValue: 2, templateId, imageUrl } as PlaceOrderModel);
  };
  const [editMode, setEditMode] = useState(false);
  return (
    <TemplateContextProdiver isOrder onComplete={onComplete}>
      <TemplateSelectorView onComplete={onComplete} edit={() => setEditMode(true)} />
      {editMode && (
        <EditTemplateModal
          onClose={() => {
            setEditMode(false);
          }}
        />
      )}
    </TemplateContextProdiver>
  );
};
