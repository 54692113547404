import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { DialogTitle, FormButton } from "suregifts-component-lib";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddGroupRecipientModel, useAddGroupRecipients } from "../../services/receipient";
import { useNavigate, useParams } from "react-router-dom";
import { GroupUploadView } from "../recipients/group/GroupUploadView";
import { useQueryClient } from "react-query";

function AddGroupRecipientModal() {
  const { groupId } = useParams();
  const { mutate: addRecipients, isLoading } = useAddGroupRecipients(groupId);
  const { control, handleSubmit, resetField, watch } = useForm<AddGroupRecipientModel>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const submitForm = (data) => {
    addRecipients(data, {
      onSuccess: () => {
        toast.success("Recipients added successfully.");
        queryClient.invalidateQueries("groupById");
        handleClose();
      },
    });
  };
  const handleClose = () => {
    navigate("..");
  };

  return (
    <Dialog open maxWidth="xs">
      <DialogTitle title="Add Recipients " handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <GroupUploadView resetField={resetField} control={control} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton label="Add" fullWidth loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default AddGroupRecipientModal;
