import { Dialog, DialogContent, Box } from "@mui/material";
import { Count, DialogTitle, GiftCardData, Pagination } from "suregifts-component-lib";
import { GiftCardFilter } from "../../../services/order";
import FilterSection from "./FilterSection";

type SelectVoucherModalProps = {
  cards: Count<GiftCardData>;
  onCardSelected?: (data: GiftCardData) => void;
  filter: GiftCardFilter;
  onClose?: () => void;
  handleFilter: (params) => void;
};
export const SelectVoucherModal = ({ cards, filter, onClose = () => {}, onCardSelected = () => {}, handleFilter }: SelectVoucherModalProps) => {
  return (
    <Dialog open maxWidth="md" onClose={onClose}>
      <DialogTitle title="Select Voucher" handleClose={onClose} />
      <DialogContent>
        <div style={{ display: "grid", gap: "20px" }}>
          <FilterSection filter={filter} handleFilter={handleFilter} />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3,1fr)",
              gap: "20px",
            }}
          >
            {cards.items.map((item) => (
              <div style={{ cursor: "pointer" }} key={item.giftId} onClick={() => onCardSelected(item)}>
                <Box
                  component={"img"}
                  src={item.imageUrl}
                  sx={{
                    width: "100%",
                    maxWidth: "260px",
                    height: "166px",
                    maxHeight: "166px",
                    borderRadius: "16px",
                    overflow: "hidden",
                    objectFit: "cover",
                  }}
                />
              </div>
            ))}
          </Box>
          <Pagination
            align="center"
            page={filter.page}
            size={filter.size}
            total={cards?.total}
            onChange={(page) => {
              handleFilter({ page });
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
