import { useVoucherDownloadContext } from "../VoucherDownloadContext";
import { GiftInfoView } from "./GiftInfoView";
import { GiftDetailsContainer } from "../../order-request/details/GiftDetailsContainer";
type OrderDetailsViewProps = {};

function OrderInfoView({}: OrderDetailsViewProps) {
  const { state, updateState } = useVoucherDownloadContext();
  return (
    <GiftDetailsContainer disableNext={!(state.numberOfvouchers && state.voucherValue)} updateState={updateState}>
      <GiftInfoView />
    </GiftDetailsContainer>
  );
}

export default OrderInfoView;
