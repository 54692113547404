import { useQuery, useMutation } from "react-query";
import { ServerError, useAxios } from "suregifts-component-lib";
import { RecipientData } from "./receipient";
export const useGetEvents = () => {
  const axios = useAxios();
  return useQuery<EventData[], ServerError>({
    queryKey: ["events"],
    keepPreviousData: true,
    initialData: [],
    queryFn: () => axios.get("/recipients/api/events", {}),
  });
};
export const useGetEventById = (eventId) => {
  const axios = useAxios();
  return useQuery<EventInfoData, ServerError>({
    queryKey: ["eventById", { eventId }],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    queryFn: () => axios.get(`/recipients/api/events/${eventId}`, {}),
  });
};
export const useAddEvent = () => {
  var axios = useAxios();
  return useMutation<EventInfoData, ServerError, AddEventModel>((model) => {
    return axios.post("/recipients/api/events", model);
  });
};
export const useEditEvent = (eventId: any) => {
  var axios = useAxios();
  return useMutation<EventInfoData, ServerError, EditEventModel>((model) => {
    return axios.post(`/recipients/api/events/${eventId}`, model);
  });
};
export const useDeleteEvent = () => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, any>((eventId) => {
    return axios.delete(`/recipients/api/events/${eventId}`);
  });
};
export const useDeleteEventRecipients = (eventId) => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, number>((recipientId) => {
    const data: RemoveEventRecipientModel = {
      recipientIds: [recipientId],
    };
    return axios.delete(`/recipients/api/events/${eventId}/recipients`, {
      data,
    });
  });
};
export const useAddEventRecipients = (eventId) => {
  var axios = useAxios();
  return useMutation<unknown, ServerError, AddEventRecipientModel>((model) => {
    return axios.post(`/recipients/api/events/${eventId}/recipients`, model);
  });
};

export interface EventData {
  id: number;
  date: string;
  title: string;
  eventTypeId: RecipientEventType;
  eventType: string;
  repeatTypeId: string;
  repeatType: string;
  recipientCount: number;
  orderId?: number;
}
export interface EventInfoData extends EventData {
  recipients: RecipientData[];
}
export interface AddEventModel {
  date: string;
  title: string;
  eventTypeId: RecipientEventType;
  repeatTypeId: string;
  fileUrl: string;
  groupId: string;
  recipientIds: number[];
}
export interface EditEventModel {
  date: string;
  title: string;
  repeatTypeId: any;
}

export interface RemoveEventRecipientModel {
  recipientIds: number[];
}
export interface AddEventRecipientModel {
  eventId: number;
  recipientIds: number[];
  groupId: string;
  fileUrl: string;
}
export enum RecipientEventType {
  Others = 0,
  Birthday = 1,
  Wedding = 2,
}
