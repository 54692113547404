import { useQuery } from "react-query";
import { Count, OrderStatusType, PageFilter, ServerError, WalletType, useAxios } from "suregifts-component-lib";

export const useGetUtilities = (filter: BillOrderFilter) => {
  const path = "/utilities/api";
  const axios = useAxios();
  return useQuery<Count<BillOrderDto>, ServerError>({
    queryKey: ["orders", filter],
    keepPreviousData: true,
    initialData: { items: [], total: 0 },
    queryFn: () =>
      axios.get(path, {
        params: filter,
      }),
  });
};

export const useGetUtilityById = (id: any) => {
  const axios = useAxios();
  return useQuery<BillOrderInfo, ServerError>({
    queryKey: ["orderById", { id }],
    keepPreviousData: true,
    queryFn: () => axios.get(`/utilities/api/${id}`),
  });
};

export interface BillOrderDto {
  id: number;
  billTypeId: number;
  billType: string;
  statusId: OrderStatusType;
  status: string;
  subTotal: string;
  amountPerUnit: string;
  dateCreated: string;
  quantity: number;
  paymnetMethodId: WalletType;
  paymentMethod: string;
  name: string;
  unit: string;
  narration: string;
}
export interface BillRecipientDto {
  id: number;
  phone: string;
  email: string;
  customerId: string;
  deliveryStatusId: number;
  deliveryStatus: string;
  name: string;
}
export interface BillOrderInfo extends BillOrderDto {
  recipients: BillRecipientDto[];
}
export interface BillOrderFilter extends PageFilter {
  type: any;
}
