import { Box, Typography, useTheme, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingBox, PageHeaderTitle, TabPanel, useDocumentTitle, TransactionType, useUtilityContent, useGetAirtimeById, AirtimeFormSection, WalletType } from "suregifts-component-lib";

import { GropuAirtimeSection } from "./GropuAirtimeSection";
import { SummaryView } from "../../SummaryView";
import { useGetWalletInfo } from "../../../../services/transaction";

import { PaymentMethodView } from "../../PaymentMethodView";

function AirtimePaymentScreen() {
  const theme = useTheme();

  const { airtimeId } = useParams();
  const { data: wallet } = useGetWalletInfo();
  const {
    form: { setValue },
    setBill,
    amountSummary,
  } = useUtilityContent();
  const { data: airtime, mutate: getAirtime } = useGetAirtimeById();

  useDocumentTitle("Airtime Purcahse");

  useEffect(() => {
    getAirtime(airtimeId, {
      onSuccess: (result) => {
        setValue("amount", result.amount.toString());
        setBill({ categoryName: "Aritime", name: result.name, logoUrl: result.logoUrl });
      },
    });
  }, []);

  useEffect(() => {
    setValue("billTypeId", TransactionType.AirtimePurchase);
    setValue("packageId", airtimeId);
    setValue("paymentMethodId", WalletType.CashWallet);
  }, []);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (tabIndex == 0) {
      setValue("quantity", 1);
    }
  }, [tabIndex]);
  if (!airtime || !wallet) {
    return <LoadingBox loading></LoadingBox>;
  }
  return (
    <div>
      <PageHeaderTitle title={`${airtime?.name} Airitme`}></PageHeaderTitle>
      <Typography sx={{ color: "#AAAABE", fontSize: "0.875rem", fontWeight: 700, mt: "1.125rem" }}>Send to</Typography>
      <LoadingBox sx={{ mt: "1.125rem" }}>
        <Box
          sx={{
            display: "grid",
            gap: "3.125rem",
            [theme.breakpoints.up("md")]: {
              gridTemplateColumns: "2fr 1fr",
            },
          }}
        >
          <div>
            <Tabs
              TabIndicatorProps={{ hidden: true }}
              value={tabIndex}
              onChange={(e, newValue) => {
                setTabIndex(newValue);
                setValue("groupId", "");
                setValue("customerId", "");
              }}
              sx={{
                "& .MuiTab-root": {
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  "&.Mui-selected": {
                    color: "secondary.main",
                  },
                },
                marginBottom: "1.875rem",
              }}
            >
              <Tab sx={{ paddingLeft: "0" }} label="Individuals" value={0} />
              <Tab label="Group" value={1} />
            </Tabs>
            <TabPanel index={0} value={tabIndex}>
              <AirtimeFormSection />
              {/* <IndividualAirtimeSection airtime={airtime} /> */}
            </TabPanel>
            <TabPanel index={1} value={tabIndex}>
              <GropuAirtimeSection airtime={airtime} />
            </TabPanel>
            <PaymentMethodView wallet={wallet} totalAmount={amountSummary?.amountPayable || 0} />
          </div>
          <SummaryView />
        </Box>
      </LoadingBox>
    </div>
  );
}

export default AirtimePaymentScreen;
