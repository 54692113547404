import { Box, Switch } from "@mui/material";
import { FormButton, dateOnly, formatMoney } from "suregifts-component-lib";
import { useDiscountContext } from "../DiscountContext";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { DiscountStatusType, useCancelPlan, useReactivatePlan } from "../../../services/discount";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

export const PlanSummaryBox = () => {
  const { clientPlan } = useDiscountContext();
  const { initialSetting: setting } = useGlobalContext();
  const { mutate: reactivate } = useReactivatePlan();
  const { mutate: cancelPlan } = useCancelPlan();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        padding: "0.75rem 24px 21px",
        background: "#1D1C23",
        borderRadius: "24px",
        display: "grid",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          "div.logo": {
            width: "44px",
            height: "44px",
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50vh",
          },
          alignItems: "start",
          h1: {
            margin: 0,
            fontWeight: 500,
            fontSize: "32px",
            lineHeight: "normal",
          },
          "div.price, div.expiry": {
            fontSize: "14px",
            lineHeight: "20px",
          },
        }}
      >
        <div className="logo">
          <img src="/assets/images/discounts/discount 1.png" alt="Logo" />
        </div>
        <div style={{ display: "grid", gap: "0.25rem", width: "100%" }}>
          <h1>Client Plan</h1>
          <div style={{ marginTop: "10px" }} className="price">
            {formatMoney(clientPlan?.price, setting?.currency)}/Employee/year
          </div>
          <div className="expiry" style={{ color: "#8C8C9D" }}>
            Expires: {dateOnly(clientPlan?.expiryDate)}
          </div>
          <div>
            Auto-renew subscription
            <Switch
              onChange={() => {
                if (clientPlan?.renewalTypeId === DiscountStatusType.AutoRenuew) {
                  cancelPlan(undefined, {
                    onSuccess: () => {
                      queryClient.invalidateQueries("discount-client-plan");
                    },
                  });
                } else {
                  reactivate(undefined, {
                    onSuccess: () => {
                      queryClient.invalidateQueries("discount-client-plan");
                    },
                  });
                }
              }}
              color="success"
              checked={clientPlan?.renewalTypeId === DiscountStatusType.AutoRenuew}
            />
          </div>
          <FormButton
            label="Add Employee"
            fullWidth
            color="success"
            onClick={() => {
              navigate("/discounts/add-units");
            }}
          />
        </div>
      </Box>
    </Box>
  );
};
