import { Box } from "@mui/material";

import {
  AdminsNavIcon,
  BillNavIcon,
  DashboardIcon,
  General3Icon,
  GeneralIcon,
  LogoutIcon,
  NavLink,
  NavLinkProps,
  RecipientIcon,
  SettingsIcon,
  useAuthContext,
} from "suregifts-component-lib";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { CalendarIcon, DeveloperIcon, EnvelopeIcon, PotIcon, SwtichIcon } from "../../icons/Icons";
import { useLogOut } from "../../services/auth";
import { useDiscountContext } from "../discounts/DiscountContext";

function NavBar() {
  const { signOut } = useAuthContext();
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies(["BUSINESS_ID", "APPLICATION_MODE"]);
  const { mutate: logOut } = useLogOut();

  const discountContext = useDiscountContext();

  const topLinks: NavLinkProps[] = [
    {
      to: "/dashboard",
      icon: DashboardIcon,
      title: "Dashboard",
    },
    {
      to: "/orders/vouchers",
      icon: GeneralIcon,
      title: "Order History",
    },
    {
      to: "/templates",
      icon: EnvelopeIcon,
      title: "Email Templates",
    },
    {
      to: "/events",
      icon: CalendarIcon,
      title: "Calendar",
    },
    {
      to: "/recipients/vouchers",
      icon: RecipientIcon,
      title: "Recipients",
    },
    {
      to: "/wallet",
      icon: General3Icon,
      title: "Wallet",
    },
    {
      to: "/bills",
      icon: PotIcon,
      title: "Bills",
    },
    {
      to: "/airtime",
      icon: BillNavIcon,
      title: "Airtime",
    },
    {
      to: "/developer",
      icon: DeveloperIcon,
      title: "Developer",
    },
    {
      to: "/discounts/index",
      icon: DeveloperIcon,
      title: "Discounts",
      onClick: (e) => {
        if (!discountContext.clientPlan) {
          e.preventDefault();
          discountContext.openModal();
        }
      },
    },
  ];

  return (
    <Box
      sx={{
        listStyle: "none",
        padding: 0,
        margin: 0,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      component="ul"
    >
      {topLinks.map((link) => (
        <li key={link.title}>
          <NavLink {...link} />
        </li>
      ))}
      <li
        style={{
          flexGrow: 1,
          minHeight: "100px",
        }}
      ></li>
      {[
        // {
        //   to: "/light-mode",
        //   icon: LightModeIcon,
        //   title: "Light Theme",
        //   onClick: (e) => {
        //     e.preventDefault();
        //     toggleColorMode();
        //   },
        // },
        {
          to: "/managers",
          icon: RecipientIcon,
          title: "Managers",
        },

        {
          to: "/support",
          icon: AdminsNavIcon,
          title: "Support",
        },
        {
          to: "/",
          icon: SwtichIcon,
          title: "Switch Account",
        },
        {
          to: "/settings",
          icon: SettingsIcon,
          title: "Settings",
        },
        {
          to: "/logout",
          icon: LogoutIcon,
          title: "Logout",
          onClick: (e) => {
            e.preventDefault();
            logOut(undefined);
            signOut();
            removeCookie("BUSINESS_ID");

            navigate("/");
          },
        },
      ].map((link) => (
        <li key={link.title}>
          <NavLink {...link} />
        </li>
      ))}
    </Box>
  );
}

export default NavBar;
