import { useTheme } from "@mui/material/styles";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { VoucherStatsDto } from "../../services/general";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Order Statistics",
    },
  },
};

type VoucherStatisticsViewProps = {
  data?: VoucherStatsDto;
};

function VoucherQuantityStatisticsView({ data }: VoucherStatisticsViewProps) {
  const theme = useTheme();

  return (
    <div>
      {data && (
        <Line
          options={options}
          data={{
            labels: data.labels,
            datasets: [
              {
                label: "Quantity",
                data: data.quantities,
                borderColor: theme.palette.info.dark,
                backgroundColor: theme.palette.info.dark,
              },
            ],
          }}
          style={{ maxHeight: "25rem" }}
        />
      )}
    </div>
  );
}

export default VoucherQuantityStatisticsView;
